import React, { useEffect } from 'react';

import { Route, Routes, useNavigate, useMatch } from 'react-router-dom';

import NavigationDrawer from 'src/components/common/NavigationDrawer/NavigationDrawer';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import useFeatures from 'src/hooks/useFeatures';
import useMspNavigationItems from 'src/routes/useMspNavigationItems';
import Companies from 'src/views/Companies/Companies';
import Company from 'src/views/Company/Company';
import MSPDashboard from 'src/views/MSPDashboard/MSPDashboard';
import Notifications from 'src/views/Notifications/Notifications';
import PageNotFound from 'src/views/PageNotFound/PageNotFound';
import Team from 'src/views/Team/Team';

function MSPAnalystRoutes() {
  const { me, permissions, userConfig } = useCurrentUser();
  const mspNavigationItems = useMspNavigationItems(permissions);
  const navigate = useNavigate();
  const { isMSPDashboardEnabled } = useFeatures();

  const match = useMatch({
    exact: true,
    path: '/',
  });

  // By default, redirect to the default route
  useEffect(() => {
    if (match) {
      navigate(userConfig.defaultRoute);
    }
  }, [navigate, match, userConfig]);

  return (
    <Routes>
      <Route
        element={(
          <NavigationDrawer
            Content={me?.msp.name}
            navigationItems={mspNavigationItems}
          >
            <Routes>
              {isMSPDashboardEnabled() && <Route element={<MSPDashboard />} index path="dashboard" />}
              <Route element={<Companies />} path="companies" />
              <Route element={<Team />} path="team" />
              <Route element={<Notifications />} path="notifications" />
              {!match && <Route element={<PageNotFound />} path="*" />}
            </Routes>
          </NavigationDrawer>
        )}
        path="/*"
      />
      <Route element={<Company />} path="companies/:companyId/*" />
    </Routes>
  );
}

export default MSPAnalystRoutes;
