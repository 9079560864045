import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatDays } from 'src/helpers/date';

// TODO: Better date format & timezone consolidation
function EvsScheduleScanStatus(props) {
  const { schedule } = props;
  const { t } = useTranslation();

  return (
    <div className="EvsScheduleScanStatus flex items-center gap-2 text-od-extra-violet od-font-secondary-regular">
      <FontAwesomeIcon icon={light('calendar-days')} />
      {t('Scheduled {{period}} on {{days}} at {{time}} {{timezone}}', {
        days: formatDays(schedule),
        period: schedule.period.toLowerCase(),
        time: schedule.timeOfDay.split(':').slice(0, 2).join(':'),
        timezone: schedule.timezone,
      })}
    </div>
  );
}

EvsScheduleScanStatus.propTypes = {
  schedule: PropTypes.object.isRequired,
};

export default EvsScheduleScanStatus;
