import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';

// Numbers 1, 2, ..., 31
const monthDays = (new Array(31).fill().map((n, i) => i + 1));

function MonthView(props) {
  const { onChange, value } = props;
  const { t } = useTranslation();

  const onClick = (n) => {
    if (value.includes(n)) {
      const i = value.indexOf(n);
      onChange([
        ...value.slice(0, i),
        ...value.slice(i + 1),
      ].sort());
    } else {
      onChange([
        ...value,
        n,
      ].sort());
    }
  };

  return (
    <section>
      <div className="grid grid-cols-7 gap-2">
        {monthDays.map((n) => (
          <Button
            key={n}
            className={classNames(
              'h-8 py-2 px-4 flex items-center justify-center',
              'od-font-secondary-regular border border-od-black-100 rounded-md',
              {
                'bg-od-primary': value.includes(n),
              },
            )}
            onClick={() => onClick(n)}
            variant="text"
          >
            {n}
          </Button>
        ))}
      </div>
      <p className="mt-3 od-font-third-regular">
        {t('If a month has less than 31 days, scans will take place on the last day of the month')}
      </p>
    </section>
  );
}

MonthView.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};

export default MonthView;
