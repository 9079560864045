import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import DropdownMenu, { DropdownItem } from 'src/components/common/DropdownMenu';
import ProfileModal from 'src/components/modals/ProfileModal/ProfileModal';
import { CurrentUserContext } from 'src/contexts/useCurrentUser';
import { ReactComponent as IconLogout } from 'src/design/custom-icons/logout.svg';
import { ReactComponent as IconProfile } from 'src/design/custom-icons/profile.svg';
import Avatar from 'src/design/ui-kit/Avatar/Avatar';

function UserWidget() {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const { error, loading, me } = useContext(CurrentUserContext);

  if (loading || error || !me) {
    return '';
  }

  const dropdownItems = [
    new DropdownItem(t('Profile'), <IconProfile />, () => {
      setIsOpen(true);
    }),
    new DropdownItem(t('Logout'), <IconLogout />, async () => {
      window.location.replace('/users/logout/');
    }),
  ];

  return (
    <div className="pl-1">
      <DropdownMenu
        items={dropdownItems}
        position="top"
      >
        <div className="UserWidget border border-od-black-300 flex items-center p-1 rounded-od-12">
          <Avatar preset={me.avatar} />
          <div className="ml-2 od-font-main-semi-bold truncate max-w-[160px]" title={me.fullName}>
            { me.fullName }
          </div>
        </div>
      </DropdownMenu>
      {isOpen && (
        <ProfileModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

export default UserWidget;
