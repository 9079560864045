import React from 'react';

import PropTypes from 'prop-types';

import PasswordRule from 'src/components/common/PasswordValidation/PasswordRule';

function PasswordValidation(props) {
  const { isFieldValidByRule, validationsMap } = props;

  return (
    <div className="PasswordValidation grid gap-2 mt-3">
      <PasswordRule
        isValid={isFieldValidByRule('password', 'min')}
        message={validationsMap.min.message}
      />
      <PasswordRule
        isValid={isFieldValidByRule('password', 'uppercaseLowercaseMixture')}
        message={validationsMap.uppercaseLowercaseMixture.message}
      />
      <PasswordRule
        isValid={isFieldValidByRule('password', 'oneNumber')}
        message={validationsMap.oneNumber.message}
      />
      <PasswordRule
        isValid={isFieldValidByRule('password', 'oneSpecialCharacter')}
        message={validationsMap.oneSpecialCharacter.message}
      />
    </div>
  );
}

PasswordValidation.propTypes = {
  isFieldValidByRule: PropTypes.func.isRequired,
  validationsMap: PropTypes.object.isRequired,
};

export default PasswordValidation;
