import React from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/common/Page';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useCurrentCompanyBciSubscription } from 'src/contexts/useCurrentCompanyBciSubscription';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import EmptyState from 'src/design/ui-kit/EmptyState/EmptyState';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useFeatures from 'src/hooks/useFeatures';
import useUserParams from 'src/hooks/useUserParams';
import CompanyDashboardBreachCredentialsOverview
  from 'src/views/CompanyDashboard/CompanyDashboardBreachCredentialsOverview';
import CompanyDashboardVulnerabilityScanningOverview from
  'src/views/CompanyDashboard/CompanyDashboardVulnerabilityScanningOverview';

function CompanyDashboard() {
  const { companyId } = useUserParams();
  const { t } = useTranslation();
  const { userConfig } = useCurrentUser();
  const { isBCIEnabled } = useFeatures();

  const { loading: loadingCompany, tactics: { evs: { companyEvsSubscription } } } = useCurrentCompany();
  const { companyBciSubscription, loading: loadingBciSubscription } = useCurrentCompanyBciSubscription();

  const hasSubscriptions = !!companyEvsSubscription || !!companyBciSubscription;
  const bcStatistics = companyBciSubscription?.company?.bcStatistics ?? null;
  const loading = loadingCompany || loadingBciSubscription;

  if (loading) {
    return (
      <Page title={t('Loading...')}>
        <Loading />
      </Page>
    );
  }

  if (!hasSubscriptions) {
    return (
      <Page title={t('Company Dashboard')}>
        <EmptyState
          subtitle={t('To set up your first tactic, click the "Open tactics" button below!')}
          title={t("Here's where you'll see an overview of your tactics at work")}
        >
          <Button
            link={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}
          >
            {t('Open tactics')}
          </Button>
        </EmptyState>
      </Page>
    );
  }

  return (
    <Page className="flex flex-col gap-10" title={t('Company Dashboard')}>
      {companyBciSubscription && bcStatistics && isBCIEnabled() && (
        <CompanyDashboardBreachCredentialsOverview bcStatistics={bcStatistics} />
      )}
      {companyEvsSubscription && <CompanyDashboardVulnerabilityScanningOverview />}
    </Page>
  );
}

export default CompanyDashboard;
