import React from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/common/Page';
import useFeatures from 'src/hooks/useFeatures';
import BciTactic from 'src/views/TacticsSettings/BciTactic/BciTactic';
import EVSTactic from 'src/views/TacticsSettings/EvsTactic/EVSTactic';

function TacticsSettings() {
  const { t } = useTranslation();
  const { isBCIEnabled } = useFeatures();

  return (
    <Page title={t('Tactics Settings')}>
      <h3>{t('Tactics')}</h3>
      <div className="grid gap-4 mt-6">
        <EVSTactic />
        {isBCIEnabled() && <BciTactic />}
      </div>
    </Page>
  );
}

export default TacticsSettings;
