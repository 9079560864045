import { useTranslation } from 'react-i18next';

import { calculateNumberOfHostsFromCidr, isCidr } from 'src/helpers/ip';

function useIPHelpers(targets) {
  const { t } = useTranslation();

  const increment = (ip) => {
    if (isCidr(ip)) {
      return calculateNumberOfHostsFromCidr(ip);
    }
    return 1;
  };

  const indexFormatter = (index, value) => {
    const startIndex = targets
      .slice(0, index)
      .reduce((total, target) => total + increment(target), 0);

    if (isCidr(value)) {
      const hostsCount = calculateNumberOfHostsFromCidr(value);
      if (hostsCount === 1) {
        return startIndex + 1;
      }
      const endIndex = startIndex + hostsCount;
      return `${startIndex + 1} - ${endIndex}`;
    }
    return startIndex + 1;
  };

  const valueFormatter = (value) => {
    if (isCidr(value)) {
      const hostsCount = calculateNumberOfHostsFromCidr(value);
      if (hostsCount === 1) {
        return value;
      }
      return t('{{ip}} [{{hostsCount}} hosts]', {
        hostsCount: calculateNumberOfHostsFromCidr(value),
        ip: value,
      });
    }
    return value;
  };

  return {
    indexFormatter,
    valueFormatter,
  };
}

export default useIPHelpers;
