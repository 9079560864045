import React from 'react';

import { useTranslation } from 'react-i18next';

import EvsTargetsTableView from 'src/components/tactics.evs/targets/EvsTargetsTableView';

// TODO: Extend with search
function EvsTargets() {
  const { t } = useTranslation();
  return (
    <section className="EvsTargets grid gap-4 mt-10">
      <h5 className="od-font-title-semi-bold mb-2">{t('Targets from all groups')}</h5>

      <EvsTargetsTableView />
    </section>
  );
}

export default EvsTargets;
