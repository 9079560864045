import * as yup from 'yup';

import { usePasswordValidations } from 'src/helpers/validators';
import useFormBuilder from 'src/hooks/useFormBuilder';

function useResetPasswordForm() {
  const { passwordValidator, validationsMap } = usePasswordValidations();

  const initialFormData = {
    password: '',
  };

  const validationSchema = yup.object({
    password: passwordValidator,
  }).required();

  const formBuilder = useFormBuilder(
    initialFormData,
    validationSchema,
    { focusField: 'password',
      reactHookFormOptions: {
        criteriaMode: 'all',
      } },
  );

  return {
    formBuilder,
    validationsMap,
  };
}

export default useResetPasswordForm;
