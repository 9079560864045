import React, { useRef } from 'react';

import { useMutation } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { SETUP_PAYMENT_SOURCE_MUTATION } from 'src/api/billing';
import FormControl from 'src/components/common/FormControl';
import Modal from 'src/components/modals/Modal';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { useMSPBilling } from 'src/contexts/useMSPBilling';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import useToast from 'src/hooks/useToast';
import useValidationHelpers from 'src/hooks/useValidationHelpers';

const schema = () => yup.object({
  email: yup
    .string()
    .required(i18n.t('Field is required'))
    .email(i18n.t('Must be a valid email field')),
}).required();

const initialFormData = (email) => ({
  email,
});

function ChangeBillingEmailModal(props) {
  const { email = '', isOpen, onClose } = props;

  const { t } = useTranslation();
  const formRef = useRef();
  const { showSuccessMessage } = useToast();
  const { me } = useCurrentUser();
  const { refetch } = useMSPBilling();

  const { formState, handleSubmit, register, reset, watch } = useForm({
    defaultValues: initialFormData(email),
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const formData = watch();

  const hasChanges = formData.email !== email;

  const handleClose = () => {
    onClose();
    setTimeout(reset);
  };

  const { getFieldError } = useValidationHelpers({
    formState,
  });

  const [savePaymentSource, { loading }] = useMutation(
    SETUP_PAYMENT_SOURCE_MUTATION,
    {
      onCompleted: async ({ setupPaymentSource }) => {
        if (setupPaymentSource) {
          showSuccessMessage(t('Email successfully updated'));
          refetch();
          handleClose();
        }
      },
    },
  );

  const onSubmit = async (submitData, event) => {
    event.preventDefault();
    await savePaymentSource({
      variables: {
        customer: me.msp.id,
        email: submitData.email,
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      shouldFocusFirstFocusable
      subtitle={t("Please enter the email you'd prefer to use for invoicing below:")}
      title={email ? t('Change invoice email') : t('Set invoice email')}
    >
      <form
        ref={formRef}
        className="mt-12"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl
          error={getFieldError('email')}
          htmlFor="ChangeBillingEmailModal__email"
          label={t('Invoice email')}
        >
          <Input
            error={getFieldError('email')}
            id="ChangeBillingEmailModal__email"
            name="email"
            placeholder={t('Email')}
            register={register}
          />
        </FormControl>

        <div className="flex justify-end mt-10">
          <Button
            disabled={!formState.isValid || !hasChanges}
            Icon={<FontAwesomeIcon icon={light('floppy-disk')} />}
            loading={loading}
            type="submit"
          >
            {t('Save changes')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

ChangeBillingEmailModal.propTypes = {
  email: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangeBillingEmailModal;
