import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as IconValidationInvalid } from 'src/design/custom-icons/validation-invalid.svg';
import { ReactComponent as IconValidationValid } from 'src/design/custom-icons/validation-valid.svg';

function PasswordRule(props) {
  const { isValid, message } = props;
  return (
    <div className="PasswordRule flex items-center gap-2">
      {isValid ? <IconValidationValid /> : <IconValidationInvalid />}
      <span className={classNames('PasswordRule__message od-font-third-regular', {
        'text-od-complementary-bad': !isValid,
        'text-od-complementary-good': isValid,
      })}
      >
        {message}
      </span>
    </div>
  );
}

PasswordRule.propTypes = {
  isValid: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default PasswordRule;
