import React from 'react';

import PropTypes from 'prop-types';

import { formatPrice, useConvertCentValue } from 'src/helpers/price';

const timeUnits = ['week', 'month'];

function FieldPeriodCost(props) {
  const { timeUnit = 'month', value = 0 } = props;

  const { convertFromCentValue } = useConvertCentValue(2);

  return (
    <div className="Field FieldPeriodCost od-font-main-regular">
      {`${formatPrice(convertFromCentValue(value))} / `}
      <span className="text-od-white-500">
        {timeUnit}
      </span>
    </div>
  );
}

FieldPeriodCost.propTypes = {
  timeUnit: PropTypes.oneOf(timeUnits),
  value: PropTypes.number,
};

export default FieldPeriodCost;
