import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as ImageEmptyCompanies } from 'src/design/images/illustrations/empty/companies.svg';
import { ReactComponent as ImageEmptyDashboard } from 'src/design/images/illustrations/empty/dashboard.svg';
import { ReactComponent as ImageEmptyUsers } from 'src/design/images/illustrations/empty/users.svg';

const variants = ['primary', 'secondary', 'tertiary'];

function EmptyState(props) {
  const {
    children = null,
    subtitle = '',
    title = '',
    variant = 'primary',
  } = props;

  const illustration = useMemo(() => {
    if (variant === 'tertiary') {
      return <ImageEmptyCompanies />;
    }
    if (variant === 'secondary') {
      return <ImageEmptyDashboard />;
    }
    return <ImageEmptyUsers />;
  }, [variant]);

  return (
    <section className="EmptyState flex flex-col items-center mt-24">
      <div className="flex items-center justify-center">
        {illustration}
      </div>
      <div className="mt-10 max-w-lg text-center">
        {title && (
          <div className="od-font-title-medium mb-2">
            {title}
          </div>
        )}
        {subtitle && (
          <div className="od-font-secondary-medium text-od-white-500 max-w-md text-center">
            {subtitle}
          </div>
        )}
      </div>
      <div className="mt-7 text">
        {children}
      </div>
    </section>
  );
}

EmptyState.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(variants),
};

export default EmptyState;
