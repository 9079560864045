/* eslint-disable max-classes-per-file */

/**
 * SortState of individual table if sorting is applied
 * @param {string} sortKey - object key to apply sort for. ig 'name', 'date', etc
 * @param {string} sortDirection - asc or desc order
 * @param {function} sortFn - sorting function that will be applied to fuse helper
 */
export class TableSortState {
  constructor(
    sortKey = '',
    sortDirection = 'asc',
    sortFn = null,
  ) {
    this.sortKey = sortKey;
    this.sortDirection = sortDirection;
    this.sortFn = sortFn;

    if (!sortFn) {
      throw new Error('TableSortState: sortFn is required');
    }
  }
}

export function toggleSortDirection(sortDirection) {
  if (sortDirection === 'asc') {
    return 'desc';
  }
  return 'asc';
}

// The function already knows sortKey and sortDirection. it was curried from the previous call
export function applySort(data, sortState) {
  if (!sortState || !sortState.sortKey) {
    return data;
  }
  return sortState.sortFn(data);
}

/**
 * @param definition
 * @param {string} definition.name
 * @param {string} definition.justifyContent
 * @param {boolean} definition.sortable
 * @param {TableSortState | null} definition.sortConfig
 * @param {string} definition.gridTemplate https://www.w3schools.com/cssref/pr_grid-template-columns.asp
 *  @default auto
 */
export class TableColumnDefinition {
  constructor({
    Icon = null,
    gridTemplate = 'auto',
    justifyContent = 'flex-start',
    name,
    sortConfig = null,
    sortable = false,
    title,
  }) {
    this.Icon = Icon;
    this.name = name;
    this.title = title;
    this.justifyContent = justifyContent;
    this.sortable = sortable;
    this.sortConfig = sortConfig;
    this.gridTemplate = gridTemplate;

    if (!name) {
      throw new Error('Column definition: name is required');
    }

    if (sortable && !(sortConfig instanceof TableSortState)) {
      throw new Error('Column definition: sortable columns must include valid sortConfig');
    }
    // If sort config is passed,
    // we take it as sortable otherwise it must be removed
    this.sortConfig = sortConfig;
  }
}
