import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { EVS_PRICES } from 'src/api/evsSubscription';
import useTargetsLoader from 'src/api/loaders/useTargetsLoader';
import BulkAddStringsForm from 'src/components/common/BulkEditStringsForm/BulkAddStringsForm';
import FormControl from 'src/components/common/FormControl';
import SidebarModal from 'src/components/modals/SidebarModal';
import TargetGroupCost from 'src/components/modals/TargetGroupSidebarModal/TargetGroupCost';
import useIPHelpers from 'src/components/modals/TargetGroupSidebarModal/useIPHelpers';
import useTargetGroupActions from 'src/components/modals/TargetGroupSidebarModal/useTargetGroupActions';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import Divider from 'src/design/ui-kit/Divider/Divider';
import Input from 'src/design/ui-kit/Input/Input';
import { isValidIP } from 'src/helpers/ip';
import { calculatePrices } from 'src/helpers/price';
import AddTargetGroupContinueButton
  from 'src/views/TacticEvs/TargetGroups/AddTargetGroup/AddTargetGroupContinueButton';
import useTargetGroupForm from 'src/views/TacticEvs/TargetGroups/AddTargetGroup/useTargetGroupForm';
// We skip pagination for now, all items will be loaded immediately
// TODO: Add react virtual list for better performance
const first = 10000;

function TargetGroupSidebarModal(props) {
  const { isOpen, mode, onClose, targetGroup = null } = props;

  const { t } = useTranslation();
  const { companyId, mspId } = useParams();
  const { tactics: { evs: { evsSubscriptionStatus } } } = useCurrentCompany();
  const [/* hasChangedTargets */, setHasChangedTargets] = useState(false);

  const evsPricesVariables = {
    customer: mspId || companyId,
  };

  const evsPricesQuery = useQuery(EVS_PRICES, {
    variables: evsPricesVariables,
  });

  const basePrice = evsPricesQuery.data?.evsPrices?.basePrice ?? 0;
  const targetPrice = evsPricesQuery.data?.evsPrices?.targetPrice ?? 0;

  // TODO: Figure out why this loads 3 times
  const targetsVariables = {
    companyId,
    first,
    ...targetGroup && { targetGroup: targetGroup.id },
  };

  const {
    data: { items: evsTargets },
    loading,
  } = useTargetsLoader(
    { skip: !targetGroup, variables: targetsVariables },
  );

  const {
    form: {
      clearErrors, control, formState, register, reset, setValue, watch,
    },
    helpers: { getFieldError },
  } = useTargetGroupForm(targetGroup);

  // TODO: Take into account newly added targets and deleted targets
  useEffect(() => {
    if (evsTargets) {
      setValue('targets', evsTargets);
    }
  }, [evsTargets, setValue]);

  const formData = watch();

  const { onSave, saving } = useTargetGroupActions({
    companyId,
    evsTargets,
    formData,
    handleClose: onClose,
    mode,
    targetGroup,
  });

  const { indexFormatter, valueFormatter } = useIPHelpers(formData.targets);

  const getTitle = () => {
    if (mode === 'subscription') {
      return t('Subscribe to EVS');
    }
    if (targetGroup) {
      return t('Manage Target Group');
    }
    return t('Create Target Group');
  };

  const handleClose = () => {
    clearErrors();
    reset();
    onClose();
  };

  const { totalPrice, totalTargetsPrice } = calculatePrices({
    basePrice,
    status: evsSubscriptionStatus,
    targetCount: formData.targets.length,
    targetGroup,
    targetPrice,
  });

  return (
    <SidebarModal
      contentClassName="grid flex-1 min-h-0"
      contentStyle={{
        gridTemplateRows: 'max-content 1px minmax(0, 1fr) max-content',
      }}
      isOpen={isOpen}
      onClose={handleClose}
      title={getTitle()}
    >
      <div className="pl-6 pr-10 pt-3 pb-7">
        <FormControl error={getFieldError('lastName')}>
          <Input
            name="name"
            placeholder={t('Group name')}
            register={register}
          />
        </FormControl>
      </div>

      <Divider />

      <div className="pl-6 pr-10 pt-3">
        <Controller
          control={control}
          name="targets"
          render={({ field: { onChange } }) => (
            <BulkAddStringsForm
              canEdit={false}
              defaultValues={formData.targets}
              disabled={loading}
              indexFormatter={indexFormatter}
              loading={loading}
              onChange={(value) => {
                setHasChangedTargets(true);
                onChange(value);
              }}
              placeholder={t('IP / Domain')}
              validator={isValidIP}
              valueFormatter={valueFormatter}
            />
          )}
        />
      </div>

      <Divider />

      <div className="pl-6 pr-10 pb-4">
        <TargetGroupCost
          basePrice={basePrice}
          targetPrice={targetPrice}
          totalPrice={totalPrice}
          totalTargetsPrice={totalTargetsPrice}
        />
      </div>

      <section className="flex flex-col mt-2 pl-6 pr-10 gap-5">
        <AddTargetGroupContinueButton
          disabled={!formState.isValid || saving || loading}
          loading={saving}
          mode={mode}
          onConfirm={onSave}
          targetGroup={targetGroup}
          totalPrice={totalPrice}
        />
      </section>
    </SidebarModal>
  );
}

TargetGroupSidebarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['subscription', 'targetGroup']).isRequired,
  onClose: PropTypes.func.isRequired,
  targetGroup: PropTypes.object,
};

export default TargetGroupSidebarModal;
