import { gql } from '@apollo/client';

export const SIGN_IN_MUTATION = gql`
  mutation($email: String!, $password: String!) {
    login(input: {
      email: $email
      password: $password
    }) {
      user {
        email
        msp {
          id
          name
        }
        permissions
      }
    }
  }
`;

export const SIGN_UP_COMPANY_MUTATION = gql`
  mutation($isMsp: Boolean!, $companyName: String!,
   $firstName: String!, $lastName: String!, $email: String!, $password: String!) {
    signUp(input: {
      isMsp: $isMsp,
      companyName: $companyName,
      firstName: $firstName,
      lastName: $lastName,
      email: $email
      password: $password
    }) {
      ok
    }
  }
`;

export const REQUEST_RESET_PASSWORD_MUTATION = gql`
  mutation($email: String!) {
    requestResetPassword(input: {
      email: $email
    }) {
      ok
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation($uid: String!, $password: String!) {
    resetPassword(input: {
      password: $password,  
      uid: $uid
    }) {
      ok
    }
  }
`;

export const SIGN_OUT_MUTATION = gql`
  mutation($clientMutationId: String) {
    logout(input: {
      clientMutationId: $clientMutationId
    }) {
      ok
    }
  }
`;
