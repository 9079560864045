import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { BCI_COUNT_PERIOD } from 'src/constants/bci';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import FieldCountStatus from 'src/design/ui-kit/Field/FieldCountStatus/FieldCountStatus';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import CompaniesTableViewRowVulnerabilitiesCell from 'src/views/Companies/CompaniesTableViewRowVulnerabilitiesCell';
import EditCompanyButton from 'src/views/Companies/EditCompanyButton';

function CompaniesTableViewRow(props) {
  const { data: company, id } = props;

  const { t } = useTranslation();
  const { permissions, userConfig } = useCurrentUser();

  return (
    <tr id={id}>
      <td>
        <Tooltip text={t('Control this company')}>
          <NavLink
            className="text-link flex gap-2 items-center group"
            to={`/companies/${company.id}/dashboard`}
          >
            <FontAwesomeIcon
              className="Button--primary px-3 py-2 max-h-9 rounded-od-10"
              icon={light('person-to-portal')}
            />

            <span className="group-hover:text-od-button-primary-hover">
              {company.name}
            </span>
          </NavLink>
        </Tooltip>
      </td>

      <td className="justify-center">
        <NavLink
          className="link-text flex gap-2 items-center group"
          to={`/companies/${company.id}/users`}
        >
          {company.usersCount}
        </NavLink>
      </td>

      <td className="justify-center">
        <CompaniesTableViewRowVulnerabilitiesCell company={company} />
      </td>

      <td className="justify-center">
        {company.bcStatistics?.breachCount > 0 && (
          <Tooltip text={t('Last {{n}} days', { n: BCI_COUNT_PERIOD })}>
            <NavLink to={userConfig.buildUrl('/tactics-settings', `/${company.id}`)}>
              <FieldCountStatus
                negative
                value={company.bcStatistics.breachCount}
              />
            </NavLink>
          </Tooltip>
        )}

      </td>

      {/* <td className="justify-end"> */}
      {/*  <FieldPeriodCost */}
      {/*    timeUnit="month" */}
      {/*    value={789.90} */}
      {/*  /> */}
      {/* </td> */}

      {
        permissions.canManageCompany() && (
          <td className="justify-end">
            <EditCompanyButton company={company} />
          </td>
        )
      }
    </tr>
  );
}

CompaniesTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
};

export default CompaniesTableViewRow;
