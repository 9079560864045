import React from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { RESET_PASSWORD_MUTATION } from 'src/api/auth';
import Page from 'src/components/common/Page';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

import ResetPasswordForm from './ResetPasswordForm';

function ResetPassword() {
  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();
  const { navigateToSignIn } = useCurrentUser();
  const { uid } = useUserParams();

  const [resetPassword, { loading: saving }] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: async (response) => {
      if (response.resetPassword?.ok) {
        showSuccessMessage(t('Password successfully reset'));
        navigateToSignIn();
      }
    },
  });

  const onSubmit = async (formData) => {
    try {
      await resetPassword({ variables: {
        password: formData.password,
        uid,
      } });
    } catch (e) {
      // Error is handled globally
    }
  };

  return (
    <Page
      className="ResetPassword h-full flex justify-center items-center"
      title={t('Set password')}
    >
      <ResetPasswordForm
        loading={saving}
        onSubmit={onSubmit}
      />
    </Page>
  );
}

export default ResetPassword;
