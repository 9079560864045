import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import EvsVulnerabilitySeverityStackedBarChart from
  'src/components/charts/EvsVulnerabilitySeverityStackedBarChart/EvsVulnerabilitySeverityStackedBarChart';
import { DATETIME } from 'src/constants/date';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import FieldDatetime from 'src/design/ui-kit/Field/FieldDatetime/FieldDatetime';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import { useScanVulnerabilitySeverityDefinitions } from 'src/helpers/scan';
import useUserParams from 'src/hooks/useUserParams';

function EvsVulnerabilityTableViewRow(props) {
  const { data: scan } = props;
  const { companyId } = useUserParams();
  const { userConfig } = useCurrentUser();
  const { t } = useTranslation();
  const vulnerabilitySeverityDefinitions = useScanVulnerabilitySeverityDefinitions({ showInfo: true });

  const series = vulnerabilitySeverityDefinitions.map(({ color, key, name }) => ({
    color,
    data: [scan.statistics.vulnCountPerLevel[key]],
    name,
  })).reverse();

  return (
    <tr className="EvsVulnerabilityTableViewRow">
      <td>
        <Tooltip text={t('Scan details')}>
          <NavLink
            className="text-link flex gap-2 items-center group"
            to={userConfig.buildUrl(`/evs-scans/${scan.id}`, `/${companyId}`)}
          >
            <FontAwesomeIcon
              className="Button--primary px-3 py-2 max-h-9 rounded-od-10"
              icon={light('chart-line-up-down')}
            />
            {scan.startedAt ? (
              <FieldDatetime
                dateFormat={DATETIME}
                value={new Date(scan.startedAt)}
              />
            ) : '-'}
          </NavLink>
        </Tooltip>
      </td>
      <td>{scan.owner.name}</td>
      <td>
        <EvsVulnerabilitySeverityStackedBarChart series={series} />
      </td>
      <td className="justify-end">
        {scan.ranAt && t('Completed')}
      </td>
    </tr>
  );
}

EvsVulnerabilityTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
};

export default EvsVulnerabilityTableViewRow;
