import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DELETE_USER_MUTATION, USERS_QUERY } from 'src/api/users';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import Button from 'src/design/ui-kit/Button/Button';
import useToast from 'src/hooks/useToast';

function DeleteUserButton(props) {
  const { user } = props;
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { t } = useTranslation();

  const { showSuccessMessage } = useToast();
  const [_deleteUser, { loading: saving }] = useMutation(
    DELETE_USER_MUTATION,
    {
      onCompleted({ deleteUser }) {
        if (deleteUser) {
          showSuccessMessage(t('User deleted successfully'));
          setIsOpenConfirmationModal(false);
        }
      },
      refetchQueries: [USERS_QUERY],
    },
  );

  const onDelete = async () => {
    await _deleteUser({
      variables: {
        id: user.id,
      },
    });
  };

  return (
    <>
      <Button
        Icon={<FontAwesomeIcon icon={light('trash')} />}
        onClick={() => setIsOpenConfirmationModal(true)}
        variant="icon"
      />
      <ConfirmationModal
        Cancel={(
          <Button
            disabled={saving}
            Icon={<FontAwesomeIcon icon={light('ban')} />}
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('No')}
          </Button>
        )}
        Confirm={(
          <Button
            Icon={<FontAwesomeIcon icon={light('thumbs-up')} />}
            loading={saving}
            onClick={onDelete}
          >
            {t('Yes')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        subtitle={t('Are you sure that you want to delete the user?')}
        title={t('Delete user')}
      />
    </>
  );
}

DeleteUserButton.propTypes = {
  user: PropTypes.object.isRequired,
};

export default DeleteUserButton;
