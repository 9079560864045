import i18n from 'i18next';

import { TableColumnDefinition } from 'src/hooks/useTable';

const useCompanyColumns = () => [
  new TableColumnDefinition({
    name: 'message',
    title: i18n.t('Message'),
  }),
  new TableColumnDefinition({
    name: 'date',
    title: i18n.t('Date'),
  }),
];

export default useCompanyColumns;
