import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Avatar from 'src/design/ui-kit/Avatar/Avatar';

function Username(props) {
  const { user } = props;
  const { t } = useTranslation();
  const hasName = user.firstName || user.lastName;
  return (
    <div className="flex items-center">
      <Avatar preset={user.avatar} size="sm" />
      <div className={classNames('od-font-main-medium ml-3', {
        'text-od-white-500': !hasName,
        'text-od-white-900': hasName,
      })}
      >
        {hasName ? user.fullName : t('Unnamed')}
      </div>
    </div>
  );
}

Username.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Username;
