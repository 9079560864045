import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import colors from 'src/design/theme/colors';
import InfoCardVisual from 'src/design/ui-kit/InfoCardVisual/InfoCardVisual';

function CompanyDashboardVulnerabilityScanningOverviewTotal() {
  const { t } = useTranslation();
  const { company } = useCurrentCompany();

  const { currentCount, newCount, remediatedCount } = company.evsAggregateStatistics;

  const previousCount = currentCount.total + remediatedCount.total - newCount.total;

  return (
    <ul className="CompanyDashboardVulnerabilityScanningOverviewTotal mt-5 grid lg:grid-cols-4 gap-3">
      <InfoCardVisual
        color={colors['od-status-box-previous-bg']}
        description={t('Previous')}
        Icon={(
          <FontAwesomeIcon
            className="rotate-90"
            icon={light('arrow-up-to-dotted-line')}
            size="xl"
          />
        )}
        tag="li"
      >
        {previousCount}
      </InfoCardVisual>
      <InfoCardVisual
        color={colors['od-status-box-new-bg']}
        description={t('New')}
        Icon={(
          <FontAwesomeIcon
            icon={light('arrow-up-to-dotted-line')}
            size="xl"
          />
        )}
        tag="li"
      >
        {newCount.total}
      </InfoCardVisual>
      <InfoCardVisual
        color={colors['od-status-box-resolved-bg']}
        description={t('Resolved')}
        Icon={(
          <FontAwesomeIcon
            className="rotate-180"
            icon={light('arrow-up-to-dotted-line')}
            size="xl"
          />
        )}
        tag="li"
      >
        {remediatedCount.total}
      </InfoCardVisual>
      <InfoCardVisual
        color={colors['od-status-box-current-bg']}
        description={t('Current')}
        Icon={(
          <FontAwesomeIcon
            icon={light('chart-scatter')}
            size="xl"
          />
        )}
        tag="li"
      >
        {currentCount.total}
      </InfoCardVisual>
    </ul>
  );
}

export default CompanyDashboardVulnerabilityScanningOverviewTotal;
