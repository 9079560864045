import { gql } from '@apollo/client';

import FragmentInvoice from 'src/api/fragments/FragmentInvoice';

export const SETUP_PAYMENT_SOURCE_MUTATION = gql`
  mutation ($token: String, $customer: ID!, $email: String!) {
    setupPaymentSource(input: {
      token: $token
      customer: $customer
      email: $email
    }) {
      billingInformation {
        email
        isCardWorking
      }
    }
  }
`;

export function transformBillingDetailsToInvoices(data) {
  if (!data.mspBillingDetails) {
    return {
      items: [],
      pageInfo: {
        endCursor: null,
        hasNextPage: false,
      },
    };
  }
  return {
    items: data.mspBillingDetails.invoices.edges.map((edge) => edge.node),
    pageInfo: data.mspBillingDetails.invoices.pageInfo,
  };
}

export const MSP_BILLING_DETAILS = gql`
  ${FragmentInvoice}
  query mspBillingDetails($id: ID!) {
    mspBillingDetails(id: $id) {
      activeCompanies
      totalMonthlyCost
      paymentSource {
        isCardWorking
        ccLastDigits
        email
      }
      thisMonthTotalInvoices
      lastInvoice {
        ...FragmentInvoice
      }
      upcomingInvoice {
        ...FragmentInvoice
      }
    }
  }
`;

export const MSP_BILLING_INVOICES = gql`
  ${FragmentInvoice}
  query mspBillingDetails($id: ID!, $first: Int!, $after: ID) {
    mspBillingDetails(id: $id) {
      invoices(first: $first, after: $after) {
        edges {
          node {
            ...FragmentInvoice
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const MSP_BILLING_COMPANY_DETAILS = gql`
  query mspBillingCompanyDetails($id: ID!) {
    mspBillingCompanyDetails(id: $id) {
      company {
        id
        name
      }
      nextPayment {
        cost
        date
      }
      cost
      evsSubscription {
        id
        isActive
        expiresAt
        targetCount
      }
    }
  }
`;
