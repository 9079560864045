import { ALL_SCANS_QUERY } from 'src/api/evsScans/evsScans';
import { transformAllScansResponse as transformResponse } from 'src/api/evsScans/evsScansHelpers';
import usePaginatedLoader from 'src/api/loaders/usePaginatedLoader';

export default function useVulnerabilityScansLoader({ variables }) {
  return usePaginatedLoader(
    ALL_SCANS_QUERY,
    { variables },
    transformResponse,
  );
}
