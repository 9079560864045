import { gql } from '@apollo/client';

// TODO: Replace unsheduledGroups with unscheduledGroups
// eslint-disable-next-line import/prefer-default-export
export const MSP_DASHBOARD = gql`
  query($mspId: ID!) {
    mspDashboard(msp: $mspId) {
      analysts
      companies
      admins
      bciInfos {
        deployment
        hitsThisMonth
        totalBreaches
        totalDeployment
        totalRecords
      }
      evsInfos {
        deployment
        exploitableVulnerabilities
        scannedThisMonth
        totalDeployment
        unsheduledGroups
      }
    }
  }
`;
