import { BCI_DOMAINS } from 'src/api/bci';
import usePaginatedLoader from 'src/api/loaders/usePaginatedLoader';

export function transformResponse(data) {
  if (!data) {
    return {
      items: [],
      pageInfo: {
        endCursor: null,
        hasNextPage: false,
      },
    };
  }
  return {
    items: data.bciDomains.edges.map((edge) => edge.node),
    pageInfo: data.bciDomains.pageInfo,
  };
}

export default function useDomainsLoader({ variables }) {
  return usePaginatedLoader(
    BCI_DOMAINS,
    {
      variables,
    },
    transformResponse,
  );
}
