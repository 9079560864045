import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Badge from 'src/design/ui-kit/Badge/Badge';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import { formatDateDistanceToNow } from 'src/helpers/date';

function EvsNoScanDataBadge(props) {
  const { companyId, date } = props;
  const { userConfig } = useCurrentUser();
  const { t } = useTranslation();

  return (
    <Tooltip text={t('Click to view tactic settings')}>
      <NavLink to={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}>
        <Badge variant="danger">
          <FontAwesomeIcon icon={light('ban')} />
          {t('Expiring {{date}}', { date: formatDateDistanceToNow(date) })}
        </Badge>
      </NavLink>
    </Tooltip>
  );
}

EvsNoScanDataBadge.propTypes = {
  companyId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default EvsNoScanDataBadge;
