import { gql } from '@apollo/client';

const FragmentCountPerLevel = gql`
  fragment FragmentCountPerLevel on CountPerLevel {
    critical
    high
    medium
    low
    info
    total
  }
`;

export default FragmentCountPerLevel;
