import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

function FormControl(props) {
  const {
    children = null,
    className = '',
    disabled = false,
    error = '',
    htmlFor = '',
    label = '',
  } = props;

  return (
    <div className={classNames('FormControl flex flex-col gap-1', className)}>
      {label && (
        <label
          className={classNames('od-font-third-regular ml-5', {
            'text-od-white-500': disabled,
          })}
          htmlFor={htmlFor}
        >
          {label}
        </label>
      )}
      <div>
        {children}
      </div>
      {error && (
        <div className="text-od-complementary-bad ml-4 od-font-third-regular">
          {error}
        </div>
      )}
    </div>
  );
}

FormControl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
};

export default FormControl;
