import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function FieldCountStatus(props) {
  const { negative = false, title = '', value } = props;
  const classes = classNames(
    'Field FieldCountStatus',
    'h-6 inline-flex gap-2 items-center justify-center od-secondary-medium rounded-od-100 pl-2 pr-3 py-1.5',
    {
      'text-od-complementary-bad': negative,
      'text-od-complementary-good': !negative,
    },
  );
  if (value === 0) {
    return (
      <div className="text-center" style={{ minWidth: '72px' }}>
        0
      </div>
    );
  }

  // TODO: Use tailwind styles for the background
  return (
    <div
      className={classes}
      style={{
        background: negative ? 'rgba(255, 101, 110, 0.2)' : 'rgba(51, 226, 129, 0.2)',
        minWidth: '72px',
      }}
      title={title}
    >
      {negative
        ? <FontAwesomeIcon icon={light('arrow-up-short-wide')} />
        : <FontAwesomeIcon icon={light('arrow-down-short-wide')} />}
      {value}
    </div>
  );
}

FieldCountStatus.propTypes = {
  negative: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.number.isRequired,
};

export default FieldCountStatus;
