import React from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SIGN_IN_MUTATION } from 'src/api/auth';
import Page from 'src/components/common/Page';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import useToast from 'src/hooks/useToast';
import { getUserConfig, userTypeStore } from 'src/users';
import SignInForm from 'src/views/Auth/SignIn/SignInForm';

function SignIn() {
  const { t } = useTranslation();
  const { showErrorMessage } = useToast();
  const navigate = useNavigate();

  const { fetchMe, loading } = useCurrentUser();

  const [signIn, { loading: saving }] = useMutation(
    SIGN_IN_MUTATION,
    {
      onCompleted: async ({ login }) => {
        if (login) {
          const { data: { me } } = await fetchMe();
          userTypeStore.set(me.userType);
          const _userConfig = getUserConfig(userTypeStore.get());
          navigate(_userConfig.buildUrl('/'));
        }
      },
    },
  );

  const onSubmit = async (formData) => {
    try {
      await signIn({ variables: {
        email: formData.email,
        password: formData.password,
      } });
    } catch (e) {
      showErrorMessage(t('Something went wrong. Please try again'));
      // Error is handled globally
    }
  };

  return (
    <Page
      className="h-full flex justify-center items-center"
      title={t('Sign in')}
    >
      <SignInForm
        onSubmit={onSubmit}
        saving={saving || loading}
      />
    </Page>
  );
}

export default SignIn;
