import React, { useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SETUP_PAYMENT_SOURCE_MUTATION } from 'src/api/billing';
import FormControl from 'src/components/common/FormControl';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import { isValidEmail } from 'src/helpers/validators';
import useGooglePlacesHelpers from 'src/hooks/useGooglePlacesHelpers';
import useToast from 'src/hooks/useToast';
import ChargebeeWidget from 'src/views/Billing/ChangePaymentMethod/ChargebeeWidget';

function CardForm(props) {
  const {
    email: defaultEmail = '',
    onSave,
    showEmailField,
  } = props;
  const { me } = useCurrentUser();

  const cardRef = useRef();
  const { getDefaultAddressObject, isValidAddress } = useGooglePlacesHelpers();
  const [numberState, setNumberState] = useState(null);
  const [expiryState, setExpiryState] = useState(null);
  const [cvvState, setCvvState] = useState(null);
  const [addressState, setAddressState] = useState(getDefaultAddressObject());
  const [isDirty, setIsDirty] = useState(false);
  const [email, setEmail] = useState(defaultEmail);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();

  const isValidCard = !numberState?.error
      && !expiryState?.error
      && !cvvState?.error
      && isValidAddress(addressState)
      && (showEmailField ? isValidEmail(email) : true);

  const isCompleteCard = numberState?.complete && expiryState?.complete && cvvState?.complete;

  const [savePaymentSource] = useMutation(
    SETUP_PAYMENT_SOURCE_MUTATION,
    {
      onCompleted: async ({ setupPaymentSource }) => {
        if (setupPaymentSource) {
          showSuccessMessage(t('Payment method successfully updated'));
          onSave();
        }
      },
    },
  );

  const createToken = () => cardRef.current.tokenize({
    billingAddress: addressState.street_address,
    billingCity: addressState.city,
    billingCountry: addressState.country,
    billingState: addressState.state,
    billingZip: addressState.zip_code,
  });

  const onSubmit = async (event) => {
    try {
      setIsLoading(true);
      setIsDirty(true);
      event.preventDefault();
      if (!isValidCard) {
        return;
      }
      const { token } = await createToken();
      await savePaymentSource({ variables: {
        customer: me.msp.id,
        email,
        token,
      } });
    } catch (e) {
      // Do nothing
    } finally {
      setIsLoading(false);
    }
  };

  const changeAddressField = (value, event) => {
    setAddressState((prevAddressState) => ({
      ...prevAddressState,
      [event.target.name]: event.target.value,
    }));
  };

  const emailError = (isDirty && !isValidEmail(email)) ? t('Must be a valid email') : '';
  const shouldDisableForm = !isValidCard || (!isCompleteCard && isDirty);

  return (
    <form className="CardForm px-4" noValidate onSubmit={onSubmit}>
      <div className="CardForm__wrapper p-6">
        <h5 className="mb-2">{t('Card details:')}</h5>
        <ChargebeeWidget
          ref={cardRef}
          cvvState={cvvState}
          expiryState={expiryState}
          isDirty={isDirty}
          numberState={numberState}
          setCvvState={setCvvState}
          setExpiryState={setExpiryState}
          setNumberState={setNumberState}
        />

        <h5 className="mt-4 mb-2">{t('Billing address:')}</h5>

        <div className="grid gap-2">
          <div>
            <FormControl htmlFor="street_address" label={t('Street address')}>
              <Input
                id="street_address"
                name="street_address"
                onInput={changeAddressField}
                placeholder={t('Manhattan Boulevard')}
                type="text"
                value={addressState.street_address}
              />
            </FormControl>
          </div>

          <div>
            <FormControl htmlFor="city" label={t('City')}>
              <Input
                className="Input--billing"
                name="city"
                onInput={changeAddressField}
                placeholder={t('Harvey')}
                type="text"
                value={addressState.city}
              />
            </FormControl>
          </div>

          <div>
            <FormControl htmlFor="city" label={t('State')}>
              <Input
                className="Input--billing"
                name="state"
                onInput={changeAddressField}
                placeholder={t('LA')}
                type="text"
                value={addressState.state}
              />
            </FormControl>
          </div>

          <div>
            <FormControl htmlFor="zip_code" label={t('Zip code')}>
              <Input
                className="Input--billing"
                name="zip_code"
                onInput={changeAddressField}
                placeholder={t('70058')}
                type="text"
                value={addressState.zip_code}
              />
            </FormControl>
          </div>

          <div>
            <FormControl htmlFor="country" label={t('Country')}>
              <Input
                className="Input--billing"
                name="country"
                onInput={changeAddressField}
                placeholder={t('USA')}
                type="text"
                value={addressState.country}
              />
            </FormControl>
          </div>

          {isDirty && !isValidAddress(addressState) && (
          <p className="text-od-complementary-bad text-xs mt-1">
            {t('Billing information is required')}
          </p>
          )}
        </div>

        {showEmailField && (
        <div className="mt-3">
          <FormControl
            error={emailError}
            htmlFor="email"
            label={t('Billing Email')}
          >
            <Input
              className="Input--billing"
              error={emailError}
              name="email"
              onInput={setEmail}
              placeholder={t('Enter email')}
              type="text"
              value={email}
            />
          </FormControl>
          <p className="text-[10px] mt-1 text-od-white-500">
            {t('* This email address will receive invoices from OPFOR Delta.')}
          </p>
        </div>
        )}

        <div className="flex justify-end mt-6">
          <Button
            className="w-full text-od-white-900"
            loading={isLoading}
            type="submit"
            visuallyDisabled={shouldDisableForm}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </form>
  );
}

CardForm.propTypes = {
  email: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  showEmailField: PropTypes.bool.isRequired,
};

export default CardForm;
