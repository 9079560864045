import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, useMatch } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import NavigationItem from 'src/design/models/NavigationItem';

function NavigationLinkNotifications() {
  const { t } = useTranslation();
  const { hasUnseenNotifications, unseenNotifications } = useCurrentUser();

  const notificationMenuItem = new NavigationItem(
    {
      absolutePath: '/notifications',
      icon: 'users',
      name: t('Notifications'),
      relativePath: 'notifications',
      to: '/notifications',
    },
  );

  const match = useMatch({
    path: notificationMenuItem.absolutePath,
  });

  const classes = classNames(
    'NavigationLinkNotifications',
    'pl-4 py-3 relative od-font-main-medium z-20 flex items-center gap-2',
    {
      'rounded-md od-sidebar-li--active': match,
      'text-od-white-500': !match || !hasUnseenNotifications,
      'text-od-white-900': match || hasUnseenNotifications,
    },
  );

  return (
    <NavLink
      className={classes}
      title={hasUnseenNotifications ? t('You have {{count}} unread notifications', { count: unseenNotifications }) : ''}
      to={notificationMenuItem.to}
    >
      <div className="relative">
        <FontAwesomeIcon icon={light('bell')} size="lg" />

        {hasUnseenNotifications && (
          <div className="bg-od-complementary-bad w-3 h-3 text-od-white-900
          rounded-full absolute top-0 right-0 flex items-center justify-center text-[8px]"
          >
            {unseenNotifications}
          </div>
        )}

      </div>
      {notificationMenuItem.name}
    </NavLink>
  );
}

export default NavigationLinkNotifications;
