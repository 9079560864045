import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DELETE_EVS_TARGET_GROUP_MUTATION } from 'src/api/evsTargetGroups';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { isSubscriptionExpiring } from 'src/constants/subscription';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import Button from 'src/design/ui-kit/Button/Button';
import useToast from 'src/hooks/useToast';
import EvsResumeSubscriptionModal from 'src/views/TacticsSettings/EvsTactic/EvsResumeSubscriptionModal';

function DeleteTargetGroupButton(props) {
  const { targetGroup } = props;
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [isOpenResumeSubscriptionModal, setIsOpenResumeSubscriptionModal] = useState(false);

  const { t } = useTranslation();

  const { tactics: { evs: { evsSubscriptionStatus, refetchQueries } } } = useCurrentCompany();

  const { showSuccessMessage } = useToast();

  const [deleteTargetGroup, { loading: saving }] = useMutation(
    DELETE_EVS_TARGET_GROUP_MUTATION,
    {
      onCompleted() {
        showSuccessMessage(t('Target group deleted successfully'));
        setIsOpenConfirmationModal(false);
      },
      refetchQueries,
    },
  );

  const onDelete = async () => {
    await deleteTargetGroup({
      variables: {
        targetGroup: targetGroup.id,
      },
    });
  };

  function onClick() {
    if (isSubscriptionExpiring(evsSubscriptionStatus)) {
      setIsOpenResumeSubscriptionModal(true);
    } else {
      setIsOpenConfirmationModal(true);
    }
  }

  return (
    <>
      <Button
        Icon={<FontAwesomeIcon icon={light('trash')} />}
        onClick={() => onClick()}
        size="sm"
        variant="dangerous"
      >
        {t('Delete')}
      </Button>
      <ConfirmationModal
        Cancel={(
          <Button
            disabled={saving}
            Icon={<FontAwesomeIcon icon={light('ban')} />}
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('No')}
          </Button>
        )}
        Confirm={(
          <Button
            Icon={<FontAwesomeIcon icon={light('thumbs-up')} />}
            loading={saving}
            onClick={onDelete}
          >
            {t('Yes')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        subtitle={t("Are you sure that you want to delete '{{ targetGroup }}'?", { targetGroup: targetGroup.name })}
        title={t('Delete target group')}
      />
      <EvsResumeSubscriptionModal
        isOpen={isOpenResumeSubscriptionModal}
        onClose={() => setIsOpenResumeSubscriptionModal(false)}
      >
        {t('Groups cannot be modified when the subscription has been stopped.')}
        <br />
        {t('Would you like to resume the subscription?')}
        <br />
        {t('Resuming your previous subscription will keep all of your target groups, schedules, and costs.')}
      </EvsResumeSubscriptionModal>
    </>
  );
}

DeleteTargetGroupButton.propTypes = {
  targetGroup: PropTypes.object.isRequired,
};

export default DeleteTargetGroupButton;
