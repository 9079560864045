/* eslint-disable react/no-this-in-sfc,object-shorthand,func-names */
import React from 'react';

import hexToRgba from 'hex-to-rgba';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

import * as colorsMap from 'src/design/theme/colors';

HighchartsMore(Highcharts);

const chartColor = '#536371';
const activeChartColor = '#6D7CFF';

function PolarChart(props) {
  const { chartData, showInfo } = props;
  const { categories, colors, data } = chartData;
  const max = Math.max(...data.flat());
  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      backgroundColor: 'transparent',
      borderColor: 'red',
      polar: true,
      type: 'area',
    },
    colors,
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    pane: {
      size: '55%',
    },
    series: [
      {
        color: chartColor,
        data: data[0],
        fillColor: hexToRgba(chartColor, 0.24),
        pointPlacement: 'on',
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      {
        color: activeChartColor,
        data: data[1],
        fillColor: hexToRgba(activeChartColor, 0.24),
        pointPlacement: 'on',
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    ],
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: colorsMap['od-black-900'],
      borderRadius: 12,
      borderWidth: 0,
      formatter: function () {
        const last = this.points[0];
        const current = this.points[1];
        return (`
          <div class="
              bg-od-black-900 rounded-xl od-font-secondary-semi-bold od-font-family text-od-white-900 grid gap-2
            ">
             <p>${last.key}</p>
             <ul class="grid gap-2">
                <li class="flex gap-2">
                  <div class="h-3.5 w-3.5 rounded" style="background: ${last.color}"></div>
                  <p>Last scan:</p>
                  <p>${last.y}</p>
                </li>
                
                <li class="flex gap-2">
                  <div class="h-3.5 w-3.5 rounded" style="background: ${current.color}"></div>
                  <p>Current scan:</p>
                  <p>${current.y}</p>
                </li>
             </ul>
          </div>
      `);
      },
      opacity: 1,
      shape: 'square',
      shared: true,
      useHTML: true,
    },
    xAxis: {
      categories,
      gridLineColor: chartColor,
      labels: {
        formatter: function () {
          const name = this.value;
          const index = categories.indexOf(name);
          const color = colors[index];
          if (!color) {
            return '';
          }
          const tick = `<div class="h-2 w-2" style="background: ${color}"></div>`;
          const text = `<div class="leading-4 text-center">
                          <span class="od-font-third-semi-bold uppercase text-od-white-500">${name}</span> <br>
                        </div>`;
          const getWrapperClassByIndex = () => {
            if (index === 0) {
              return 'mt-1 flex-col-reverse';
            }
            if (index === 1) {
              if (showInfo) {
                return '-ml-4 mt-2.5';
              }
              return '-ml-4 mt-0.5';
            }
            if (index === 2) {
              if (showInfo) {
                return 'flex-col -ml-8 mt-1';
              }
              return 'flex-col -mt-1';
            }
            if (index === 3) {
              if (showInfo) {
                return 'flex-col -mr-5 mt-1';
              }
              return 'flex-col -mr-[26px] mt-[22px]';
            }
            if (index === 4) {
              return 'flex-row-reverse -mr-4 mt-3';
            }
            return '';
          };
          return (`
            <div class="flex gap-1 items-center ${getWrapperClassByIndex()}">
              ${tick}
              ${text}
            </div>
      `);
        },
        shared: false,
        useHTML: true,
      },
      lineWidth: 0,
      maxPadding: 1,
      tickmarkPlacement: 'on',
    },
    yAxis: {
      gridLineColor: chartColor,
      gridLineInterpolation: 'polygon',
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      tickInterval: max + 2,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

PolarChart.propTypes = {
  chartData: PropTypes.object.isRequired,
  showInfo: PropTypes.bool.isRequired,
};

export default PolarChart;
