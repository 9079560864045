import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';
import BciResumeSubscriptionModal from 'src/views/TacticsSettings/BciTactic/BciResumeSubscriptionModal';

function BciTacticResumeButton() {
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { t } = useTranslation();

  const onClickResumeButton = async () => {
    await setIsOpenConfirmationModal(true);
  };

  return (
    <>
      <Button
        Icon={<FontAwesomeIcon icon={light('play')} />}
        onClick={onClickResumeButton}
        size="sm"
      >
        {t('Resume')}
      </Button>
      <BciResumeSubscriptionModal
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
      />
    </>
  );
}

export default BciTacticResumeButton;
