import React, { Fragment } from 'react';

import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import map from 'lodash-es/map';
import startCase from 'lodash-es/startCase';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import IconExpanded from 'src/design/custom-icons/IconExpanded/IconExpanded';

function VulnerabilityRowDetailsPlugin(props) {
  const { attributes } = props;

  const { t } = useTranslation();

  return (
    <section className="bg-od-black-300 rounded-lg">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className="w-full text-od-white-900 od-font-secondary-medium border-b border-b-od-black-500 py-4 px-5"
            >
              <div className="mr-1 flex gap-3 items-center">
                <IconExpanded expanded={open} />
                {t('Plugin Details')}
              </div>
            </Disclosure.Button>
            <Transition show={open}>
              <Disclosure.Panel>
                <div
                  className="grid gap-x-10 gap-y-5 items-center rounded-od-8 p-5"
                  style={{ gridTemplateColumns: 'max-content auto' }}
                >
                  {map(attributes, (value, key) => (
                    <Fragment key={key}>
                      <span className="od-font-main-regular text-od-white-500 max-w-max">{startCase(key)}</span>
                      <span
                        className={classNames('od-font-main-medium', {
                          'text-od-complementary-medium': ['od-vulnerability-risk'].includes(key),
                        })}
                      >
                        {value}
                      </span>
                    </Fragment>
                  ))}
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </section>
  );
}

VulnerabilityRowDetailsPlugin.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export default VulnerabilityRowDetailsPlugin;
