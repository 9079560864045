import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import EvsScheduleScanModal from 'src/components/tactics.evs/scans/EvsScheduleScanModal/EvsScheduleScanModal';
import Button, { buttonSizes } from 'src/design/ui-kit/Button/Button';

function EvsScheduleScanButton(props) {
  const { initialTargetGroup = null, size } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button
        Icon={<FontAwesomeIcon icon={light('calendar-days')} />}
        onClick={() => setIsOpen(true)}
        size={size}
        variant="secondary"
      >
        {t('Schedule')}
      </Button>
      <EvsScheduleScanModal
        initialTargetGroup={initialTargetGroup}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}

EvsScheduleScanButton.propTypes = {
  initialTargetGroup: PropTypes.object,
  size: PropTypes.oneOf(buttonSizes).isRequired,
};

export default EvsScheduleScanButton;
