import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const isValidName = (value) => /^[a-z,.'-]+$/i.test(value);

// eslint-disable-next-line max-len
export const isValidEmail = (value) => !!value && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

// export const isValid
export function usePasswordValidations() {
  const { t } = useTranslation();
  const validationsMap = {
    min: {
      message: t('Contains at least 12 characters'),
    },
    oneNumber: {
      message: t('Contains at least one number'),
      validator: (value) => value && /.*[0-9].*/.test(value),
    },
    oneSpecialCharacter: {
      message: i18n.t('Contains at least one special character'),
      validator: (value) => value && /[\p{P}\p{S}]/u.test(value),
    },
    uppercaseLowercaseMixture: {
      message: i18n.t('Contains a mixture of uppercase and lowercase letters'),
      validator: (value) => value && /^(?=.*[a-z])(?=.*[A-Z])/.test(value),
    },
  };

  const passwordValidator = yup
    .string()
    .min(12, validationsMap.min.message)
    .required(t('Field is required'))
    .test('oneNumber', validationsMap.oneNumber.message, validationsMap.oneNumber.validator)
    .test(
      'oneSpecialCharacter',
      validationsMap.oneSpecialCharacter.message,
      validationsMap.oneSpecialCharacter.validator,
    )
    .test(
      'uppercaseLowercaseMixture',
      validationsMap.uppercaseLowercaseMixture.message,
      validationsMap.uppercaseLowercaseMixture.validator,
    );

  return {
    passwordValidator,
    validationsMap,
  };
}
