import { VulnerabilityStatus } from 'src/constants/evs';

const countPerLevel = {
  critical: 0,
  high: 0,
  info: 0,
  low: 0,
  medium: 0,
  total: 0,
};

const deltaStatistics = {
  newCount: {
    total: 0,
  },
  remediatedCount: {
    total: 0,
  },
};

const statistics = {
  currentCount: 0,
  deadAssetsCount: 0,
  exploitCountPerLevel: countPerLevel,
  liveAssetsCount: 0,
  vulnCountPerLevel: countPerLevel,
};

/**
 * Transform all scans response with a flat array of scans
 * @param data
 */
export function transformAllScansResponse(data) {
  if (!data) {
    return {
      items: [],
      pageInfo: {
        endCursor: null,
        hasNextPage: false,
      },
    };
  }
  return {
    items: data.allScans.edges.map((edge) => ({
      ...edge.node,
      statistics: edge.node.statistics || statistics,
    })),
    pageInfo: data.allScans.pageInfo,
  };
}

export function transformScan(scan) {
  return {
    ...scan,
    deltaStatistics: scan.deltaStatistics || deltaStatistics,
    statistics: scan.statistics || statistics,
  };
}

export function transformScanResponse(data) {
  if (!data) {
    return {
      scan: null,
    };
  }
  return {
    scan: transformScan(data.scan),
  };
}

/**
 * Function to retrieve complex data for vulnerabilities
 * @param data
 */
function retrieveVulnerabilitiesData(data) {
  if (!data) {
    return {
      vulnerabilitiesByStatus: [],
      vulnerabilityCountsByStatus: countPerLevel,
    };
  }

  return {
    vulnerabilitiesByStatus: data.vulnerabilitiesByStatus.edges
      .map((edge) => edge.node)
      .map((vulnerability) => ({
        ...vulnerability,
        assets: vulnerability.assets.edges.map((edge) => edge.node),
      })),
    vulnerabilityCountsByStatus: data.vulnerabilityCountsByStatus,
  };
}

export function transformScanVulnerabilitiesResponse(data) {
  if (!data) {
    return {
      [VulnerabilityStatus.CURRENT]: {
        vulnerabilitiesByStatus: [],
        vulnerabilityCountsByStatus: countPerLevel,
      },
      [VulnerabilityStatus.NEW]: {
        vulnerabilitiesByStatus: [],
        vulnerabilityCountsByStatus: countPerLevel,
      },
      [VulnerabilityStatus.REMEDIATED]: {
        vulnerabilitiesByStatus: [],
        vulnerabilityCountsByStatus: countPerLevel,
      },
    };
  }
  return {
    [VulnerabilityStatus.CURRENT]: retrieveVulnerabilitiesData(
      data[VulnerabilityStatus.CURRENT].deltaStatistics,
    ),
    [VulnerabilityStatus.NEW]: retrieveVulnerabilitiesData(
      data[VulnerabilityStatus.NEW].deltaStatistics,
    ),
    [VulnerabilityStatus.REMEDIATED]: retrieveVulnerabilitiesData(
      data[VulnerabilityStatus.REMEDIATED].deltaStatistics,
    ),
  };
}
