import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';

function AllDomainAnalyticsSection(props) {
  const { bcStatistics } = props;

  const { t } = useTranslation();

  return (
    <section className="AllDomainAnalyticsSection grid grid-cols-12 xl:grid-cols-5 gap-3">
      <InfoCard
        className="col-span-4 xl:col-span-1"
        description={t('Domains monitored')}
      >
        {bcStatistics.domainCount}
      </InfoCard>
      <InfoCard
        className="col-span-4 xl:col-span-1"
        description={t('Total Breached Records')}
      >
        {bcStatistics.recordCount}
      </InfoCard>
      <InfoCard
        className="col-span-4 xl:col-span-1"
        description={t('Unique routes Impacted')}
      >
        {bcStatistics.impactedUserCount}
      </InfoCard>
      <InfoCard
        className="col-span-6 xl:col-span-1"
        description={t('Unique Credential Sets')}
      >
        {bcStatistics.credentialSetCount}
      </InfoCard>
      <InfoCard
        className="col-span-6 xl:col-span-1"
        description={t('Total Breached Databases')}
      >
        {bcStatistics.breachCount}
      </InfoCard>
    </section>
  );
}

AllDomainAnalyticsSection.propTypes = {
  bcStatistics: PropTypes.object.isRequired,
};

export default AllDomainAnalyticsSection;
