export default class NavigationItem {
  constructor({
    Icon,
    absolutePath,
    component,
    name,
    relativePath,
    to,
  }) {
    this.Icon = Icon;
    this.absolutePath = absolutePath;
    this.component = component;
    this.name = name;
    this.to = to;
    this.relativePath = relativePath;

    if (!this.relativePath) {
      throw new Error('NavigationItem: absolutePath is required to highlight active link');
    }
  }
}
