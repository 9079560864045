import React from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { EVS_TARGET_GROUPS, transformResponse } from 'src/api/evsTargetGroups';
import Select, { SelectOptionModel } from 'src/design/ui-kit/Select/Select';
import useUserParams from 'src/hooks/useUserParams';

// https://react-select.com/components
// eslint-disable-next-line react/prop-types
function Option({ data: option, innerProps }) {
  const { t } = useTranslation();

  return (
    <div
      {...innerProps}
      className="Select__option p-2 od-font-secondary-regular hover:shadow-od-select-option rounded-od-8
       text-od-white-900 cursor-pointer mb-2 flex justify-between w-full items-center"
    >
      {/* eslint-disable-next-line react/prop-types */}
      { option.label }
      <span className="text-od-white-500">
        {/* eslint-disable-next-line react/prop-types */}
        { t('{{count}} targets', { count: option.item.targetCount }) }
      </span>
    </div>
  );
}

// TODO: Add pagination
const first = 100;
const scansFirst = 10;

export function buildOption(targetGroup) {
  return new SelectOptionModel(
    targetGroup.id,
    targetGroup.name,
    targetGroup.id,
    targetGroup,
  );
}

function EvsTargetGroupSelect(props) {
  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const { data, loading } = useQuery(EVS_TARGET_GROUPS, {
    variables: {
      companyId,
      first,
      scansFirst,
    },
  });
  const { items: evsTargetGroups } = transformResponse(data);

  const options = evsTargetGroups.map(buildOption);

  return (
    <Select
      components={{
        Option,
      }}
      hasSeparator
      isLoading={loading}
      loadingMessage={() => t('Loading...')}
      options={options}
      placeholder={t('Select group')}
      {...props}
    />
  );
}

export default EvsTargetGroupSelect;
