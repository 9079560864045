import React from 'react';

import { ReactComponent as IconBci } from 'src/design/custom-icons/bci.svg';

function IconBreachCredentials() {
  return (
    <div className="IconBreachCredentials bg-od-cyan/10 flex items-center
        justify-center p-2.5 text-od-cyan m-0 rounded-[10px]"
    >
      <IconBci className="fill-od-cyan" />
    </div>
  );
}

export default IconBreachCredentials;
