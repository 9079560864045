export const DEFAULT_DATE_FORMAT = 'MMM dd, yyyy';

export const MONTH_YEAR_FORMAT = 'MMM yyyy';

export const DATETIME = 'MMM dd, yyyy HH:mm';

export const SHORT_DATE = 'dd LLL';

export const LONG_LOCALIZED_DATE = 'PPP';

export const DEFAULT_TIME_FORMAT = 'HH:mm';
