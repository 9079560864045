import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'src/helpers/date';
import DomainToggleSubscriptionButton from 'src/views/TacticBci/DomainToggleSubscriptionButton';

function DomainGridRow(props) {
  const { data: domain } = props;
  const { t } = useTranslation();

  // eslint-disable-next-line max-len
  const expirationMessage = () => t('This subscription has been deactivated. All data will be lost after {{date}} unless the subscription is resumed.', {
    date: formatDate(Date.now()),
  });

  return (
    <div className="TacticBciDomainsTableViewRow py-3 bg-od-black-500
     rounded-od-10 items-center gap-4 px-5 flex"
    >
      <span className={classNames('flex items-center gap-2', {
        'text-od-white-500': !domain.enabled,
        'text-od-white-900': domain.enabled,
      })}
      >
        <FontAwesomeIcon icon={light('server')} />
        <span className="od-font-secondary-regular">{domain.name}</span>
      </span>

      <span className="text-od-complementary-medium text-xs flex-1">
        {!domain.enabled && expirationMessage()}
      </span>

      <DomainToggleSubscriptionButton domain={domain} />
    </div>
  );
}

DomainGridRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DomainGridRow;
