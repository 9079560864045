export const ORIGIN = 'origin';

export const HOST = 'host';

export const REFERER = 'referer';

export const ContentType = 'Content-Type';

export const XCSRFToken = 'X-CSRFToken';

export const DOCX_MEDIA_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
