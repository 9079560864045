import React from 'react';

import { useQuery } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { MSP_DASHBOARD } from 'src/api/mspDashboard';
import Page from 'src/components/common/Page';
import IconBreachCredentials from 'src/design/custom-icons/IconBreachCredentials/IconBreachCredentials';
// import IconNews from 'src/design/custom-icons/IconNews/IconNews';
import IconVulnerability from 'src/design/custom-icons/IconVulnerability/IconVulnerability';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';
import Loading from 'src/design/ui-kit/Loading/Loading';
import toPercentage from 'src/helpers/toPercentage';
import useUserParams from 'src/hooks/useUserParams';
// import MSPDashboardNews from 'src/views/MSPDashboard/MSPDashboardNews/MSPDashboardNews';

const mspDashboardDefaultValue = {
  admins: 0,
  analysts: 0,
  bciInfos: {
    deployment: 0,
    hitsThisMonth: 0,
    totalBreaches: 0,
    totalDeployment: 0,
    totalRecords: 0,
  },
  companies: 0,
  evsInfos: {
    deployment: 0,
    exploitableVulnerabilities: 0,
    scannedThisMonth: 0,
    totalDeployment: 0,
    unsheduledGroups: 0,
  },
};

// TODO: Add info icons with tooltips
function MSPDashboard() {
  const { t } = useTranslation();
  const { mspId } = useUserParams();

  const { data, error, loading } = useQuery(MSP_DASHBOARD, {
    variables: {
      mspId,
    },
  });

  const mspDashboard = data?.mspDashboard || mspDashboardDefaultValue;

  if (loading || error) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  const formatDeployment = (deployment, totalDeployment) => `${toPercentage(deployment)} (${totalDeployment})`;

  // TODO: Deployment and totalDeployment should be displayed as percentages
  return (
    <Page title={t('MSP Dashboard')}>
      <h1 className="mb-4">{t('MSP Dashboard')}</h1>
      <section className="grid lg:grid-cols-3 gap-4 mt-4">
        <InfoCard description={t('Total companies')}>
          {mspDashboard.companies}
        </InfoCard>

        <InfoCard
          color="#25D272"
          description={t('MSP Admins')}
          Icon={<FontAwesomeIcon icon={light('code')} size="xl" />}
        >
          {mspDashboard.admins}
        </InfoCard>

        <InfoCard description={t('MSP Analysts')}>
          {mspDashboard.analysts}
        </InfoCard>
      </section>

      <h2 className="mt-8 text-xl">{t('Global tactic statistics')}</h2>

      <h3 className="text-base flex gap-3 items-center mt-5">
        <IconVulnerability />
        <span>{t('External vulnerability scans')}</span>
      </h3>

      <section className="grid lg:grid-cols-4 gap-4 mt-4">
        <InfoCard
          description={t('Deployment')}
          tooltip={t('This value is the number of your total customers who have this tactic enabled.')}
        >
          {formatDeployment(mspDashboard.evsInfos.deployment, mspDashboard.evsInfos.totalDeployment)}
        </InfoCard>

        <InfoCard description={t('Unscheduled groups')}>
          {mspDashboard.evsInfos.unsheduledGroups}
        </InfoCard>

        <InfoCard description={t('Hosts scanned this month')}>
          {mspDashboard.evsInfos.scannedThisMonth}
        </InfoCard>

        <InfoCard description={t('Exploitable vulnerabilities')}>
          {mspDashboard.evsInfos.exploitableVulnerabilities}
        </InfoCard>
      </section>

      <h3 className="text-base flex gap-3 items-center mt-5">
        <IconBreachCredentials />
        <span>{t('Breached credentials')}</span>
      </h3>

      <section className="grid lg:grid-cols-4 gap-4 mt-4">
        <InfoCard
          description={t('Deployment')}
          tooltip={t('This value is the number of your total customers who have this tactic enabled.')}

        >
          {formatDeployment(mspDashboard.bciInfos.deployment, mspDashboard.bciInfos.totalDeployment)}
        </InfoCard>

        <InfoCard description={t('Total records')}>
          {mspDashboard.bciInfos.totalRecords}
        </InfoCard>

        <InfoCard description={t('Total breaches')}>
          {mspDashboard.bciInfos.totalBreaches}
        </InfoCard>

        <InfoCard description={t('Hits this month')}>
          {mspDashboard.bciInfos.hitsThisMonth}
        </InfoCard>
      </section>

      {/* <h3 className="text-base flex gap-3 items-center mt-5">
        <IconNews />
        <span>{t('News')}</span>
      </h3>

      <section>
        <MSPDashboardNews />
      </section> */}
    </Page>
  );
}

export default MSPDashboard;
