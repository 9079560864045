import React, { createContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { BCI_SUBSCRIPTION } from 'src/api/bciSubscription';
import { SubscriptionStatus } from 'src/constants/subscription';
import useFeatures from 'src/hooks/useFeatures';
import useUserParams from 'src/hooks/useUserParams';

export const CompanyBciSubscriptionContext = createContext({
  /**
   * Bci subscription status
   */
  bciSubscriptionStatus: SubscriptionStatus.INACTIVE,

  /**
   * Company bci subscription data
   */
  companyBciSubscription: null,

  /**
   * Bci tactic data error
   */
  error: null,

  /**
   * Loading state for bci tactic data queries
   */
  loading: true,

  /**
   * List of bci queries to refetch after mutations
   */
  refetchQueries: [],
});

export function CompanyBciSubscriptionProvider(props) {
  const { children, includeBciStatistics = false } = props;
  const { companyId } = useUserParams();
  const { isBCIEnabled } = useFeatures();

  const { data, error, loading } = useQuery(BCI_SUBSCRIPTION, {
    skip: !isBCIEnabled(),
    variables: {
      companyId,
      includeBciStatistics,
    },
  });

  const { companyBciSubscription } = data || { companyBciSubscription: null };

  const bciSubscriptionStatus = companyBciSubscription?.status ?? SubscriptionStatus.INACTIVE;

  const value = useMemo(() => ({
    bciSubscriptionStatus,
    companyBciSubscription,
    error,
    loading,
    refetchQueries: [BCI_SUBSCRIPTION],
  }), [bciSubscriptionStatus, companyBciSubscription, error, loading]);

  return (
    <CompanyBciSubscriptionContext.Provider value={value}>
      {children}
    </CompanyBciSubscriptionContext.Provider>
  );
}

CompanyBciSubscriptionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  includeBciStatistics: PropTypes.bool,
};

export const useCurrentCompanyBciSubscription = () => React.useContext(CompanyBciSubscriptionContext);
