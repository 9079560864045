import React, { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import LimitSwitcher from 'src/components/charts/ScanStackedChart/LimitSwitcher';
import StackedChart from 'src/components/charts/ScanStackedChart/StackedChart';
import { SHORT_DATE } from 'src/constants/date';
import { formatDate } from 'src/helpers/date';
import { useScanVulnerabilitySeverityDefinitions } from 'src/helpers/scan';

function buildChartData(scans, limit = 5, showInfo = false) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const vulnerabilitySeverityDefinitions = useScanVulnerabilitySeverityDefinitions({ showInfo });
  const types = [...vulnerabilitySeverityDefinitions].reverse();
  const filteredScans = [...scans.slice(0, limit)].reverse();
  const colors = types.map(({ color }) => color);
  const data = types.map(({ color, key, name }) => ({
    data: filteredScans.map((scan) => ({
      color,
      x: formatDate(new Date(scan.ranAt), SHORT_DATE),
      y: scan.statistics.vulnCountPerLevel[key],
    })),
    name,
  }));
  const categories = data[0].data.map(({ x }) => x);

  return {
    categories,
    colors,
    series: data.map((item) => ({
      ...item,
      data: item.data.map(({ y }) => y),
    })),
  };
}

function ScanStackedChart(props) {
  const { className = '', scans, showInfo, style = {}, title } = props;
  const [limit, setLimit] = useState(5);

  const chartData = buildChartData(scans, limit, showInfo);

  return (
    <div
      className={classNames('ScanStackedChart p-6', className)}
      style={style}
    >
      <div className="flex justify-between">
        <div className="od-font-title-semi-bold">
          {title}
        </div>
        {
          scans.length > 5 && (
            <LimitSwitcher
              onSwitch={setLimit}
              selected={limit}
              values={[5, 10]}
            />
          )
        }
      </div>
      <StackedChart chartData={chartData} />
    </div>
  );
}

ScanStackedChart.propTypes = {
  className: PropTypes.string,
  scans: PropTypes.array.isRequired,
  showInfo: PropTypes.bool.isRequired,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default ScanStackedChart;
