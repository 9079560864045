import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import BulkAddStringsForm from 'src/components/common/BulkEditStringsForm/BulkAddStringsForm';
import SidebarModal from 'src/components/modals/SidebarModal';
import Divider from 'src/design/ui-kit/Divider/Divider';
import FieldPeriodCost from 'src/design/ui-kit/Field/FieldPeriodCost/FieldPeriodCost';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import { isDomain } from 'src/helpers/ip';
import { formatPrice, useConvertCentValue } from 'src/helpers/price';
import useBCISubscriptionActions from 'src/views/TacticBci/AddDomains/useBCISubscriptionActions';

import AddDomainsContinueButton from './AddDomainsContinueButton';

const schema = () => yup.object({
  domains: yup
    .array()
    .test(
      'notEmpty',
      i18n.t('At least 1 domain is required'),
      (arr) => arr.length > 0,
    ),
}).required();

const initialFormData = () => ({
  domains: [],
});

function AddDomainsSidebarModal(props) {
  const { isOpen, mode, onClose } = props;

  const { convertFromCentValue } = useConvertCentValue(2);
  const { t } = useTranslation();

  const { clearErrors, control, formState, reset, watch } = useForm({
    defaultValues: initialFormData(),
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const handleClose = () => {
    onClose();
    clearErrors();
    reset(initialFormData());
  };

  const formData = watch();

  const {
    domainSetupPrice,
    estimateAddThisMonth,
    loading,
    onConfirm,
    totalEstimateNextMonth,
  } = useBCISubscriptionActions({
    formData,
    handleClose,
    mode,
  });

  return (
    <SidebarModal
      contentClassName="grid flex-1 min-h-0"
      contentStyle={{
        gridTemplateRows: '1px minmax(0, 1fr) 81px 1px 105px',
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={mode === 'subscription' ? t('Add domains to monitor') : t('Add domains')}
    >
      <Divider />

      <div className="pl-6 pr-10 pt-3">
        <Controller
          control={control}
          name="domains"
          render={({ field: { onChange } }) => (
            <BulkAddStringsForm
              onChange={onChange}
              placeholder={t('Domain name')}
              validator={isDomain}
            />
          )}
        />
      </div>

      <div className="pl-6 pr-10 pb-4">
        <Divider />
        <section className="mt-3">
          <div className="flex items-center justify-between">
            <span className="od-font-main-regular text-od-white-500">
              {t('Cost this month:')}
            </span>
            <span className="od-font-main-regular text-od-white-500">
              {formatPrice(convertFromCentValue(estimateAddThisMonth))}
            </span>
          </div>
          <div className="flex items-center justify-between mt-3">
            <span className="od-font-main-regular text-od-white-500">
              {t('Cost next month:')}
            </span>
            <span className="od-font-main-regular text-od-white-500">
              {formatPrice(convertFromCentValue(totalEstimateNextMonth))}
            </span>
          </div>
        </section>
      </div>

      <Divider />

      <section className="flex flex-col mt-2 pl-6 pr-10 gap-5">
        <div className="flex items-center justify-between">
          <span className="od-font-title-regular">
            {t('Due for today:')}
          </span>
          <span className="flex items-center gap-2.5">
            <Tooltip
              text={t('This price includes prorated price for each domain plus domain setup fee({{fee}}).', {
                fee: formatPrice(convertFromCentValue(domainSetupPrice)),
              })}
            >
              <FontAwesomeIcon icon={light('circle-info')} />
            </Tooltip>
            <FieldPeriodCost value={estimateAddThisMonth} />
          </span>
        </div>

        <AddDomainsContinueButton
          disabled={!formState.isValid || loading}
          loading={loading}
          onConfirm={onConfirm}
          totalPrice={estimateAddThisMonth}
        />
      </section>
    </SidebarModal>
  );
}

AddDomainsSidebarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['subscription', 'domains']).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddDomainsSidebarModal;
