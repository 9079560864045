import React from 'react';

import { useTranslation } from 'react-i18next';

import useNotificationsLoader from 'src/api/loaders/useNotificationsLoader';
import Page from 'src/components/common/Page';
import TableView from 'src/components/common/Table/TableView';
import EmptyState from 'src/design/ui-kit/EmptyState/EmptyState';
import Loading from 'src/design/ui-kit/Loading/Loading';

import NotificationsTableViewRow from './NotificationsTableViewRow';
import useNotificationsColumns from './useNotificationsColumns';

const first = 20;

function Notifications() {
  const columns = useNotificationsColumns();
  const { t } = useTranslation();

  const {
    data: { items: allNotifications, pageInfo },
    error, fetchMore, isLoadingMore, loading,
  } = useNotificationsLoader({
    variables: {
      first,
    },
  });

  const onLoadMore = async () => {
    await fetchMore({
      variables: {
        after: pageInfo.endCursor,
        first,
      },
    });
  };

  if (error || loading) {
    return (
      <Page title={t('Notifications')}>
        <Loading />
      </Page>
    );
  }

  if (allNotifications.length === 0 && !loading) {
    return (
      <Page title={t('Notifications')}>
        <EmptyState title={t('You currently have no notifications.')} />
      </Page>
    );
  }

  return (
    <Page title={t('Companies')}>
      <div className="flex items-center gap-4" />

      <div className="mt-8">
        <TableView
          columns={columns}
          data={allNotifications}
          isLoadingMore={isLoadingMore}
          onLoadMore={onLoadMore}
          pageInfo={pageInfo}
          TableRow={NotificationsTableViewRow}
        />
      </div>
    </Page>
  );
}

export default Notifications;
