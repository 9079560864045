import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function IconVulnerability() {
  return (
    <FontAwesomeIcon
      className="
        IconVulnerability
        rounded-[10px] bg-od-yellow/10 flex items-center
        justify-center p-2.5 text-od-yellow m-0 max-w-[40px] h-max-[40px] border-box
      "
      icon={light('radar')}
      size="lg"
    />
  );
}

export default IconVulnerability;
