import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import TargetGroupSidebarModal from
  'src/components/modals/TargetGroupSidebarModal/TargetGroupSidebarModal';
import { isSubscriptionExpiring } from 'src/constants/subscription';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import Button from 'src/design/ui-kit/Button/Button';
import EvsResumeSubscriptionModal from 'src/views/TacticsSettings/EvsTactic/EvsResumeSubscriptionModal';

function AddTargetGroupButton() {
  const [isOpenTargetGroupSidebarModal, setIsOpenTargetGroupSidebarModal] = useState(false);
  const [isOpenResumeSubscriptionModal, setIsOpenResumeSubscriptionModal] = useState(false);

  const { tactics: { evs: { evsSubscriptionStatus } } } = useCurrentCompany();
  const { t } = useTranslation();

  function onClick() {
    if (isSubscriptionExpiring(evsSubscriptionStatus)) {
      setIsOpenResumeSubscriptionModal(true);
    } else {
      setIsOpenTargetGroupSidebarModal(true);
    }
  }

  return (
    <>
      <Button
        Icon={<FontAwesomeIcon icon={light('square-plus')} />}
        onClick={() => onClick()}
        title={t('Create Target Group')}
      >
        {t('Add group')}
      </Button>
      {isOpenTargetGroupSidebarModal && (
        <TargetGroupSidebarModal
          isOpen={isOpenTargetGroupSidebarModal}
          mode="targetGroup"
          onClose={() => setIsOpenTargetGroupSidebarModal(false)}
        />
      )}

      <EvsResumeSubscriptionModal
        isOpen={isOpenResumeSubscriptionModal}
        onClose={() => setIsOpenResumeSubscriptionModal(false)}
      >
        {t('Groups cannot be modified when the subscription has been stopped.')}
        <br />
        {t('Would you like to resume the subscription?')}
        <br />
        {t('Resuming your previous subscription will keep all of your target groups, schedules, and costs.')}
      </EvsResumeSubscriptionModal>
    </>
  );
}

export default AddTargetGroupButton;
