import { useParams } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';

function useUserParams() {
  const { me } = useCurrentUser();
  const params = useParams();

  const companyId = params.companyId || (me?.company?.id ?? null);
  const mspId = me?.msp?.id ?? null;

  return {
    companyId,
    mspId,
    ...params,
  };
}

export default useUserParams;
