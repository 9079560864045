import filter from 'lodash-es/filter';
import includes from 'lodash-es/includes';
import uniq from 'lodash-es/uniq';

export function hasDuplicates(array) {
  return uniq(array).length !== array.length;
}

export function updateAt(index, item, array) {
  return [
    ...array.slice(0, index),
    item,
    ...array.slice(index + 1),
  ];
}

export function deleteAt(index, array) {
  return [
    ...array.slice(0, index),
    ...array.slice(index + 1),
  ];
}

export function findDuplicates(array) {
  return filter(array, (val, i, iteratee) => includes(iteratee, val, i + 1));
}
