import React from 'react';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const sizes = ['sm', 'md'];

// Map of size to font awesome icon size
const sizesMap = {
  md: '3x',
  sm: '2x',
};

function Loading(props) {
  const {
    className = 'py-4',
    size = sizesMap.md,
  } = props;
  const { t } = useTranslation();

  const iconSize = sizesMap[size];

  return (
    <div className={classNames('Loading flex justify-center', className)}>
      <FontAwesomeIcon
        aria-label={t('Loading')}
        className="text-od-primary"
        icon={solid('spinner')}
        size={iconSize}
        spinPulse
      />
    </div>
  );
}

Loading.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(sizes),
};

export default Loading;
