import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';

export const tooltipPositions = ['top', 'bottom'];

function InfoCard(props) {
  const {
    children = null,
    className = '',
    description,
    fluidHeight = false,
    tag = 'div',
    tooltip = '',
    tooltipPosition = 'top',
  } = props;

  const CustomTag = tag;

  return (
    <CustomTag className={classNames(
      'InfoCard p-5 rounded-od-20 shadow-od-card flex justify-between bg-od-black-500',
      className,
      { 'h-26': !fluidHeight },
    )}
    >
      <div className="w-full">
        <div className="od-font-main-medium text-od-white-500 flex gap-1 items-center">
          {description}
          {tooltip && (
            <div className="px-1">
              <Tooltip
                popoverProps={{
                  positions: [tooltipPosition],
                }}
                text={tooltip}
              >
                <FontAwesomeIcon icon={light('circle-info')} />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="mt-3">
          { children }
        </div>
      </div>
    </CustomTag>
  );
}

InfoCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  fluidHeight: PropTypes.bool,
  tag: PropTypes.oneOf(['div', 'li']),
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.oneOf(tooltipPositions),
};

export default InfoCard;
