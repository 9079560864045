import React from 'react';

import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SCAN_OVERVIEW_SCAN_LIST_QUERY } from 'src/api/evsScans/evsScans';
import { transformScan } from 'src/api/evsScans/evsScansHelpers';
import ScanOSPieChart from 'src/components/charts/ScanPieChart/ScanOSPieChart';
import ScanPieChart from 'src/components/charts/ScanPieChart/ScanPieChart';
import ScanPolarChart from 'src/components/charts/ScanPolarChart/ScanPolarChart';
import ScanStackedChart from 'src/components/charts/ScanStackedChart/ScanStackedChart';
import Loading from 'src/design/ui-kit/Loading/Loading';
import ScanOverviewDetails from 'src/views/EvsScanOverview/ScanOverviewDetails';

function EvsScanOverviewContent(props) {
  const { scan, showInfo } = props;

  const { t } = useTranslation();

  const hasMultipleScans = !!scan.deltaStatistics?.previousScan;

  const {
    data: allScansData,
    error,
    loading,
  } = useQuery(SCAN_OVERVIEW_SCAN_LIST_QUERY, {
    skip: !hasMultipleScans,
    variables: {
      endAt: scan.ranAt,
      targetGroup: scan.owner.id,
    },
  });

  const allScans = allScansData?.allScans?.edges?.map((edge) => transformScan(edge.node)) ?? [];

  const hasLoadedScans = !error && !loading && allScans.length > 0;

  return (
    <section className="EvsScanOverviewContent grid gap-6 mt-8">
      <ScanOverviewDetails scan={scan} />

      <div className="ScanOverview__charts grid xl:grid-cols-2 gap-4">
        <ScanOSPieChart
          className="bg-od-black-300 rounded-od-20 col-span-2 xl:col-span-1"
          scan={scan}
        />

        <ScanPieChart
          className="bg-od-black-300 rounded-od-20 col-span-2 xl:col-span-1"
          scan={scan}
          showInfo={showInfo}
        />

        {hasMultipleScans && !hasLoadedScans && <Loading />}

        {hasMultipleScans && hasLoadedScans && (
          <>
            <ScanPolarChart
              className="h-[500px] bg-od-black-300 rounded-od-20 col-span-2 xl:col-span-1"
              scans={allScans}
              showInfo={showInfo}
              title={t('Comparison to last scan')}
            />
            <ScanStackedChart
              className={
                classNames(
                  'h-[500px] bg-od-black-300 rounded-od-20 col-span-2',
                  { 'xl:col-span-1': hasMultipleScans },
                )
              }
              scans={allScans}
              showInfo={showInfo}
              title={t('Scan results over time')}
            />
          </>
        )}
      </div>
    </section>
  );
}

EvsScanOverviewContent.propTypes = {
  scan: PropTypes.object.isRequired,
  showInfo: PropTypes.bool.isRequired,
};

export default EvsScanOverviewContent;
