import { useCallback } from 'react';

import { useWindowSize } from 'react-use';

import { screens, screensMap } from 'src/design/theme/screens';

const breakpoints = Object.keys(screens);

export default function useBreakpoint() {
  const size = useWindowSize();
  const isGreaterThan = useCallback((breakpoint) => {
    if (!breakpoints.includes(breakpoint)) {
      throw new Error(`Breakpoint ${breakpoint} is not valid.`);
    }
    return size.width >= screensMap[breakpoint];
  }, [size]);

  return {
    isGreaterThan,
    size,
  };
}
