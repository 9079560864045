import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as IconDropdownArrow } from 'src/design/custom-icons/dropdown-arrow.svg';

function DropdownArrow(props) {
  const {
    Icon = IconDropdownArrow,
    open = false,
  } = props;
  return (
    <Icon
      className={
        classNames({
          'transform rotate-180': open,
        })
      }
    />
  );
}

DropdownArrow.propTypes = {
  Icon: PropTypes.object,
  open: PropTypes.bool,
};

export default DropdownArrow;
