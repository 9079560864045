import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

function Card(props) {
  const { children, className } = props;
  return (
    <div className={classNames('Card bg-od-black-500 rounded-od-20 px-5 py-5', className)}>
      {children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Card;
