import React from 'react';

import { useMutation } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BCI_RESUME_SUBSCRIPTION } from 'src/api/bciSubscription';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { useCurrentCompanyBciSubscription } from 'src/contexts/useCurrentCompanyBciSubscription';
import Button from 'src/design/ui-kit/Button/Button';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function BciResumeSubscriptionModal(props) {
  const {
    children = undefined,
    isOpen,
    onClose,
  } = props;
  const { companyId } = useUserParams();
  const { showSuccessMessage } = useToast();
  const { refetchQueries } = useCurrentCompanyBciSubscription();

  const { t } = useTranslation();

  const [subscribe, { loading: saving }] = useMutation(BCI_RESUME_SUBSCRIPTION, {
    onCompleted({ bciResumeSubscription }) {
      if (bciResumeSubscription) {
        showSuccessMessage(t('Subscription successfully resumed'));
        onClose();
      }
    },
    refetchQueries,
    variables: {
      company: companyId,
    },
  });

  const onConfirm = async () => {
    await subscribe({
      variables: {
        company: companyId,
      },
    });
  };

  return (
    <ConfirmationModal
      Cancel={(
        <Button
          disabled={saving}
          Icon={<FontAwesomeIcon icon={light('ban')} />}
          onClick={onClose}
          variant="secondary"
        >
          {t('No')}
        </Button>
      )}
      Confirm={(
        <Button
          Icon={<FontAwesomeIcon icon={light('thumbs-up')} />}
          loading={saving}
          onClick={onConfirm}
        >
          {t('Yes')}
        </Button>
      )}
      isOpen={isOpen}
      onClose={onClose}
      Subtitle={(
        <span className="text-sm od-font-secondary-regular text-od-white-500 mt-3">
          {/* eslint-disable-next-line max-len */}
          {children || t('This action will resume your previous subscription and keep all of your domains, schedules, and costs.')}
        </span>
      )}
      title={t('Resume subscription?')}
    />
  );
}

BciResumeSubscriptionModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BciResumeSubscriptionModal;
