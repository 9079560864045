import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

import { DATETIME } from 'src/constants/date';
import Select from 'src/design/ui-kit/Select/Select';
import { formatDate } from 'src/helpers/date';

// eslint-disable-next-line react/prop-types
function ScanOption({ data: option, innerProps, isSelected }) {
  return (
    <div
      {...innerProps}
      className="py-2 hover:shadow-od-select-option
       rounded-od-8 text-od-white-900 cursor-pointer mb-2 flex justify-between items-center"
    >
      <span className="pl-2 od-font-secondary-regular">
        {/* eslint-disable-next-line react/prop-types */}
        { formatDate(option.ranAt, DATETIME) }
      </span>
      {
        isSelected && (
          <FontAwesomeIcon icon={light('check')} />
        )
      }
    </div>
  );
}

function ScanDropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={light('chevrons-down')} />
    </components.DropdownIndicator>
  );
}

function ScanSelect(props) {
  const { onChange, scans, selectedScan } = props;

  const { t } = useTranslation();

  return (
    <Select
      className="flex-1 mr-2 max-w-xs"
      components={{
        DropdownIndicator: ScanDropdownIndicator,
        Option: ScanOption,
      }}
      getOptionLabel={(scan) => formatDate(scan.ranAt, DATETIME)}
      getOptionValue={(scan) => formatDate(scan.ranAt, DATETIME)}
      isClearable={false}
      isLoading={false}
      isSearchable={false}
      loadingMessage={() => t('Loading...')}
      onChange={onChange}
      options={scans}
      placeholder={t('Loading...')}
      value={selectedScan}
    />
  );
}

ScanSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  scans: PropTypes.array.isRequired,
  selectedScan: PropTypes.object.isRequired,
};

export default ScanSelect;
