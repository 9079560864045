import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import SubscriptionIndicator from 'src/components/common/SubscriptionIndicator';
import { isSubscriptionActive, isSubscriptionExpiring, SubscriptionStatus } from 'src/constants/subscription';
import { useCurrentCompanyBciSubscription } from 'src/contexts/useCurrentCompanyBciSubscription';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import IconBreachCredentials from 'src/design/custom-icons/IconBreachCredentials/IconBreachCredentials';
import Button from 'src/design/ui-kit/Button/Button';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
import BciTacticActionButton from 'src/views/TacticsSettings/BciTactic/BciTacticActionButton';

function BCITactic() {
  const { t } = useTranslation();
  const { bciSubscriptionStatus, companyBciSubscription, loading } = useCurrentCompanyBciSubscription();

  const { permissions, userConfig } = useCurrentUser();
  const { companyId } = useUserParams();
  const navigate = useNavigate();

  if (loading) {
    return (
      <Loading />
    );
  }
  const getSubscribeButton = () => {
    if (!permissions.canManageBciSubscription()) {
      return null;
    }
    return <BciTacticActionButton />;
  };

  const editUrl = userConfig.buildUrl('/tactics-settings/bci', `/${companyId}`);
  const manageButtonVariant = bciSubscriptionStatus === SubscriptionStatus.ACTIVE ? 'primary' : 'secondary';
  const canManage = isSubscriptionActive(bciSubscriptionStatus) || isSubscriptionExpiring(bciSubscriptionStatus);

  return (
    <div
      className="
        BciTactic p-5 rounded-lg
        grid gap-3 grid-cols-[40px_max-content_1fr] grid-rows-[max-content_max-content]
        bg-gradient-to-r from-od-cyan/[0.08] to-od-black-500
      "
    >
      <div className="row-span-2">
        <IconBreachCredentials />
      </div>

      {canManage ? (
        <NavLink
          className="od-font-main-medium"
          disabled={!canManage}
          to={editUrl}
        >
          {t('Breached Credential Detection')}
        </NavLink>
      ) : (
        <span className="od-font-main-medium">{t('Breached Credential Detection')}</span>
      )}

      <div className="place-self-end">
        <SubscriptionIndicator
          expiresAt={companyBciSubscription?.expiresAt}
          status={bciSubscriptionStatus}
        />
      </div>

      <div className="od-font-secondary-regular text-od-white-500 max-w-lg">
        {t('It is a tactic which searches through different companies data breaches (in open sources & on dark web)'
          + ' for your employees information, using your domain names.')}
      </div>

      <div className="place-self-end flex items-center gap-2">
        {canManage && (
          <Button
            className="place-self-end flex gap-2 items-center h-9"
            Icon={<FontAwesomeIcon icon={light('wrench')} />}
            onClick={() => navigate(editUrl)}
            size="sm"
            variant={manageButtonVariant}
          >
            {t('Manage')}
          </Button>
        )}
        {getSubscribeButton()}
      </div>
    </div>
  );
}

export default BCITactic;
