import React from 'react';

import { useTranslation } from 'react-i18next';

import useDomainsLoader from 'src/api/loaders/useDomainsLoader';
import GoBackLink from 'src/components/common/GoBackLink';
import Page from 'src/components/common/Page';
import TableView from 'src/components/common/Table/TableView';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import IconBreachCredentials from 'src/design/custom-icons/IconBreachCredentials/IconBreachCredentials';
import PirateGif from 'src/design/images/pirate.gif';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
import AddDomainsButton from 'src/views/TacticBci/AddDomains/AddDomainsButton';
import DomainGridRow from 'src/views/TacticBci/DomainGridRow';
import TacticBciDetails from 'src/views/TacticBci/TacticBciDetails';

function TacticBci() {
  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const { userConfig } = useCurrentUser();

  const variables = {
    companyId,
  };

  // TODO: Consider adding pagination
  const { data: { items: bciDomains, pageInfo }, fetchMore, isLoadingMore, loading } = useDomainsLoader({
    variables,
  });

  const onLoadMore = async () => {
    await fetchMore({
      variables: {
        ...variables,
        after: pageInfo.endCursor,
      },
    });
  };

  if (loading) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page
      className="Page TacticsBci"
      title={t('Breach Credential')}
    >
      <header>
        <h1 className="flex gap-2 items-center mb-4">
          <IconBreachCredentials />
          {t('Breached Credential Detection')}
        </h1>

        <GoBackLink to={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}>
          {t('All tactics')}
        </GoBackLink>
      </header>

      <TacticBciDetails bciDomains={bciDomains} />

      <section className="flex items-center justify-between mt-10">
        <h5 className="od-font-title-semi-bold">
          {t('Domains')}
        </h5>
        <AddDomainsButton />
      </section>

      <section className="mt-8">

        {bciDomains.length > 0 ? (
          <TableView
            columns={[]}
            data={bciDomains}
            isLoadingMore={isLoadingMore}
            onLoadMore={onLoadMore}
            pageInfo={pageInfo}
            showHead={bciDomains.length > 0}
            TableRow={DomainGridRow}
          />
        ) : (
          <div className="text-sm text-center">
            <img
              alt={t('Loading')}
              className="block mx-auto my-4 w-[180px]"
              src={PirateGif}
            />
            {t('There are no domains. Click "Add domain" button to create a first domain')}
          </div>
        )}
      </section>
    </Page>
  );
}

export default TacticBci;
