import usePaginatedLoader from 'src/api/loaders/usePaginatedLoader';
import { ALL_NOTIFICATIONS_QUERY } from 'src/api/notifications';

export function transformResponse(data) {
  if (!data) {
    return {
      items: [],
      pageInfo: {
        endCursor: null,
        hasNextPage: false,
      },
    };
  }
  return {
    items: data.allNotifications.edges.map((edge) => edge.node),
    pageInfo: data.allNotifications.pageInfo,
  };
}

export default function useNotificationsLoader({ variables }) {
  return usePaginatedLoader(
    ALL_NOTIFICATIONS_QUERY,
    { variables },
    transformResponse,
  );
}
