import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';
import PaymentMethodModal from 'src/views/Billing/ChangePaymentMethod/PaymentMethodModal';

function ChangePaymentMethodButton(props) {
  const { isCardWorking = false } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Button
        Icon={<FontAwesomeIcon icon={light('pen-to-square')} />}
        onClick={() => setIsModalOpen(true)}
        size={isCardWorking ? 'sm' : 'md'}
        variant={isCardWorking ? 'secondary' : 'primary'}
      >
        {isCardWorking ? t('Change') : t('Set payment method')}
      </Button>
      <PaymentMethodModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

ChangePaymentMethodButton.propTypes = {
  isCardWorking: PropTypes.bool,
};

export default ChangePaymentMethodButton;
