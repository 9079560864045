import React from 'react';

import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SET_PERIOD_SCAN_SCHEDULE_MUTATION } from 'src/api/evsScans/evsScans';
import FormControl from 'src/components/common/FormControl';
import Modal from 'src/components/modals/Modal';
import useEvsScheduleScanModalForm from
  'src/components/tactics.evs/scans/EvsScheduleScanModal/useEvsScheduleScanModalForm';
import EvsTargetGroupSelect from 'src/components/tactics.evs/targetGroups/EvsTargetGroupSelect';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import Button from 'src/design/ui-kit/Button/Button';
import DaysSelect from 'src/design/ui-kit/DaysSelect/DaysSelect';
import TimePicker from 'src/design/ui-kit/TimePicker/TimePicker';
import { getUserTimezone } from 'src/helpers/date';
import useToast from 'src/hooks/useToast';

function EvsScheduleScanModal(props) {
  const {
    initialTargetGroup = null,
    isOpen,
    onClose,
  } = props;
  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();
  const { tactics: { evs: { refetchQueries } } } = useCurrentCompany();

  const {
    form: { control, formState, handleSubmit, reset },
    helpers: { getFieldError },
  } = useEvsScheduleScanModalForm({ initialTargetGroup });

  const handleClose = () => {
    reset();
    onClose();
  };

  const [setPeriodScanSchedule, { loading: saving }] = useMutation(SET_PERIOD_SCAN_SCHEDULE_MUTATION, {
    onCompleted(response) {
      if (response.setPeriodScanSchedule) {
        showSuccessMessage('Scan successfully scheduled');
        handleClose();
      }
    },
    refetchQueries,
  });

  const onSubmit = async (submitData, event) => {
    event.preventDefault();
    await setPeriodScanSchedule({
      variables: {
        days: submitData.daysValue.days,
        period: submitData.daysValue.period,
        targetGroup: submitData.targetGroup.id,
        timeOfDay: submitData.timeOfDay,
        timezone: getUserTimezone(),
      },
    });
  };

  return (
    <Modal
      className="EvsScheduleScanModal"
      isOpen={isOpen}
      onClose={handleClose}
      shouldFocusFirstFocusable
      subtitle={t('Please select at least one target group and a schedule date below')}
      title={t('Schedule Scan')}
    >
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid gap-4">
          <FormControl
            error={getFieldError('targetGroup')}
            htmlFor="targetGroup"
            label={t('Target group')}
          >
            <Controller
              control={control}
              id="targetGroup"
              name="targetGroup"
              render={({ field: { onChange, ref, value } }) => (
                <EvsTargetGroupSelect
                  inputRef={ref}
                  isSearchable={false}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </FormControl>

          <FormControl
            htmlFor="daysValue"
            label={t('Schedule date')}
          >
            <Controller
              control={control}
              id="daysValue"
              name="daysValue"
              render={({ field: { onChange, value } }) => (
                <DaysSelect
                  key={`${value.period}-${value.days.join('')}`}
                  onChange={onChange}
                  placeholder={t('Schedule')}
                  value={value}
                />
              )}
            />
          </FormControl>

          <FormControl htmlFor="timeOfDay" label={t('Time')}>
            <Controller
              control={control}
              id="timeOfDay"
              name="timeOfDay"
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  key={value}
                  onChange={onChange}
                  placeholder={t('Select time')}
                  value={value}
                />
              )}
            />
          </FormControl>
        </div>

        <div className="mt-10 flex justify-end">
          <Button
            disabled={!formState.isValid || saving}
            type="submit"
          >
            {t('Schedule')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

EvsScheduleScanModal.propTypes = {
  initialTargetGroup: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EvsScheduleScanModal;
