import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PieChart from 'src/components/charts/ScanPieChart/PieChart';
import ProgressBar from 'src/components/charts/ScanPieChart/ProgressBar';
import { useScanVulnerabilitySeverityDefinitions } from 'src/helpers/scan';

const buildData = (scan, showInfo) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const vulnerabilitySeverityDefinitions = useScanVulnerabilitySeverityDefinitions({ showInfo });

  const data = vulnerabilitySeverityDefinitions.map(({ color, key, name }) => ({
    color,
    key,
    name,
    value: scan.statistics.vulnCountPerLevel[key],
  }));
  const total = data.reduce((result, current) => result + current.value, 0);
  return data.map((item) => ({
    ...item,
    percent: (item.value / total) * 100,
  }));
};

const mapToChartData = (data) => data.map((item) => ({
  color: item.color,
  name: item.name,
  sliced: false,
  y: item.value,
}));

function ScanPieChart(props) {
  const { className = '', scan, showInfo, style = {} } = props;
  const { t } = useTranslation();
  const data = buildData(scan, showInfo);

  return (
    <section
      className={classNames('ScanPieChart p-6 flex flex-col', className)}
      style={style}
    >
      <div className="od-font-title-semi-bold">
        {t('Vulnerabilities')}
      </div>
      <div className="mt-8 flex items-center gap-10 justify-center flex-1">
        <PieChart
          chartData={mapToChartData(data)}
          title={t('Vulnerabilities')}
        />
        <div className="flex flex-1 flex-col gap-3">
          {data.map((item) => (
            <ProgressBar
              key={item.key}
              data={item}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

ScanPieChart.propTypes = {
  className: PropTypes.string,
  scan: PropTypes.object.isRequired,
  showInfo: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

export default ScanPieChart;
