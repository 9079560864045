import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactCrop from 'react-image-crop';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-image-crop/dist/ReactCrop.css';
import Modal
  from 'src/components/modals/Modal';
import Button from 'src/design/ui-kit/Button/Button';
import { getCroppedImg, blobToBase64 } from 'src/helpers/image';

function ImageUploaderModal(props) {
  const { imageUrl, isOpen, onClose, onComplete } = props;
  const imageRef = useRef();
  const { t } = useTranslation();
  const [crop, setCrop] = useState();

  const onSubmit = async () => {
    const blob = await getCroppedImg(imageRef.current, crop);
    const generatedImageUrl = await blobToBase64(blob);
    onComplete(generatedImageUrl);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('Crop image')}
    >
      <div className="flex justify-center">
        <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
          <img
            ref={imageRef}
            alt={t('Preview')}
            src={imageUrl}
          />
        </ReactCrop>
      </div>
      <section className="mt-8">
        <Button className="w-full" onClick={onSubmit}>
          {t('Submit')}
        </Button>
      </section>
    </Modal>
  );
}

ImageUploaderModal.propTypes = {
  imageUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
};

export default ImageUploaderModal;
