import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

function IconExpanded(props) {
  const { expanded } = props;

  return (
    <FontAwesomeIcon
      icon={expanded ? light('chevrons-up') : light('chevrons-down')}
    />
  );
}

IconExpanded.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

export default IconExpanded;
