import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { EVS_UNSUBSCRIBE } from 'src/api/evsSubscription';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import Button from 'src/design/ui-kit/Button/Button';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function EvsTacticUnsubscribeButton() {
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const { showSuccessMessage } = useToast();
  const { tactics: { evs: { refetchQueries } } } = useCurrentCompany();

  const [unsubscribe, { loading: saving }] = useMutation(EVS_UNSUBSCRIBE, {
    onCompleted({ evsUnsubscribe }) {
      if (evsUnsubscribe) {
        showSuccessMessage(t('Successfully unsubscribed'));
        setIsOpenConfirmationModal(false);
      }
    },
    refetchQueries,
    variables: {
      company: companyId,
    },
  });

  const subtitle = t(
    // eslint-disable-next-line max-len
    'Are you sure you want to unsubscribe from this tactic? Doing so will result in deletion of all target groups and results after your billing period has ended.',
  );

  return (
    <>
      <Button
        Icon={<FontAwesomeIcon icon={light('ban')} />}
        onClick={() => setIsOpenConfirmationModal(true)}
        size="sm"
        variant="dangerous"
      >
        {t('Unsubscribe')}
      </Button>
      <ConfirmationModal
        Cancel={(
          <Button
            disabled={saving}
            Icon={<FontAwesomeIcon icon={light('ban')} />}
            onClick={() => setIsOpenConfirmationModal(false)}
          >
            {t('No')}
          </Button>
        )}
        Confirm={(
          <Button
            Icon={<FontAwesomeIcon icon={light('thumbs-up')} />}
            loading={saving}
            onClick={unsubscribe}
            variant="secondary"
          >
            {t('Yes')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        subtitle={subtitle}
        title={t('Unsubscribe')}
      />
    </>
  );
}

export default EvsTacticUnsubscribeButton;
