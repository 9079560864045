const defaultSelectors = [
  'a[href]',
  '[contenteditable]',
  'input:not([disabled]):not([type=file])',
  'select:not([disabled])',
  'textarea:not([disabled])',
];

// eslint-disable-next-line
const getAllFocusable = (element, selectors = defaultSelectors) =>
  // eslint-disable-next-line
  Array.from(element.querySelectorAll(selectors.join(',')));

const getFirstFocusable = (element) => {
  const focusableElements = getAllFocusable(element);

  if (!focusableElements.length) {
    return false;
  }

  return focusableElements[0];
};

export const focusFirstFocusable = (element, selectors) => {
  const firstFocusable = getFirstFocusable(element, selectors);

  if (firstFocusable) {
    // Needed for FF to show the cursor
    // if there's an overflow to the right
    firstFocusable.scrollLeft = 99999;
    firstFocusable.focus();
  }
};

export const blurFirstFocusable = (element, selectors) => {
  const firstFocusable = getFirstFocusable(element, selectors);

  if (firstFocusable) {
    // Needed for FF to show the cursor
    // if there's an overflow to the right
    firstFocusable.scrollLeft = 99999;
    firstFocusable.blur();
  }
};

export const focusNextFocusable = ({ element, form }) => {
  const focusableElements = getAllFocusable(form);
  const index = focusableElements.indexOf(element);
  const nextFocusable = focusableElements[index + 1];
  if (nextFocusable) {
    nextFocusable.focus();
  }
};
