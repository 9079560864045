// TODO: Enable this rule in eslint config
// TODO: Move ips helpers here and add unit tests
// eslint-disable-next-line import/prefer-default-export
export function compareTargets(targets1, targets2) {
  const newTargets = [];
  const deletedTargets = [];

  // Check what targets were deleted
  targets1.forEach((target) => {
    if (!targets2.includes(target)) {
      deletedTargets.push(target);
    }
  });

  // Check what targets were added in targets2
  targets2.forEach((target) => {
    if (!targets1.includes(target)) {
      newTargets.push(target);
    }
  });

  return {
    deletedTargets,
    newTargets,
  };
}
