import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { REQUEST_RESET_PASSWORD_MUTATION } from 'src/api/auth';
import Page from 'src/components/common/Page';
import useToast from 'src/hooks/useToast';

import RequestResetPasswordForm from './RequestResetPasswordForm';

function RequestResetPassword() {
  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const [requestResetPassword, { loading: saving }] = useMutation(REQUEST_RESET_PASSWORD_MUTATION, {
    onCompleted: async (response) => {
      if (response.requestResetPassword.ok) {
        showSuccessMessage(t('Password resent email successfully sent'));
        setIsEmailSent(true);
      }
    },
  });

  const onSubmit = async (formData) => {
    try {
      await requestResetPassword({ variables: {
        email: formData.email,
      } });
    } catch (e) {
      // Error is handled globally
    }
  };

  return (
    <Page
      className="RequestResetPassword h-full flex justify-center items-center"
      title={isEmailSent ? t('Reset Email Sent!') : t('Request reset password')}
    >
      <RequestResetPasswordForm
        isEmailSent={isEmailSent}
        loading={saving}
        onSubmit={onSubmit}
      />
    </Page>
  );
}

export default RequestResetPassword;
