import React from 'react';

import { useTranslation } from 'react-i18next';

import useBillingInvoicesLoader from 'src/api/loaders/useBillingInvoicesLoader';
import TableView from 'src/components/common/Table/TableView';
import Loading from 'src/design/ui-kit/Loading/Loading';
import { TableColumnDefinition } from 'src/hooks/useTable';
import useUserParams from 'src/hooks/useUserParams';
import BillingInvoicesTableViewRow from 'src/views/BillingInvoices/BillingInvoicesTableViewRow';

const first = 50;

function BillingInvoicesTableView() {
  const { t } = useTranslation();
  const { mspId } = useUserParams();

  const columns = [
    new TableColumnDefinition({
      name: 'invoice',
      title: t('Invoice'),
    }),
    new TableColumnDefinition({
      name: 'total',
      title: t('Total'),
    }),
    new TableColumnDefinition({
      name: 'status',
      title: t('Status'),
    }),
    new TableColumnDefinition({
      name: 'issuedOn',
      title: t('Issued on'),
    }),
    new TableColumnDefinition({
      name: 'paidOn',
      title: t('Paid on'),
    }),
    new TableColumnDefinition({
      gridTemplate: '60px',
      name: 'downloadButton',
    }),
  ];

  const variables = {
    first,
    id: mspId,
  };

  const {
    data: { items: invoices, pageInfo },
    fetchMore,
    isLoadingMore,
    loading,
  } = useBillingInvoicesLoader({ variables });

  const onLoadMore = async () => {
    await fetchMore({
      variables: {
        ...variables,
        after: pageInfo.endCursor,
      },
    });
  };

  if (loading) {
    return (
      <Loading />
    );
  }

  if (!loading && invoices.length === 0) {
    return (
      <div className="text-center">
        {t('No invoices found')}
      </div>
    );
  }

  return (
    <TableView
      columns={columns}
      data={invoices}
      isLoadingMore={isLoadingMore}
      onLoadMore={onLoadMore}
      pageInfo={pageInfo}
      TableRow={BillingInvoicesTableViewRow}
    />
  );
}

export default BillingInvoicesTableView;
