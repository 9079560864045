import orderBy from 'lodash-es/orderBy';

// Sort functions
export const sortByNumber = (extractValueFn) => ({ sortDirection }) => (data) => [...data]
  .sort((a, b) => {
    const valueA = extractValueFn(a);
    const valueB = extractValueFn(b);
    if (sortDirection === 'asc') {
      return valueA - valueB;
    }
    return valueB - valueA;
  });

export const sortAlphabetically = (extractValueFn) => ({
  sortDirection,
  sortKey,
}) => (data) => orderBy(
  data,
  [(item) => extractValueFn(item, sortKey).toLowerCase()],
  sortDirection,
);

export const sortByDate = (extractValueFn) => ({ sortDirection }) => (data) => [...data]
  .sort((a, b) => {
    const dateA = new Date(extractValueFn(a));
    const dateB = new Date(extractValueFn(b));
    if (sortDirection === 'asc') {
      return dateA - dateB;
    }
    return dateB - dateA;
  });
