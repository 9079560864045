import { format, formatDistanceToNow } from 'date-fns';
import i18n from 'i18next';

import { DEFAULT_DATE_FORMAT } from 'src/constants/date';

export function formatDate(dateISO, dateFormat = DEFAULT_DATE_FORMAT) {
  return format(new Date(dateISO), dateFormat);
}

export function formatDateDistanceToNow(dateISO) {
  return formatDistanceToNow(new Date(dateISO), { addSuffix: true });
}

export function getHoursList() {
  const hours = [];
  for (let i = 0; i < 24; i += 1) {
    hours.push(i < 10 ? `0${i}` : String(i));
  }
  return hours;
}

export function getMinutesList() {
  const hours = [];
  for (let i = 0; i < 60; i += 1) {
    hours.push(i < 10 ? `0${i}` : String(i));
  }
  return hours;
}

// For now only 'HH:mm' is supported
export function parseHourAndMinute(value = '') {
  if (!value) {
    return { hour: '', minute: '' };
  }
  const [hour, minute] = value.split(':');

  return {
    hour,
    minute,
  };
}

export function buildTime({ hour, minute }) {
  return `${hour}:${minute}`;
}

export function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const weekdays = () => [
  {
    id: 1,
    text: i18n.t('Mon'),
  },
  {
    id: 2,
    text: i18n.t('Tue'),
  },
  {
    id: 3,
    text: i18n.t('Wed'),
  },
  {
    id: 4,
    text: i18n.t('Thu'),
  },
  {
    id: 5,
    text: i18n.t('Fri'),
  },
  {
    id: 6,
    text: i18n.t('Sat'),
  },
  {
    id: 7,
    text: i18n.t('Sun'),
  },
];

export function mapToWeekDay(id) {
  return weekdays().find((weekday) => weekday.id === id);
}

export function formatDays({ days, period }) {
  if (period === 'WEEKLY') {
    return days.map((day) => mapToWeekDay(day).text).join(', ');
  }
  return days.join(', ');
}
