import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import EvsNotEnabledBadge from 'src/components/tactics.evs/EvsNotEnabledBadge';
import EvsNoScanDataBadge from 'src/components/tactics.evs/EvsNotScanDataBadge';
import EvsSubscriptionExpiringBadge from 'src/components/tactics.evs/EvsSubscriptionExpiringBadge';
import EvsScanStatus from 'src/components/tactics.evs/scans/EvsScanStatus';
import { isSubscriptionActive, isSubscriptionExpiring } from 'src/constants/subscription';
import FieldCountStatus from 'src/design/ui-kit/Field/FieldCountStatus/FieldCountStatus';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';

function CompaniesTableViewRowVulnerabilitiesCell(props) {
  const { company } = props;
  const { evsAggregateStatistics, evsSubscription } = company;

  const { t } = useTranslation();

  const newCount = evsAggregateStatistics?.newCount.total ?? 0;
  const remediatedCount = evsAggregateStatistics?.remediatedCount.total ?? 0;

  const isScanRunning = company.evsSubscription?.isScanRunning ?? false;

  if (isSubscriptionExpiring(evsSubscription?.status)) {
    return (
      <EvsSubscriptionExpiringBadge
        companyId={company.id}
        date={evsSubscription.expiresAt}
      />
    );
  }

  if (!isSubscriptionActive(evsSubscription?.status)) {
    return (
      <EvsNotEnabledBadge companyId={company.id} />
    );
  }

  if (newCount === 0 && remediatedCount === 0) {
    return (
      <EvsNoScanDataBadge companyId={company.id} />
    );
  }

  return (
    <NavLink
      className="block"
      to={`/companies/${company.id}/dashboard`}
    >
      <div className="flex gap-3 items-center">
        {newCount > 0 && (
          <Tooltip text={t('New vulnerabilities since the last scan')}>
            <FieldCountStatus
              negative
              value={newCount}
            />
          </Tooltip>
        )}
        {remediatedCount > 0 && (
          <Tooltip text={t('Vulnerabilities no longer seen since the last scan')}>
            <FieldCountStatus value={remediatedCount} />
          </Tooltip>
        )}
        {isScanRunning && <EvsScanStatus />}
      </div>
    </NavLink>
  );
}

CompaniesTableViewRowVulnerabilitiesCell.propTypes = {
  company: PropTypes.object.isRequired,
};

export default CompaniesTableViewRowVulnerabilitiesCell;
