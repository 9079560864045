import React, { createContext, useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { ME_QUERY } from 'src/api/users';
import { buildPermissions } from 'src/helpers/permissions';
import useFeatures from 'src/hooks/useFeatures';
import useToast from 'src/hooks/useToast';
import { userTypeStore, getUserConfig, USER_TYPES } from 'src/users';

export const CurrentUserContext = createContext({
  error: null,
  fetchMe: () => new Promise(),
  hasUnseenNotifications: false,
  isCompanyUser: false,
  isGlobalAdminUser: false,
  isMSPUser: false,
  loading: false,
  me: null,
  navigateToDefaultPage: () => {},
  navigateToSignIn: () => {},
  permissions: {},
  unseenNotifications: 0,
  userConfig: getUserConfig(null),
});

export function CurrentUserProvider(props) {
  const { children } = props;
  const { showErrorMessage } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { isMSPSettingsEnabled } = useFeatures();

  const isAuthPage = location.pathname.includes('/auth/');
  const isHomePage = location.pathname === '/';

  const navigateToSignIn = () => window.location.replace('/users/login/');

  const navigateToDefaultPage = useCallback(async () => {
    const _userConfig = getUserConfig(userTypeStore.get());
    navigate(_userConfig.buildUrl('/'));
  }, [navigate]);

  const handleError = () => {
    userTypeStore.clear();
    if (!isAuthPage) {
      navigateToSignIn();
      if (!isHomePage) {
        showErrorMessage(t('User is not authorized'));
      }
    }
  };

  const { data, error, loading, refetch: fetchMe } = useQuery(ME_QUERY, {
    async onCompleted(response) {
      const { me: _me } = response || { me: null };

      // We are interested only in non-auth views
      if (!_me) {
        handleError();
        return;
      }

      if (_me.userType) {
        userTypeStore.set(_me.userType);
      }
    },
    onError: handleError,
    variables: {
      ignoreErrors: true,
    },
  });

  const unseenNotifications = data?.unseenNotifications ?? 0;
  let _me = null;
  //  TODO: Remove this once the backend is updated to return the logo
  if (data?.me) {
    _me = {
      ...data.me,
    };

    if (_me?.msp) {
      if (isMSPSettingsEnabled()) {
        _me.msp = {
          ..._me.msp,
          logo: localStorage.getItem('mspLogo')
           || 'https://pbs.twimg.com/profile_images/1487142485180747782/fLs5uP_S_400x400.png',
        };
      } else {
        _me.msp = {
          ..._me.msp,
        };
      }
    }
  }

  const userConfig = getUserConfig(userTypeStore.get());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const me = { ..._me };
  const id = me?.id;

  const value = useMemo(() => ({
    error,
    fetchMe,
    hasUnseenNotifications: unseenNotifications > 0,
    isCompanyUser: [
      USER_TYPES.COMPANY_ADMIN,
      USER_TYPES.AUDITOR,
    ].includes(userConfig.userType),
    isGlobalAdminUser: [
      USER_TYPES.GLOBAL_ADMIN,
    ].includes(userConfig.userType),
    isMSPUser: [
      USER_TYPES.MSP_ADMIN,
      USER_TYPES.MSP_ANALYST,
    ].includes(userConfig.userType),
    loading,
    me,
    navigateToDefaultPage,
    navigateToSignIn,
    permissions: buildPermissions(me),
    unseenNotifications,
    userConfig,
  }), [error, fetchMe, loading, me, navigateToDefaultPage, unseenNotifications, userConfig]);

  return (
    <CurrentUserContext.Provider
      key={id}
      value={value}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

CurrentUserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);
