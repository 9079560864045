/* eslint-disable react/no-danger */
import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as IconAvatarLg } from 'src/design/custom-icons/avatar-lg.svg';
import { ReactComponent as IconAvatarMd } from 'src/design/custom-icons/avatar-md.svg';
import { ReactComponent as IconAvatarSm } from 'src/design/custom-icons/avatar-sm.svg';

const DEFAULT_AVATAR = {
  fill: {
    path: '#989898',
    rect: '#3F4044',
  },
};

const AVATAR_MAP = {
  // Row 1
  'avatar-0': {
    fill: {
      path: '#DB3000',
      rect: '#FF7852',
    },
  },
  'avatar-1': {
    fill: {
      path: '#FE8100',
      rect: '#FFB971',
    },
  },
  'avatar-2': {
    fill: {
      path: '#55E26C',
      rect: '#92B798',
    },
  },
  'avatar-3': {
    fill: {
      path: '#FF4C6B',
      rect: '#F88B9E',
    },
  },
  'avatar-4': {
    fill: {
      path: '#FFD100',
      rect: '#C7BE95',
    },
  },

  // Row 1
  'avatar-5': {
    fill: {
      path: '#C1CD35',
      rect: '#F4FF71',
    },
  },
  'avatar-6': {
    fill: {
      path: '#55CB6F',
      rect: '#71FF90',
    },
  },
  'avatar-7': {
    fill: {
      path: '#1EC4C4',
      rect: '#71FFFF',
    },
  },
  'avatar-8': {
    fill: {
      path: '#9846EB',
      rect: '#B871FF',
    },
  },
  'avatar-9': {
    fill: {
      path: '#F33636',
      rect: '#FF7171',
    },
  },
};

export const ALL_AVATAR_NAMES = Object.keys(AVATAR_MAP);

const sizes = ['sm', 'md', 'lg'];

function Avatar(props) {
  const { preset = 'default', size = 'md' } = props;
  const variantDefinition = AVATAR_MAP[preset] || DEFAULT_AVATAR;

  const classes = classNames(
    'Avatar flex items-center justify-center rounded-od-10 flex-shrink-0',
    {
      'w-10 h-10': size === 'sm',
      'w-12 h-12': size === 'md',
      'w-18 h-18': size === 'lg',
    },
  );

  return (
    <div
      className={classes}
      style={{
        backgroundColor: variantDefinition.fill.rect,
      }}
    >
      {size === 'sm' && (
        <IconAvatarSm fill={variantDefinition.fill.path} />
      )}
      {size === 'md' && (
        <IconAvatarMd fill={variantDefinition.fill.path} />
      )}
      {size === 'lg' && (
        <IconAvatarLg fill={variantDefinition.fill.path} />
      )}
    </div>
  );
}

Avatar.propTypes = {
  preset: PropTypes.oneOf([
    'default',
    'avatar-0',
    'avatar-1',
    'avatar-2',
    'avatar-3',
    'avatar-4',
    'avatar-5',
    'avatar-6',
    'avatar-7',
    'avatar-8',
    'avatar-9',
  ]),
  size: PropTypes.oneOf(sizes),
};

export default Avatar;
