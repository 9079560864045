import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import Button from 'src/design/ui-kit/Button/Button';
import { formatPrice, useConvertCentValue } from 'src/helpers/price';

function AddDomainsContinueButton(props) {
  const { disabled, loading, onConfirm, totalPrice } = props;
  const { convertFromCentValue } = useConvertCentValue(2);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        className="w-full flex justify-center items-center gap-2"
        disabled={disabled}
        loading={loading}
        onClick={() => setIsOpenConfirmationModal(true)}
      >
        {t('Continue')}
      </Button>

      <ConfirmationModal
        Cancel={(
          <Button
            disabled={loading}
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('Cancel')}
          </Button>
        )}
        Confirm={(
          <Button
            Icon={<FontAwesomeIcon icon={light('thumbs-up')} />}
            loading={loading}
            onClick={onConfirm}
          >
            {t('Confirm')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        Subtitle={(
          <span className="od-font-secondary-medium text-od-white-500 mt-3">
            <Trans
              components={[
                (
                  <span className="text-od-white-900 od-font-secondary-medium">
                    {`${totalPrice}$`}
                  </span>
                ),
              ]}
              i18nKey="For adding domains you will be charged <0>{{totalPrice}}</0>"
              t={t}
              values={{
                totalPrice: `${formatPrice(convertFromCentValue(totalPrice))}`,
              }}
            />
          </span>
        )}
        title={t('Add domains')}
      />
    </>
  );
}

AddDomainsContinueButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
};

export default AddDomainsContinueButton;
