import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormControl from 'src/components/common/FormControl';
import PasswordValidation from 'src/components/common/PasswordValidation/PasswordValidation';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import InputPassword from 'src/design/ui-kit/InputPassword/InputPassword';

import useSignUpForm from './useSignUpForm';

function SignUpForm(props) {
  const { loading, onSubmit, showCompanyField } = props;
  const ref = useRef();
  const { t } = useTranslation();

  const {
    formBuilder: {
      form: { formState, handleSubmit, register, watch },
      helpers: { getFieldError, isFieldTouched, isFieldValidByRule },
    },
    validationsMap,
  } = useSignUpForm(showCompanyField);

  const { password } = watch();

  const onSubmitWrapper = (formData, event) => {
    event.preventDefault();

    if (!formState.isValid) {
      return;
    }

    onSubmit(formData);
  };

  const showPasswordValidation = isFieldTouched('password')
      || getFieldError('password')
      || !!password;

  return (
    <form
      ref={ref}
      className="SignUpForm grid gap-8"
      noValidate
      onSubmit={handleSubmit(onSubmitWrapper)}
      style={{ width: '420px' }}
    >
      <section className="grid gap-4">
        {showCompanyField && (
        <FormControl
          error={getFieldError('company')}
          htmlFor="company"
          label={t('Company')}
        >
          <Input
            error={getFieldError('company')}
            name="company"
            placeholder={t('Enter company name')}
            register={register}
          />
        </FormControl>
        )}

        <FormControl
          error={getFieldError('firstName')}
          htmlFor="firstName"
          label={t('First name')}
        >
          <Input
            error={getFieldError('firstName')}
            name="firstName"
            placeholder={t('Enter your first name')}
            register={register}
          />
        </FormControl>

        <FormControl
          error={getFieldError('lastName')}
          htmlFor="lastName"
          label={t('Last name')}
        >
          <Input
            error={getFieldError('lastName')}
            name="lastName"
            placeholder={t('Enter your last name')}
            register={register}
          />
        </FormControl>

        <FormControl
          error={getFieldError('email')}
          htmlFor="email"
          label={t('Email')}
        >
          <Input
            error={getFieldError('email')}
            name="email"
            placeholder={t('Enter your email')}
            register={register}
          />
        </FormControl>

        <FormControl
          htmlFor="password"
          label={t('Password')}
        >
          <InputPassword
            error={getFieldError('password')}
            name="password"
            placeholder={t('Enter password')}
            register={register}
          />

          {showPasswordValidation && (
          <PasswordValidation
            isFieldValidByRule={isFieldValidByRule}
            validationsMap={validationsMap}
          />
          )}
        </FormControl>
      </section>

      <section>
        <Button
          className="w-full"
          disabled={loading}
          iconPosition="after"
          type="submit"
          visuallyDisabled={!formState.isValid}
        >
          {t('Sign up')}
        </Button>
      </section>
    </form>
  );
}

SignUpForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showCompanyField: PropTypes.bool.isRequired,
};

export default SignUpForm;
