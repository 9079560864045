import i18n from 'i18next';
import uniq from 'lodash-es/uniq';
import * as yup from 'yup';

import { hasDuplicates } from 'src/helpers/array';

export const schema = (validator) => yup.object({
  inputValue: yup
    .string()
    .test(
      'validSingleValue',
      i18n.t('Must be a valid value'),
      validator,
    )
    .required(i18n.t('Field is required')),

  valuesArray: yup
    .array()
    .test(
      'notEmpty',
      i18n.t('Cannot contain empty values'),
      (arr) => arr.length > 0,
    )
    .test(
      'validMultipleValues',
      i18n.t('Must be valid values'),
      (arr) => arr.every(validator),
    )
    .test(
      'uniqueValues',
      i18n.t('Cannot contain duplicates'),
      (arr) => !hasDuplicates(arr),
    )
    .required(i18n.t('Field is required')),
}).required();

export function validateImportedRecords(text, validator) {
  const importedValues = uniq(text.split('\n').filter((value) => value));
  const isValid = importedValues.every(validator) && importedValues.length > 0;
  return {
    importedValues,
    isValid,
  };
}
