import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import EvsScanStatus from 'src/components/tactics.evs/scans/EvsScanStatus';
import EvsScheduleScanStatus from 'src/components/tactics.evs/scans/EvsScheduleScanStatus';
import DeleteTargetGroupButton from 'src/views/TacticEvs/TargetGroups/DeleteTargetGroupButton';
import ManageTargetGroupButton from 'src/views/TacticEvs/TargetGroups/ManageTargetGroupButton';

function GroupsGridViewRow(props) {
  const { canDelete, canManage, targetGroup } = props;

  const { t } = useTranslation();

  const isScheduled = !!targetGroup.schedule;

  return (
    <div
      className="grid od-font-main-medium items-center h-18 bg-od-black-500 px-5 rounded-lg justify-items-center"
      id={`GridRow${targetGroup.id}`}
      style={{
        gridTemplateColumns: '1fr 2fr 1fr 220px',
      }}
    >
      <span className="place-self-start self-center">{targetGroup.name}</span>

      <div className="place-self-start self-center px-6">
        {isScheduled && <EvsScheduleScanStatus schedule={targetGroup.schedule} />}
      </div>

      <span className="place-self-start text-od-white-500 self-center">
        {t('{{count}} Targets', { count: targetGroup.targetCount })}
      </span>

      {/* <span> */}
      {/*  <FieldPeriodCost timeUnit="month" value={700} /> */}
      {/* </span> */}

      <div className="place-self-end self-center flex items-center gap-2">
        {targetGroup.isScanRunning && (
          <EvsScanStatus />
        )}
        {canManage && (
          <ManageTargetGroupButton targetGroup={targetGroup} />
        )}
        {canDelete && (
          <DeleteTargetGroupButton targetGroup={targetGroup} />
        )}
      </div>
    </div>
  );
}

GroupsGridViewRow.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  canManage: PropTypes.bool.isRequired,
  targetGroup: PropTypes.object.isRequired,
};

export default GroupsGridViewRow;
