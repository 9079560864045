import React, { useEffect } from 'react';

import { Route, Routes, useNavigate, useMatch } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Company from 'src/views/Company/Company';
import PageNotFound from 'src/views/PageNotFound/PageNotFound';

function AuditorRoutes() {
  const { userConfig } = useCurrentUser();
  const navigate = useNavigate();

  const match = useMatch({
    exact: true,
    path: '/',
  });

  // By default, redirect to the default route
  useEffect(() => {
    if (match) {
      navigate(userConfig.buildUrl('/dashboard'));
    }
  }, [navigate, match, userConfig]);

  return (
    <Routes>
      <Route
        element={(
          <Company />
        )}
        path="/*"
      />
      {!match && <Route element={<PageNotFound />} path="*" />}
    </Routes>
  );
}

export default AuditorRoutes;
