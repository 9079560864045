import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { SIGN_UP_COMPANY_MUTATION } from 'src/api/auth';
import Page from 'src/components/common/Page';
import { ReactComponent as LogoSvg } from 'src/design/images/logo.svg';
import useToast from 'src/hooks/useToast';
import SignUpForm from 'src/views/Auth/SignUp/SignUpForm';

function SignUpCompany() {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();

  const [signUp, { loading }] = useMutation(
    SIGN_UP_COMPANY_MUTATION,
    {
      onCompleted: async (response) => {
        if (response.signUp?.ok) {
          setIsEmailSent(true);
          showSuccessMessage(t('We have sent you an email with a verification link'));
        }
      },
    },
  );

  const onSubmit = async (formData) => {
    try {
      await signUp({ variables: {
        companyName: formData.company,
        email: formData.email,
        firstName: formData.firstName,
        isMsp: false,
        lastName: formData.lastName,
        password: formData.password,
      } });
    } catch (e) {
      // Error is handled globally
    }
  };

  return (
    <Page
      className="h-full flex justify-center items-center"
      title={t('Company sign up')}
    >
      <div className="grid gap-4">
        <section className="grid gap-6">
          <div className="flex justify-center">
            <LogoSvg />
          </div>
          <h1 className="text-center">{t('Create company account')}</h1>
        </section>

        {isEmailSent ? (
          <p>{t('Instructions has been sent, check your email and follow the required steps to proceed')}</p>
        ) : (
          <SignUpForm
            loading={loading}
            onSubmit={onSubmit}
            showCompanyField
          />
        )}

      </div>
    </Page>
  );
}

export default SignUpCompany;
