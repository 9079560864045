import { useLayoutEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import useValidationHelpers from './useValidationHelpers';

function useFormBuilder(initialFormData, validationsSchema, options = {
  focusField: null,
  reactHookFormOptions: {},
}) {
  const { focusField, reactHookFormOptions } = options;
  const form = useForm({
    defaultValues: initialFormData,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationsSchema),
    ...reactHookFormOptions,
  });

  const { setFocus } = form;

  useLayoutEffect(() => {
    if (focusField) {
      setFocus(focusField);
    }
  }, [focusField, setFocus]);

  return {
    form,
    helpers: useValidationHelpers({ formState: form.formState }),
  };
}

export default useFormBuilder;
