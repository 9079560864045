import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BillingInvoiceStatus } from 'src/constants/billing';
import Button from 'src/design/ui-kit/Button/Button';
import FieldDatetime from 'src/design/ui-kit/Field/FieldDatetime/FieldDatetime';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import { downloadFile } from 'src/helpers/file';
import { formatPrice, useConvertCentValue } from 'src/helpers/price';

function BillingInvoicesTableViewRow(props) {
  const { data: invoice } = props;
  const { convertFromCentValue } = useConvertCentValue(2);

  const { t } = useTranslation();

  // TODO: Implement download
  return (
    <tr className="BillingInvoicesTableViewRow hover:bg-od-black-300">
      <td>{invoice.id}</td>
      <td>{formatPrice(convertFromCentValue(invoice.total))}</td>
      <td>
        <div
          className={classNames('px-4 py-1 rounded-md text-od-white-900 text-sm', {
            'bg-od-complementary-good': invoice.status === BillingInvoiceStatus.Paid,
          })}
        >
          {invoice.status}
        </div>
      </td>
      <td>
        <FieldDatetime value={new Date(invoice.date)} />
      </td>
      <td>
        <FieldDatetime value={new Date(invoice.paidAt)} />
      </td>
      <td className="justify-end">
        <Tooltip text={t('Download invoice')}>
          <Button
            Icon={(
              <FontAwesomeIcon
                icon={light('download')}
              />
            )}
            onClick={() => downloadFile(invoice.pdfUrl)}
            variant="iconPrimary"
          />
        </Tooltip>
      </td>
    </tr>
  );
}

BillingInvoicesTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
};

export default BillingInvoicesTableViewRow;
