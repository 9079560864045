import { gql } from '@apollo/client';

import FragmentCountPerLevel from 'src/api/fragments/FragmentCountPerLevel';

function mapScans(targetGroup) {
  return {
    ...targetGroup,
    scans: targetGroup.scans.edges.map((edge) => edge.node),
  };
}

export function transformResponse(data) {
  if (!data) {
    return {
      items: [],
    };
  }
  return {
    items: data.evsTargetGroups.edges
      .map((edge) => mapScans(edge.node)),
  };
}

// TODO: Reuse ScanFragment
export const EVS_TARGET_GROUPS = gql`
  ${FragmentCountPerLevel}
  
  query evsTargetGroups ($companyId: ID!, $first: Int!, $scansFirst: Int!) {
    evsTargetGroups(
      company: $companyId
      first: $first
    ) {
      edges {
        node {
          id
          name
          schedule {
            id
            period
            days
            timeOfDay
            timezone
          }
          isScanRunning
          targetCount
          scans(first: $scansFirst) {
            edges {
              node {
                id
                deltaStatistics {
                  id
                  newCount {
                    total
                  }
                  remediatedCount {
                    total
                  }
                }
                statistics {
                  id
                  currentCount
                  vulnCountPerLevel {
                    ...FragmentCountPerLevel
                  }
                }
                ranAt
                startedAt
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const CREATE_EVS_TARGET_GROUP_MUTATION = gql`
  mutation($name: String!, $companyId: ID!, $targets: [String!]!) {
    evsTargetGroupCreate(input: {
      name: $name
      company: $companyId
      targets: $targets
    }) {
      targetGroup {
        id
        name
      }
    }
  }
`;

export const UPDATE_EVS_TARGET_GROUP_MUTATION = gql`
  mutation($name: String!, $targetGroup: ID!) {
    evsTargetGroupUpdate(input: {
      name: $name
      targetGroup: $targetGroup
    }) {
      targetGroup {
        id
        name
      }
    }
  }
`;

export const DELETE_EVS_TARGET_GROUP_MUTATION = gql`
  mutation($targetGroup: ID!) {
    evsTargetGroupDelete(input: {
      targetGroup: $targetGroup
    }) {
      id
    }
  }
`;

export const EVS_TARGET_GROUP_ADD_TARGETS = gql`
  mutation($targetGroup: ID!, $targets: [String!]!) {
    evsTargetGroupAddTargets(input: {
      targetGroup: $targetGroup
      targets: $targets
    }) {
      targetGroup {
        id
      }
    }
  }
`;

export const EVS_TARGET_GROUP_REMOVE_TARGETS = gql`
  mutation($targetGroup: ID!, $targets: [String!]!) {
    evsTargetGroupRemoveTargets(input: {
      targetGroup: $targetGroup
      targets: $targets
    }) {
      targetGroup {
        id
      }
    }
  }
`;
