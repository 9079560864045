import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { SelectOptionModel } from 'src/design/ui-kit/Select/Select';
import useFormBuilder from 'src/hooks/useFormBuilder';
import { USER_TYPE_LABELS } from 'src/users';

function useUserForm({ user, userCategoryType }) {
  const { t } = useTranslation();
  const { userConfig } = useCurrentUser();

  const userTypes = userConfig.getInvitationTypes(userCategoryType)
    .map((type) => new SelectOptionModel(
      type,
      USER_TYPE_LABELS[type],
    ));

  const initialFormData = user ? ({
    email: user.email,
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    userType: new SelectOptionModel(user.userType, USER_TYPE_LABELS[user.userType]),
  }) : ({
    email: '',
    firstName: '',
    lastName: '',
    userType: '',
  });

  const validationSchema = yup.object({
    email: yup
      .string()
      .required(t('Field is required'))
      .email(t('Must be a valid email field')),
    firstName: yup
      .string(),
    lastName: yup
      .string(),
    userType: yup
      .object()
      .required(t('Field is required')),
  }).required();

  return {
    ...useFormBuilder(initialFormData, validationSchema),
    userTypes,
  };
}

export default useUserForm;
