import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useFormBuilder from 'src/hooks/useFormBuilder';

function useSignInForm() {
  const { t } = useTranslation();

  const initialFormData = {
    email: '',
    password: '',
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .required(t('Field is required'))
      .email(t('Must be a valid email field')),
    password: yup
      .string()
      .required(t('Field is required')),
  }).required();

  return useFormBuilder(initialFormData, validationSchema);
}

export default useSignInForm;
