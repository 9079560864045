import React from 'react';

import PropTypes from 'prop-types';

function EvsTargetsTableViewRow(props) {
  const { data: target } = props;

  return (
    <tr id={`TableRow${target}`}>
      <td>
        <span>{target}</span>
      </td>

      {/* {permissions.canManageEvsSubscription() && ( */}
      {/*   <td> */}
      {/*     <EvsTargetsDeleteButton target={target} /> */}
      {/*   </td> */}
      {/* )} */}
    </tr>
  );
}

EvsTargetsTableViewRow.propTypes = {
  data: PropTypes.string.isRequired,
};

export default EvsTargetsTableViewRow;
