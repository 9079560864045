import React from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/common/Page';
import { useMSPBilling } from 'src/contexts/useMSPBilling';
import EmptyState from 'src/design/ui-kit/EmptyState/EmptyState';
import Loading from 'src/design/ui-kit/Loading/Loading';
// import BillingCompaniesTableView from 'src/views/Billing/BillingCompaniesTableView';
import BillingDetails from 'src/views/Billing/BillingDetails';
import BillingInvoicesDetails from 'src/views/Billing/BillingInvoicesDetails';
import ChangePaymentMethodButton from 'src/views/Billing/ChangePaymentMethod/ChangePaymentMethodButton';

function Billing() {
  const { loading, mspBillingDetails } = useMSPBilling();
  const { t } = useTranslation();

  const { paymentSource } = mspBillingDetails;

  if (loading) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page className="grid gap-6" title={t('Billing')}>
      <h1>{t('Billing')}</h1>
      {paymentSource ? (
        <>
          <BillingDetails />

          <BillingInvoicesDetails />

          {/* We'll use it later, but will need to pay extra to chargbee for this feature: */}
          {/* <section> */}
          {/*   <h2 className="text-base mb-6">{t('Current monthly cost by company')}</h2> */}
          {/*   <BillingCompaniesTableView companies={companies} /> */}
          {/* </section> */}
        </>
      ) : (
        <EmptyState
          subtitle={t('There is no payment method currently setup. Please add a credit credit to your account.')}
        >
          <ChangePaymentMethodButton />
        </EmptyState>
      )}
    </Page>
  );
}

export default Billing;
