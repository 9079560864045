import { EVS_VULNERABILITY_DETAILS_QUERY, transformEvsVulnerabilityDetailsResponse } from 'src/api/evsScans/evsScans';
import usePaginatedLoader from 'src/api/loaders/usePaginatedLoader';

export default function useEvsVulnerabilityLoader({ variables }) {
  return usePaginatedLoader(
    EVS_VULNERABILITY_DETAILS_QUERY,
    { variables },
    transformEvsVulnerabilityDetailsResponse,
  );
}
