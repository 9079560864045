export const VulnerabilityStatus = Object.freeze({
  CURRENT: 'CURRENT',
  NEW: 'NEW',
  REMEDIATED: 'REMEDIATED',
});

export const EVS_SEVERITY_SCORE_MIN = 0.0;
export const EVS_SEVERITY_SCORE_MAX = 10;
export const EVS_SEVERITY_SCORE_STEP = 0.1;

export const EvsSeverity = Object.freeze({
  Critical: 'critical',
  High: 'high',
  Info: 'info',
  Low: 'low',
  Medium: 'medium',
});

export function setSeverityScores([info, low, medium, high, critical]) {
  return {
    [EvsSeverity.Info]: info,
    [EvsSeverity.High]: high,
    [EvsSeverity.Low]: low,
    [EvsSeverity.Medium]: medium,
    [EvsSeverity.Critical]: critical,
  };
}

export const EvsEmptySeverityScoreMap = Object.freeze({
  [EvsSeverity.Info]: 0,
  [EvsSeverity.Low]: 0,
  [EvsSeverity.Medium]: 0,
  [EvsSeverity.High]: 0,
  [EvsSeverity.Critical]: 0,
});

export const EvsDefaultSeverityScoreMap = Object.freeze({
  [EvsSeverity.Info]: 0,
  [EvsSeverity.Low]: 1,
  [EvsSeverity.Medium]: 2,
  [EvsSeverity.High]: 3,
  [EvsSeverity.Critical]: 4,
});

export const VulnerabilityAssetStatus = Object.freeze({
  ...VulnerabilityStatus,
  PREVIOUSLY_IDENTIFIED: 'PREVIOUSLY_IDENTIFIED',
});
