import * as colorsMap from 'src/design/theme/colors';

const chargebeeStyles = {
  base: {
    '::placeholder': {
      color: colorsMap['od-white-500'],
    },
    ':focus': {
      color: colorsMap['od-white-900'],
    },
    ':focus::placeholder': {
      color: colorsMap['od-white-500'],
    },
    color: colorsMap['od-white-900'],

    fontSize: '16px',

    fontSmoothing: 'antialiased',

    fontWeight: 600,
  },
  invalid: {
    '::placeholder': {
      color: colorsMap['od-complementary-bad'],
    },
    ':focus': {
      color: colorsMap['od-complementary-bad'],
    },
    color: colorsMap['od-complementary-bad'],
  },
};

export default chargebeeStyles;
