import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useMatch } from 'react-router-dom';

import NavigationItem from 'src/design/models/NavigationItem';

function NavigationLink(props) {
  const { navigationItem } = props;

  const match = useMatch({
    path: navigationItem
      .absolutePath,
  });
  const classes = classNames('pl-4 py-3 relative od-font-main-medium z-20 flex items-center gap-2', {
    'rounded-md od-sidebar-li--active text-od-white-900': match,
    'text-od-white-500': !match,
  });

  return (
    <NavLink
      className={classes}
      end
      to={navigationItem.to}
    >
      {navigationItem.Icon}
      {navigationItem.name}
    </NavLink>
  );
}

NavigationLink.propTypes = {
  navigationItem: PropTypes.instanceOf(NavigationItem).isRequired,
};

export default NavigationLink;
