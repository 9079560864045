import React, { useRef, useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MSP_LOGO_ACCEPT_IMAGE_EXTENSIONS } from 'src/constants/msp';
import Button from 'src/design/ui-kit/Button/Button';

import ImageUploaderModal from './ImageUploaderModal';

function ImageUploader(props) {
  const { buttonLabel = '', onComplete } = props;
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState(null);

  const inputRef = useRef();

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const clearInput = () => {
    inputRef.current.value = '';
  };

  const onModalClose = () => {
    setImageUrl('');
    clearInput();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageUrl(URL.createObjectURL(file));
    clearInput();
  };

  return (
    <div>
      <input
        ref={inputRef}
        accept={MSP_LOGO_ACCEPT_IMAGE_EXTENSIONS}
        className="hidden"
        onChange={handleFileChange}
        type="file"
      />

      <ImageUploaderModal
        imageUrl={imageUrl}
        isOpen={!!imageUrl}
        onClose={onModalClose}
        onComplete={onComplete}
      />

      <Button
        Icon={<FontAwesomeIcon icon={light('image')} />}
        onClick={onButtonClick}
        size="xs"
      >
        {buttonLabel || t('Choose image')}
      </Button>
    </div>
  );
}

ImageUploader.propTypes = {
  buttonLabel: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
};

export default ImageUploader;
