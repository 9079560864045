import i18n from 'i18next';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { TableColumnDefinition } from 'src/hooks/useTable';

const useUserColumns = ({ userCategoryType }) => {
  const { permissions } = useCurrentUser();
  return [
    new TableColumnDefinition({
      name: 'fullName',
      title: i18n.t('User'),
    }),
    new TableColumnDefinition({
      name: 'email',
      title: i18n.t('Email'),
    }),
    new TableColumnDefinition({
      justifyContent: 'flex-end',
      name: 'role',
      title: i18n.t('Role'),
    }),
    permissions.canManageUsers(userCategoryType) ? new TableColumnDefinition({
      gridTemplate: '60px',
      name: 'editButton',
    }) : null,
    permissions.canManageUsers(userCategoryType) ? new TableColumnDefinition({
      gridTemplate: '60px',
      name: 'deleteButton',
    }) : null,
  ].filter(Boolean);
};

export default useUserColumns;
