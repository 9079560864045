import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { ReactComponent as IconClose } from 'src/design/custom-icons/close.svg';
import Button from 'src/design/ui-kit/Button/Button';
import { focusFirstFocusable } from 'src/helpers/focus';

// Transitions
// https://github.com/reactjs/react-modal/blob/master/docs/styles/transitions.md

function SidebarModal(props) {
  const {
    Subtitle = null,
    children,
    contentClassName = '',
    contentStyle = null,
    isOpen,
    onClose,
    shouldCloseOnOverlayClick = false,
    shouldFocusFirstFocusable = false,
    subtitle = '',
    title,
  } = props;

  const modalContent = useRef();

  const onAfterOpen = () => {
    if (shouldFocusFirstFocusable) {
      focusFirstFocusable(modalContent.current);
    }
  };

  return (
    <ReactModal
      ariaHideApp={false}
      className="Modal--sidebar"
      closeTimeoutMS={50}
      contentLabel={title}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      overlayClassName="Modal__overlay"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldFocusAfterRender={false}
    >
      <div className="h-full flex flex-col">
        <header
          className="flex justify-between pl-6 pr-10 pt-8"
        >
          <div>
            <h3>{title}</h3>
            {
             subtitle && (
               <p className="text-od-white-500 od-font-secondary-regular mt-3">{subtitle}</p>
             )
           }
            {Subtitle}
          </div>
          <Button
            className="self-start"
            Icon={<IconClose />}
            onClick={onClose}
            variant="text"
          />
        </header>
        <div
          ref={modalContent}
          className={classNames('Modal__content mt-5 pb-8', contentClassName)}
          style={contentStyle}
        >
          {children}
        </div>
      </div>
    </ReactModal>
  );
}

SidebarModal.propTypes = {
  // Subtitle component
  Subtitle: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  contentClassName: PropTypes.string,
  contentStyle: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldFocusFirstFocusable: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SidebarModal;
