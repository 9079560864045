import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import NavigationItem from 'src/design/models/NavigationItem';
import useFeatures from 'src/hooks/useFeatures';

function useMspNavigationItems(permissions) {
  const { t } = useTranslation();
  const { isMSPDashboardEnabled, isMSPSettingsEnabled } = useFeatures();

  return [
    isMSPDashboardEnabled() ? new NavigationItem(
      {
        Icon: (
          <FontAwesomeIcon icon={light('gauge')} />
        ),
        absolutePath: '/dashboard',
        key: 'dashboard',
        name: t('Dashboard'),
        relativePath: 'dashboard',
        to: '/dashboard',
      },
    ) : false,
    new NavigationItem(
      {
        Icon: (
          <FontAwesomeIcon icon={light('buildings')} />
        ),
        absolutePath: '/companies',
        key: 'companies',
        name: t('Companies'),
        relativePath: 'companies',
        to: '/companies',
      },
    ),
    permissions.canSetupPaymentMethod() ? new NavigationItem(
      {
        Icon: (
          <FontAwesomeIcon icon={light('file-invoice-dollar')} />
        ),
        absolutePath: '/billing/*',
        key: 'companies',
        name: t('Billing'),
        relativePath: 'billing/*',
        to: '/billing',
      },
    ) : false,
    new NavigationItem(
      {
        Icon: (
          <FontAwesomeIcon icon={light('people-group')} />
        ),
        absolutePath: '/team',
        name: t('Team'),
        relativePath: 'team',
        to: '/team',
      },
    ),
    isMSPSettingsEnabled() ? new NavigationItem(
      {
        Icon: (
          <FontAwesomeIcon icon={light('sliders')} />
        ),
        absolutePath: '/settings',
        name: t('MSP Settings'),
        relativePath: 'msp/settings',
        to: '/settings',
      },
    ) : false,
  ].filter(Boolean);
}

export default useMspNavigationItems;
