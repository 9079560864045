import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { EXPORT_BREACHES_MUTATION } from 'src/api/bci';
import ReportsModal from 'src/components/modals/ReportsModal';
import Button from 'src/design/ui-kit/Button/Button';
import { openInNewTab } from 'src/helpers/url';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function BciReportsButton() {
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const { companyId } = useUserParams();
  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();

  const [exportCSV, { loading: saving }] = useMutation(
    EXPORT_BREACHES_MUTATION,
    {
      onCompleted({ exportBreachesCsv }) {
        if (!exportBreachesCsv) {
          return;
        }
        openInNewTab(exportBreachesCsv.url);
        showSuccessMessage(t('Reports for breach credentials exported successfully'));
        setIsExportModalOpen(false);
      },
    },
  );

  const onExport = async () => {
    await exportCSV({
      variables: {
        companyId,
      },
    });
  };

  return (
    <>
      <Button
        className="EvsExportScanButton gap-2 flex items-center"
        Icon={<FontAwesomeIcon icon={light('cloud-arrow-down')} />}
        onClick={() => setIsExportModalOpen(true)}
      >
        {t('Export')}
      </Button>
      <ReportsModal
        isOpen={isExportModalOpen}
        loading={saving}
        onClose={() => setIsExportModalOpen(false)}
        onExport={onExport}
        supportedFormats={['csv']}
      />
    </>
  );
}

export default BciReportsButton;
