// eslint-disable-next-line import/prefer-default-export
export const BillingInvoiceStatus = Object.freeze({
  NotPaid: 'NotPaid',
  Paid: 'Paid',
  PaymentDue: 'PaymentDue',
  Pending: 'Pending',
  Posted: 'Posted',
  Upcoming: 'Upcoming',
  Voided: 'Voided',
});
