/* eslint-disable react/destructuring-assignment,react/prop-types */
import React, { useState } from 'react';

import { ReactComponent as IconEyeCrossed } from 'src/design/custom-icons/eye-crossed.svg';
import { ReactComponent as IconEye } from 'src/design/custom-icons/eye.svg';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';

function InputPassword(props) {
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const onToggle = () => {
    const toggledValue = !shouldShowPassword;
    if (props.onToggleShowPassword) {
      props.onToggleShowPassword(toggledValue);
    }
    setShouldShowPassword(toggledValue);
  };

  return (
    <Input
      {...props}
      InputIcon={(
        <Button
          onClick={onToggle}
          variant="text"
        >
          {shouldShowPassword ? <IconEyeCrossed /> : <IconEye />}
        </Button>
      )}
      type={shouldShowPassword ? 'text' : 'password'}
    />
  );
}

export default InputPassword;
