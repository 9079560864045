import { COMPANIES_QUERY, transformResponse } from 'src/api/companies';
import usePaginatedLoader from 'src/api/loaders/usePaginatedLoader';

export default function useCompaniesLoader({ skip, variables }) {
  return usePaginatedLoader(
    COMPANIES_QUERY,
    { skip, variables },
    transformResponse,
  );
}
