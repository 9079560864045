import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function GoBackLink(props) {
  const { children, to } = props;
  return (
    <NavLink
      className={'GoBackLink flex gap-2 items-center text-sm '
        + 'hover:text-od-button-primary-hover hover:opacity-100 '
        + 'od-font-button opacity-70'}
      to={to}
    >
      <FontAwesomeIcon icon={light('arrow-left')} />
      {children}
    </NavLink>
  );
}

GoBackLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  to: PropTypes.string.isRequired,
};

export default GoBackLink;
