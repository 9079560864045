import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import Button from 'src/design/ui-kit/Button/Button';
import FieldCost from 'src/design/ui-kit/Field/FieldCost/FieldCost';

function AddTargetGroupContinueButton(props) {
  const {
    disabled,
    loading = false,
    mode,
    onConfirm,
    targetGroup = null,
    totalPrice,
  } = props;

  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { t } = useTranslation();

  const getText = () => {
    if (mode === 'subscription') {
      return {
        description: t('For creating this subscription you will be charged <0>{{totalPrice}}</0>', {
          totalPrice,
        }),
        title: t('Subscribe'),
      };
    }

    if (targetGroup) {
      return {
        description: t('For updating this group you will be charged <0>{{totalPrice}}</0>', {
          totalPrice,
        }),
        title: t('Update group'),
      };
    }

    return {
      description: t('For creating this group you will be charged <0>{{totalPrice}}</0>', {
        totalPrice,
      }),
      title: t('Create group'),
    };
  };

  const { description, title } = getText();

  return (
    <>
      <Button
        className="w-full"
        disabled={disabled}
        onClick={() => setIsOpenConfirmationModal(true)}
      >
        {t('Continue')}
      </Button>

      <ConfirmationModal
        Cancel={(
          <Button
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('Cancel')}
          </Button>
        )}
        Confirm={(
          <Button
            Icon={<FontAwesomeIcon icon={light('thumbs-up')} />}
            loading={loading}
            onClick={onConfirm}
          >
            {t('Confirm')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        Subtitle={(
          <span className="od-font-secondary-medium text-od-white-500 mt-3">
            <Trans
              components={[
                (
                  <FieldCost className="text-od-white-900 od-font-secondary-medium inline-block" value={totalPrice} />
                ),
              ]}
              i18nKey={description}
              t={t}
            />
          </span>
        )}
        title={title}
      />
    </>
  );
}

AddTargetGroupContinueButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  mode: PropTypes.oneOf(['subscription', 'targetGroup']).isRequired,
  onConfirm: PropTypes.func.isRequired,
  targetGroup: PropTypes.object,
  totalPrice: PropTypes.number.isRequired,
};

export default AddTargetGroupContinueButton;
