import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatPrice, useConvertCentValue } from 'src/helpers/price';

function FieldCost(props) {
  const { className = 'od-secondary-regular', value = 0 } = props;

  const { convertFromCentValue } = useConvertCentValue(2);

  const classes = classNames(
    'Field FieldCost',
    className,
  );
  return (
    <div className={classes}>
      {formatPrice(convertFromCentValue(value))}
    </div>
  );
}

FieldCost.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
};

export default FieldCost;
