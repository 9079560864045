export function hexToRgba(hex, opacity) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
  }
  return hex;
}

// This function is a factory that creates a function that returns a color from the list of colors
// The returned color is removed from the list of colors to prevent duplicates
export function colorFactory(_colors) {
  let index = 0;
  const colorsCopy = [..._colors]; // Create a copy to avoid modifying the original

  return function getNextColor() {
    const color = colorsCopy[index];
    index = (index + 1) % colorsCopy.length; // Cycle back to 0 when reaching the end
    return color;
  };
}
