import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PieChart from 'src/components/charts/ScanPieChart/PieChart';
import ProgressBar from 'src/components/charts/ScanPieChart/ProgressBar';
import { colorFactory } from 'src/helpers/color';

// Generated by AI
const randomColors = [
  '#1F77B4', // Muted Blue
  '#AA7F0E', // Deep Orange
  '#2CA02C', // Forest Green
  '#a62728', // Brick Red
  '#9467BD', // Muted Purple
  '#8C564B', // Brown
  '#E377C2', // Pink
  '#7F7F7F', // Gray
  '#BCBD22', // Olive
  '#17BECF', // Turquoise
];

const buildData = (osCounts) => {
  const osList = Object.entries(osCounts)
    .map(([os, count]) => ({ count, os }))
    .filter(({ count, os }) => count > 0 && os !== 'Dead hosts')
    .slice(0, 10);
  const total = osList.reduce((result, current) => result + current.count, 0);
  const getColor = colorFactory(randomColors);

  return osList
    .map(({ count, os }) => ({
      color: getColor(),
      key: os,
      name: os === 'Failed ID' ? 'Unknown OS' : os,
      percent: (count / total) * 100,
      value: count,
    }));
};

const mapToChartData = (data) => data.map((item) => ({
  color: item.color,
  name: item.name,
  sliced: false,
  y: item.value,
}));

function ScanOSPieChart(props) {
  const { className = '', scan, style = {} } = props;
  const { t } = useTranslation();

  const osCounts = JSON.parse(JSON.parse(scan.statistics.osCounts));
  const data = buildData(osCounts);

  return (
    <section
      className={classNames('ScanPieChart p-6 flex flex-col', className)}
      style={style}
    >
      <div className="od-font-title-semi-bold">
        {t('Operating systems')}
      </div>
      <div className="mt-8 flex items-center gap-10 flex-1">
        <PieChart
          chartData={mapToChartData(data)}
          title={t('Operating systems')}
        />
        <div className="flex flex-1 flex-col gap-3">
          {data.map((item) => (
            <ProgressBar
              key={item.key}
              data={item}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

ScanOSPieChart.propTypes = {
  className: PropTypes.string,
  scan: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default ScanOSPieChart;
