import React from 'react';

import PropTypes from 'prop-types';

import NavigationLink from 'src/components/common/NavigationDrawer/NavigationLink';
import NavigationLinkNotifications from 'src/components/common/NavigationDrawer/NavigationLinkNotifications';
import UserWidget from 'src/components/common/UserWidget';
import { ReactComponent as LogoSvg } from 'src/design/images/logo.svg';
import NavigationItem from 'src/design/models/NavigationItem';

import LogoPreview from '../LogoPreview';

export default function NavigationDrawer(props) {
  const { Content = null, children, logoUrl = '', navigationItems } = props;

  return (
    <div
      className="flex h-screen"
    >
      <aside className="w-60 pr-1 border-od-black-500 border-r flex flex-col justify-between pb-8">
        <div>
          <a href="/">
            {logoUrl ? (
              <div className="w-full flex justify-center mt-6">
                <LogoPreview logoUrl={logoUrl} />
              </div>
            ) : (<LogoSvg className="mt-6 ml-5" />)}
          </a>
          {
            Content && (
              <div className="mt-6 ml-6 font-semibold">
                {Content}
              </div>
            )
          }
          <ul className="mt-20 pl-2">
            { navigationItems.map((navigationItem) => (
              <li key={navigationItem.to} className="relative">
                <NavigationLink navigationItem={navigationItem} />
                <div className="od-sidebar-li__box--active absolute
                rounded-md h-7 w-8 bg-od-action hidden top-2 z-10"
                />
              </li>
            ))}
          </ul>
        </div>

        <div className="grid gap-6">
          <ul className="pr-1 pl-1.5 grid gap-4">
            <li className="relative">
              <NavigationLinkNotifications />
              <div className="od-sidebar-li__box--active absolute
                rounded-md h-7 w-8 bg-od-action hidden top-2 z-10"
              />
            </li>
          </ul>

          <UserWidget />
        </div>
      </aside>
      <div className="flex-1 overflow-y-scroll">
        {children}
      </div>
    </div>
  );
}

NavigationDrawer.propTypes = {
  Content: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  logoUrl: PropTypes.string,
  navigationItems: PropTypes.arrayOf(PropTypes.instanceOf(NavigationItem)).isRequired,
};
