import React from 'react';

import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

function NoResultMessage(props) {
  const { searchValue } = props;

  const { t } = useTranslation();

  return (
    <Trans
      components={[
        (
          <span className="text-od-white-900">
            {`"${searchValue}"`}
          </span>
        ),
      ]}
      i18nKey="No result for <0>{{searchValue}}</0>"
      t={t}
      values={{
        searchValue,
      }}
    />
  );
}

NoResultMessage.propTypes = {
  searchValue: PropTypes.string.isRequired,
};

export default NoResultMessage;
