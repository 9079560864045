/* eslint-disable sort-keys */
// https://www.figma.com/file/0WZ58fpi2rhWOoFgydXTlz/OPFOR-Delta?node-id=99%3A1439

module.exports = {
  // Default
  transparent: 'transparent',

  'od-action': '#594CEA',
  'od-primary': '#FE8100',

  'od-yellow': '#FFD600',

  'od-cyan': '#00F0FF',

  'od-black-900': '#030303',
  'od-black-800': '#07070A',
  'od-black-700': '#0A0B10',
  'od-black-500': '#0F1015',
  'od-black-300': '#15191F',
  'od-black-100': '#313B48',

  'od-white-900': '#FFFFFF',
  'od-white-500': '#989898',
  'od-white-300': '#3F4044',

  // Complementary colors
  'od-complementary-critical': '#8850FF',
  'od-complementary-high': '#EB4A62',
  'od-complementary-medium': '#F07639',
  'od-complementary-low': '#12B2E7',
  'od-complementary-info': '#373A4D',
  'od-complementary-good': '#25D272',
  'od-complementary-bad': '#EA4760',

  // Status boxes
  'od-status-box-previous-text': '#0064E2',
  'od-status-box-previous-bg': '#2485FF',

  'od-status-box-new-text': '#EB4A62',
  'od-status-box-new-bg': '#EA4760',

  'od-status-box-resolved-text': '#25D272',
  'od-status-box-resolved-bg': '#33E281',

  'od-status-box-current-text': '#6B40BA',
  'od-status-box-current-bg': '#6B40BA',

  // Button states
  'od-button-primary-hover': '#675BEF',
  'od-button-primary-focus': '#4236CF',

  'od-input-border-disabled': 'rgba(63, 64, 68, 0.4)',

  'od-breach-credentials-red': '#FF7E92',
  'od-vulnerability-item-count': '#84AEFF',

  'od-extra-violet': '#BCB1FF',

  'od-extra-violet-12': 'rgba(188, 177, 255, 0.12)',
};
