import React, { useRef, useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';

import { ReactComponent as IconAvatarChecked } from 'src/design/custom-icons/avatar-checked.svg';
import { ReactComponent as IconAvatarUnchecked } from 'src/design/custom-icons/avatar-unchecked.svg';
import Avatar, { ALL_AVATAR_NAMES } from 'src/design/ui-kit/Avatar/Avatar';
import Button from 'src/design/ui-kit/Button/Button';

function ProfileAvatar(props) {
  const { onChange, value = ALL_AVATAR_NAMES[0] } = props;
  const triggerRef = useRef();
  const { t } = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const onClickOk = () => {
    setIsPopoverOpen(false);
    onChange(localValue);
  };

  const getContent = () => (
    <div className="AvatarList border rounded-od-10 border-od-black-100 bg-od-black-700 px-3 py-5">
      <div className="grid grid-cols-5 grid-rows-2 gap-3">
        {ALL_AVATAR_NAMES.map((name) => (
          <Button
            key={name}
            className="relative"
            onClick={() => setLocalValue(name)}
            variant="text"
          >
            <Avatar
              preset={name}
            />
            {name === localValue ? (
              <IconAvatarChecked className="absolute left-9 bottom-9" />
            ) : (
              <IconAvatarUnchecked className="absolute left-9 bottom-9" />
            )}
          </Button>
        ))}
      </div>
      <div className="mt-3 flex justify-end">
        <Button onClick={onClickOk}>
          {t('Ok')}
        </Button>
      </div>
    </div>
  );

  return (
    <div className="ProfileAvatar">
      <Popover
        boundaryElement={triggerRef.current}
        containerClassName="ProfileAvatar__popover"
        content={getContent}
        isOpen={isPopoverOpen}
        onClickOutside={() => setIsPopoverOpen(false)}
        padding={12}
        positions={['bottom']}
        reposition={false}
      >
        <div
          ref={triggerRef}
          className="relative cursor-pointer"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          onKeyDown={() => {}}
          role="button"
          tabIndex="0"
        >
          <Avatar preset={value} size="lg" />
          <Button
            className="absolute left-14 top-14 bg-od-white-900 text-od-white-300 px-1 rounded"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            variant="text"
          >
            <FontAwesomeIcon icon={light('pen')} />
          </Button>
        </div>
      </Popover>
    </div>
  );
}

ProfileAvatar.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default ProfileAvatar;
