import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { LONG_LOCALIZED_DATE } from 'src/constants/date';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import useUserParams from 'src/hooks/useUserParams';

function PerDomainAnalyticsTableViewRow(props) {
  const { data: domainBcStatistics } = props;

  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const { userConfig } = useCurrentUser();

  const recordsLink = userConfig.buildUrl(
    `/breach-credentials/${domainBcStatistics.name}`,
    `/${companyId}`,
  );

  return (
    <tr>
      <td>
        {domainBcStatistics.recordCount >= 0 ? (
          <Tooltip text={t('View records')}>
            <NavLink
              className="text-link flex gap-2 items-center group"
              to={recordsLink}
            >
              <FontAwesomeIcon
                className="Button--primary px-3 py-2 max-h-9 rounded-od-10"
                icon={light('list')}
              />

              <span className="group-hover:text-od-button-primary-hover">
                {domainBcStatistics.name}
              </span>
            </NavLink>
          </Tooltip>
        ) : (
          <span className="text-od-white-500">{domainBcStatistics.name}</span>
        )}
      </td>
      <td className="text-od-white-500">{domainBcStatistics.recordCount}</td>
      <td className="text-od-white-500">{domainBcStatistics.breachCount}</td>
      <td className="text-od-white-500">{domainBcStatistics.impactedUserCount}</td>
      <td className="text-od-white-500">{domainBcStatistics.credentialSetCount}</td>
      <td className="flex items-center gap-5 justify-end">
        <time className="text-od-white-500">
          {format(new Date(domainBcStatistics.addedAt), LONG_LOCALIZED_DATE)}
        </time>
      </td>
    </tr>
  );
}

PerDomainAnalyticsTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
};

export default PerDomainAnalyticsTableViewRow;
