import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';

function CompanyDashboardInfoTooltip() {
  const { t } = useTranslation();

  const getContent = () => (
    <div className="InfoIcon__Tooltip od-tooltip od-tooltip--md">
      <p>{t('Formula Total')}</p>
      <p className="mt-1 od-font-secondary-regular text-od-white-500">
        {t('Description')}
      </p>
      <div className="mt-5 grid gap-2.5 grid-cols-7">
        <FontAwesomeIcon
          className="rotate-90 text-od-status-box-previous-text"
          icon={light('arrow-up-to-dotted-line')}
        />

        <FontAwesomeIcon icon={light('plus')} />

        <FontAwesomeIcon
          className="text-od-status-box-new-text"
          icon={light('arrow-up-to-dotted-line')}
        />

        <FontAwesomeIcon icon={light('minus')} />

        <FontAwesomeIcon
          className="rotate-180 text-od-status-box-resolved-text"
          icon={light('arrow-up-to-dotted-line')}
        />

        <FontAwesomeIcon icon={light('equals')} />

        <FontAwesomeIcon
          className="text-od-status-box-current-text"
          icon={light('chart-scatter')}
        />
      </div>
      <p className="mt-5">
        {t('Meaning of icons')}
      </p>
      <div
        className="mt-2.5 grid gap-y-2.5 gap-x-1"
        style={{ gridTemplateColumns: 'max-content 1fr' }}
      >
        <FontAwesomeIcon
          className="rotate-90 text-od-status-box-previous-text"
          icon={light('arrow-up-to-dotted-line')}
          size="sm"
        />
        <span className="od-font-third-regular text-od-white-500">
          {t('- Previous')}
        </span>

        <FontAwesomeIcon
          className="text-od-status-box-new-text"
          icon={light('arrow-up-to-dotted-line')}
          size="sm"
        />
        <span className="od-font-third-regular text-od-white-500">
          {t('- New')}
        </span>

        <FontAwesomeIcon
          className="rotate-180 text-od-status-box-resolved-text"
          icon={light('arrow-up-to-dotted-line')}
          size="sm"
        />
        <span className="od-font-third-regular text-od-white-500">
          {t('- Resolved')}
        </span>

        <FontAwesomeIcon
          className="text-od-status-box-current-text"
          icon={light('chart-scatter')}
          size="sm"
        />
        <span className="od-font-third-regular text-od-white-500">
          {t('- Current')}
        </span>
      </div>
    </div>
  );

  return (
    <Tooltip
      popoverProps={{
        content: getContent(),
        positions: ['right'],
      }}
    >
      <FontAwesomeIcon icon={light('circle-info')} />
    </Tooltip>
  );
}

export default CompanyDashboardInfoTooltip;
