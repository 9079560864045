import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/common/Page';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { ReactComponent as LogoSvg } from 'src/design/images/logo.svg';
import Alert from 'src/design/ui-kit/Alert/Alert';

function VerifyEmail() {
  const [countdown, setCountdown] = useState(4);
  const { navigateToSignIn } = useCurrentUser();
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown < 1) {
        clearInterval(interval);
        navigateToSignIn();
        return;
      }
      setCountdown(countdown - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown, setCountdown, navigateToSignIn]);

  return (
    <Page
      className="EmailVerified h-full flex justify-center items-center"
      title={t('Email verified')}
    >
      <section className="grid gap-4 text-center" style={{ width: '420px' }}>
        <div className="flex justify-center">
          <LogoSvg />
        </div>
        <Alert variant="success">
          <h3 className="">{t('Your email has been verified')}</h3>
        </Alert>
        <p>{t('Redirecting to login in {{countdown}} seconds...', { countdown })}</p>
      </section>
    </Page>
  );
}

export default VerifyEmail;
