import React from 'react';

import { useTranslation } from 'react-i18next';

import GoBackLink from 'src/components/common/GoBackLink';
import Page from 'src/components/common/Page';
import EvsScanActions from 'src/components/tactics.evs/scans/EvsScanActions';
import EvsTargets from 'src/components/tactics.evs/targets/EvsTargets';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import IconVulnerability from 'src/design/custom-icons/IconVulnerability/IconVulnerability';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
import TacticVulnerabilitiesDetails from 'src/views/TacticEvs/TacticVulnerabilitiesDetails';
import AddTargetGroupButton from 'src/views/TacticEvs/TargetGroups/AddTargetGroup/AddTargetGroupButton';
import GroupsGridView from 'src/views/TacticEvs/TargetGroups/GroupsGridView';

function TacticEvs() {
  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const { permissions, userConfig } = useCurrentUser();
  const { tactics: { evs: { companyEvsSubscription, evsTargetGroups } } } = useCurrentCompany();

  if (!companyEvsSubscription) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  // TODO: Add target groups pagination
  return (
    <Page
      className="Page TacticsEvs"
      title={t('External Network Vulnerability Scanning')}
    >
      <header>
        <h3 className="flex gap-2 items-center mb-4">
          <IconVulnerability />
          {t('External Network Vulnerability Scanning')}
        </h3>

        <GoBackLink to={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}>
          {t('All tactics')}
        </GoBackLink>
      </header>

      <TacticVulnerabilitiesDetails evsTargetGroups={evsTargetGroups} />

      <section className="flex items-center justify-between mt-10 mb-6">
        <h5 className="od-font-title-semi-bold">
          {t('Groups ({{count}})', { count: evsTargetGroups.length })}
        </h5>

        {permissions.canManageEvsSubscription() && (
          <div className="flex items-center gap-3">
            <EvsScanActions showSchedule />
            <AddTargetGroupButton />
          </div>
        )}
      </section>

      <GroupsGridView evsTargetGroups={evsTargetGroups} />

      <EvsTargets />
    </Page>
  );
}

export default TacticEvs;
