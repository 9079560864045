import React, { useState, useRef } from 'react';

import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';

import Button from 'src/design/ui-kit/Button/Button';

const positions = ['top', 'left', 'right', 'bottom'];

export class DropdownItem {
  constructor(name, Icon, action) {
    this.name = name;
    this.Icon = Icon;
    this.action = action;
  }
}

function DropdownMenu(props) {
  const { children, items, position = 'bottom' } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const triggerRef = useRef();

  const handleDropdownItemClick = (dropdownItem) => {
    dropdownItem.action();
    setIsPopoverOpen(false);
  };

  const getContent = ({ childRect }) => (
    <div
      className="bg-od-black-500 px-3 py-5"
      style={{
        width: `${childRect.width}px`,
      }}
    >
      <ul>
        {items.map((item) => (
          <li key={item.name}>
            <Button
              className="p-2 text-od-white-500 hover:text-od-white-900
               hover:bg-od-black-300 rounded-lg flex items-center w-full"
              onClick={() => handleDropdownItemClick(item)}
              variant="text"
            >
              {item.Icon && (
                <div className="mr-3">
                  {item.Icon}
                </div>
              )}
              {item.name}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
  return (
    <Popover
      boundaryElement={triggerRef.current}
      content={getContent}
      isOpen={isPopoverOpen}
      onClickOutside={() => setIsPopoverOpen(false)}
      padding={10}
      positions={[position]}
      reposition
    >
      <div
        ref={triggerRef}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
      >
        {children}
      </div>
    </Popover>
  );
}

DropdownMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(DropdownItem)).isRequired,
  position: PropTypes.oneOf(positions),
};

export default DropdownMenu;
