import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormControl from 'src/components/common/FormControl';
import { ReactComponent as LogoSvg } from 'src/design/images/logo.svg';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';

import useRequestResetPasswordForm from './useRequestResetPasswordForm';

function RequestResetPasswordForm(props) {
  const { isEmailSent, loading, onSubmit } = props;
  const { t } = useTranslation();

  const {
    form: { formState, handleSubmit, register },
    helpers: { getFieldError },
  } = useRequestResetPasswordForm();

  const onSubmitWrapper = (data, event) => {
    event.preventDefault();

    if (!formState.isValid) {
      return;
    }

    onSubmit(data);
  };

  return (
    <form
      className="RequestResetPasswordForm"
      noValidate
      onSubmit={handleSubmit(onSubmitWrapper)}
      style={{
        width: '420px',
      }}
    >
      <div className="flex justify-center">
        <LogoSvg />
      </div>

      {!isEmailSent ? (
        <>
          <h1 className="text-center mt-6">{isEmailSent ? t('Reset email sent!') : t('Reset your password')}</h1>
          <div className="mt-6 flex flex-col gap-4">
            <FormControl
              error={getFieldError('email')}
              htmlFor="email"
              label={t('Email')}
            >
              <Input
                error={getFieldError('email')}
                name="email"
                placeholder={t('Enter email')}
                register={register}
              />
            </FormControl>
          </div>
          <div className="mt-6 flex justify-center">
            {t('A password reset email will be sent if an account with this email exists.')}
          </div>
          <div className="mt-8">
            <Button
              className="w-full"
              disabled={loading}
              type="submit"
              visuallyDisabled={!formState.isValid}
            >
              {t('Send')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <h1 className="text-center mt-6">{t('Reset email sent!')}</h1>
          <div className="mt-6 flex justify-center">
            {t('A password recovery email will be sent to this email address.\n'
                + 'Please check your spam/junk folders if you do not receive it in your inbox.')}
          </div>
          <div className="mt-8">
            <Button
              className="w-full"
              onClick={() => window.location.replace('/users/login/')}
            >
              {t('Sign in')}
            </Button>
          </div>
        </>
      )}
    </form>
  );
}

RequestResetPasswordForm.propTypes = {
  isEmailSent: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RequestResetPasswordForm;
