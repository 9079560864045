import { gql } from '@apollo/client';

import FragmentBciStatistics from 'src/api/fragments/FragmentBciStatistics';
import FragmentBciSubscription from 'src/api/fragments/FragmentBciSubscription';

export const BCI_SUBSCRIPTION = gql`
  ${FragmentBciSubscription}
  ${FragmentBciStatistics}

  query companyBciSubscription ($companyId: ID!, $includeBciStatistics: Boolean!) {
    companyBciSubscription(company: $companyId) {
      ...BCISubscriptionNode
      
      company @include(if: $includeBciStatistics) {
        bcStatistics {
          ...BCStatistics
        }
      }
    }
  }
`;

export const BCI_SUBSCRIBE = gql`
  mutation($companyId: ID!, $targetDomains: [String!]!) {
    bciSubscribe(input: {
      company: $companyId
      targetDomains: $targetDomains
    }) {
      subscription {
        id
      }
    }
  }
`;

export const BCI_UNSUBSCRIBE = gql`
  ${FragmentBciSubscription}

  mutation ($company: ID!) {
    bciUnsubscribe(input: {
      company: $company
    }) {
      subscription {
        ...BCISubscriptionNode
      }
    }
  }
`;

export const BCI_RESUME_SUBSCRIPTION = gql`
  ${FragmentBciSubscription}

  mutation ($company: ID!) {
    bciResumeSubscription(input: {
      company: $company
    }) {
      subscription {
        ...BCISubscriptionNode
      }
    }
  }
`;

export const BCI_SUBSCRIPTION_PRICES = gql`
  query bciPrices ($companyId: ID!) {
    bciPrices(customer: $companyId) {
      domainPrice
      domainSetupPrice
    }
  }
`;

export const BCI_ESTIMATE = gql`
  query bciEstimate ($companyId: ID!, $targetDomains: [String!]!) {
    bciEstimate(company: $companyId, targetDomains: $targetDomains) {
      qtyAddDomains
      estimateAddThisMonth
      totalEstimateNextMonth
    }
  }
`;
