import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const variants = ['default', 'danger'];

function Badge(props) {
  const { children, variant = variants[0] } = props;

  return (
    <div
      className={classNames('Badge od-font-secondary-regular px-2 py-1 rounded-od-12 inline-flex gap-2 items-center', {
        'text-od-complementary-bad bg-od-complementary-bad/10': variant === 'danger',
        'text-od-extra-violet bg-od-extra-violet-12': variant === 'default',
      })}
    >
      {children}
    </div>
  );
}

Badge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf(variants),
};

export default Badge;
