const highlightedClassName = 'od-element--highlighted';

export default function highlightElement(id, timeout = 2000, scrollIntoView = true) {
  const element = document.getElementById(id);

  if (!element) {
    // eslint-disable-next-line no-console
    console.warn(`Element with id ${id} was not found`);
    return;
  }

  element.classList.add(highlightedClassName);

  if (scrollIntoView) {
    element.scrollIntoView();
  }

  setTimeout(() => element.classList.remove(highlightedClassName), timeout);
}
