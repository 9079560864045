import { gql } from '@apollo/client';

export const BREACH_CREDENTIALS_QUERY = gql`
  query allBreachedCredentials(
    $first: Int!,
    $after: String,
    $companyId: ID!,
    $domain: String,
    $orderByFields: [String!])
  {
    allBreachedCredentials(
      first: $first
      after: $after
      company: $companyId
      domain: $domain
      orderByFields: $orderByFields
    ) @connection(key: "id") {
      edges {
        node {
          id
          addedAt
          breach {
            name
            leakDate
          }
          domain
          password
          username
          ipAddress
          allData
          userId
          emailAddress
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export function transformResponse(data) {
  if (!data) {
    return {
      items: [],
      pageInfo: {
        endCursor: null,
        hasNextPage: false,
      },
    };
  }
  return {
    items: data.allBreachedCredentials.edges.map((edge) => edge.node),
    pageInfo: data.allBreachedCredentials.pageInfo,
  };
}

export const BCI_DOMAINS = gql`
  query allBreachedCredentials(
    $companyId: ID!)
  {
    bciDomains(
      company: $companyId
    ) @connection(key: "id") {
      edges {
        node {
          id
          name
          enabled
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

/**
 * @deprecated
 */
export const BCI_SET_DOMAINS_MUTATION = gql`
  mutation($companyId: ID!, $domains: [String!]!) {
    bciSetDomains(input: {
      company: $companyId
      domains: $domains
    }) {
      domains
    }
  }
`;

export const BCI_ADD_DOMAINS_MUTATION = gql`
  mutation($companyId: ID!, $domains: [String!]!) {
    bciAddDomain(input: {
      company: $companyId
      domains: $domains
    }) {
       domains {
        id
        name
      }
    }
  }
`;

export const BCI_SET_ENABLED_DOMAIN_MUTATION = gql`
  mutation($companyId: ID!, $domain: String!, $enabled: Boolean!) {
    bciSetEnabledDomain(input: {
      company: $companyId
      domain: $domain
      enabled: $enabled
    }) {
       domain {
        id
        name
        enabled
       }
    }
  }
`;

export const EXPORT_BREACHES_MUTATION = gql`
  mutation($companyId: ID!) {
    exportBreachesCsv(input: {
      company: $companyId
    }) {
      url
    }
  }
`;
