import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import EmptyState from 'src/design/ui-kit/EmptyState/EmptyState';
import AddCompanyButton from 'src/views/Companies/AddCompanyButton';

function CompaniesEmptyState(props) {
  const { permissions, showArchived } = props;

  const { t } = useTranslation();

  const getTitle = () => {
    if (showArchived) {
      return 'You have no archived companies to display';
    }
    return t("Here's where you'll find a list of all the companies you manage.");
  };

  const getSubtitle = () => {
    if (permissions.canManageCompany() && !showArchived) {
      return t('To add your first company, simply click the "Add Company" button below!');
    }
    return '';
  };

  return (
    <EmptyState
      subtitle={getSubtitle()}
      title={getTitle()}
      variant="tertiary"
    >
      {permissions.canManageCompany() && !showArchived && (
        <AddCompanyButton />
      )}
    </EmptyState>
  );
}

CompaniesEmptyState.propTypes = {
  permissions: PropTypes.object.isRequired,
  showArchived: PropTypes.bool.isRequired,
};

export default CompaniesEmptyState;
