import cookie from 'cookie';

import { XCSRFToken, ContentType, DOCX_MEDIA_TYPE } from 'src/api/constants/headers';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { openInNewTab } from 'src/helpers/url';

export default function useMSPSettingsEvsActions() {
  const cookies = cookie.parse(document.cookie);
  const { me } = useCurrentUser();

  const { reportTemplate: reportTemplateUrl } = me.msp.links;

  const csrfToken = cookies.csrftoken;

  /**
   * Download the report template
   */
  const downloadTemplate = async () => openInNewTab(reportTemplateUrl);

  /**
   * Upload the report template
   */
  const uploadTemplate = (file) => fetch(reportTemplateUrl, {
    body: file,
    headers: {
      [ContentType]: DOCX_MEDIA_TYPE,
      [XCSRFToken]: csrfToken,
    },
    method: 'PUT',
  });

  /**
   * Delete the uploaded template
   */
  const revertReportTemplate = () => fetch(reportTemplateUrl, {
    headers: {
      [XCSRFToken]: csrfToken,
    },
    method: 'DELETE',
  });

  return {
    downloadTemplate,
    revertReportTemplate,
    uploadTemplate,
  };
}
