import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { EVS_SCAN_VULNERABILITIES_MAIN_QUERY } from 'src/api/evsScans/evsScans';
import Page from 'src/components/common/Page';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
import EvsScanHeader from 'src/views/EvsScanOverview/EvsScanOverviewHeader';
import EvsScanVulnerabilitiesContent from 'src/views/EvsScanVulnerabilities/EvsScanVulnerabilitiesContent';
import PageNotFound from 'src/views/PageNotFound/PageNotFound';

function EvsScanVulnerabilities() {
  const { t } = useTranslation();
  const { scanId } = useUserParams();
  const [showInfo, setShowInfo] = useState(false);
  const excludeLevels = showInfo ? null : ['Info'];

  const { data: scanData, loading } = useQuery(EVS_SCAN_VULNERABILITIES_MAIN_QUERY, {
    variables: {
      excludeLevels,
      scanId,
    },
  });

  if (loading) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  if (!scanData) {
    return (
      <PageNotFound title={t('Scan not found')} />
    );
  }

  return (
    <Page className="EvsScanVulnerabilities" title={t('EVS | Scan vulnerabilities')}>
      <EvsScanHeader
        activeTab="vulnerabilities"
        setShowInfo={setShowInfo}
        showInfo={showInfo}
      />

      <EvsScanVulnerabilitiesContent
        scan={scanData.scan}
        showInfo={showInfo}
      />
    </Page>
  );
}

export default EvsScanVulnerabilities;
