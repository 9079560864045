function useGooglePlacesHelpers() {
  function getDefaultAddressObject() {
    return {
      city: '',
      country: '',
      googleMapLink: '',
      name: '',
      state: '',
      street_address: '',
      zip_code: '',
    };
  }

  function isValidAddress(address) {
    return !!address.street_address
        && !!address.city
        && !!address.country
        && !!address.zip_code;
  }

  // https://developers.google.com/maps/documentation/javascript/place-autocomplete#javascript_5
  function fillInAddress(autocomplete) {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    let address1 = '';
    let postcode = '';
    let country = '';
    let city = '';
    let state = '';

    const addressState = getDefaultAddressObject();

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    // eslint-disable-next-line no-restricted-syntax
    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      // eslint-disable-next-line default-case
      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          break;
        }
        case 'route': {
          address1 += component.short_name;
          break;
        }
        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }
        case 'administrative_area_level_1': {
          state = component.short_name;
          break;
        }
        case 'country':
          country = component.long_name;
          break;

        case 'locality':
          city = component.long_name;
          break;
      }
    }

    addressState.street_address = address1;
    addressState.city = city;
    addressState.zip_code = postcode;
    addressState.country = country;
    addressState.state = state;

    return addressState;
  }

  return {
    fillInAddress,
    getDefaultAddressObject,
    isValidAddress,
  };
}

export default useGooglePlacesHelpers;
