import { BREACH_CREDENTIALS_QUERY, transformResponse } from 'src/api/bci';
import usePaginatedLoader from 'src/api/loaders/usePaginatedLoader';

export default function useBreachCredentialsLoader({ variables }) {
  return usePaginatedLoader(
    BREACH_CREDENTIALS_QUERY,
    { variables },
    transformResponse,
  );
}
