/* eslint-disable react/no-this-in-sfc */
import React from 'react';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

import * as colorsMap from 'src/design/theme/colors';

function EvsVulnerabilitySeverityStackedBarChart({ series }) {
  /**
   * @type {import('highcharts').Options} options
   */
  const options = {
    chart: {
      backgroundColor: null,
      height: 48,
      spacing: [0, 0, 0, 0],
      type: 'bar',
      width: 240,
    },
    credits: {
      enabled: false,
    },
    height: 100,
    legend: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          formatter() {
            // Calculate the width of the bar segment(since it is horizontal, it is actually the height)
            const barSize = this.point.shapeArgs.height;

            // Estimate the width of the label
            const fontSize = 14; // Adjust to match your actual font size
            const labelSize = this.point.y.toString().length * fontSize * 0.6; // Approximation

            // If the label does not fit inside the bar segment, return null to hide it
            if (labelSize > barSize) {
              return null;
            }

            // If the label fits inside the bar segment, return the default label
            return this.point.y;
          },
          inside: true,
          style: {
            textOutline: 'none', // Remove the text outline
          },
        },
        stacking: 'percent',
      },
      series: {
        borderRadius: 0,
        borderWidth: 0, // Remove the border from the bars
      },
    },
    series,
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: colorsMap['od-black-900'],
      borderRadius: 4,
      borderWidth: 0,
      formatter() {
        return (`
          <div class="rounded-xl od-font-secondary-semi-bold od-font-family text-od-white-900 flex items-center gap-2">
             <div class="h-3.5 w-3.5 rounded" style="background: ${this.color}"></div>
             ${this.y} (${this.series.name})
          </div>
      `);
      },
      shadow: false,
      shape: 'square',
      shared: false,
      useHTML: true,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      min: 0,
      title: {
        text: null,
      },
      visible: false,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

EvsVulnerabilitySeverityStackedBarChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number),
    name: PropTypes.string,
  })),
};

export default EvsVulnerabilitySeverityStackedBarChart;
