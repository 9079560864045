import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import apolloClient from 'src/api/apolloClient';
import { EVS_SUBSCRIBE } from 'src/api/evsSubscription';
import { CREATE_EVS_TARGET_GROUP_MUTATION,
  EVS_TARGET_GROUP_ADD_TARGETS,
  EVS_TARGET_GROUP_REMOVE_TARGETS,
  UPDATE_EVS_TARGET_GROUP_MUTATION } from 'src/api/evsTargetGroups';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import highlightElement from 'src/helpers/highlightElement';
import { compareTargets } from 'src/helpers/targets';
import useToast from 'src/hooks/useToast';

export default function useTargetGroupActions({
  companyId,
  evsTargets,
  formData,
  handleClose,
  mode,
  targetGroup,
}) {
  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();

  const { tactics: { evs: { refetchQueries } } } = useCurrentCompany();

  // Create target group
  const [createTargetGroup, { loading: creatingTargetGroup }] = useMutation(CREATE_EVS_TARGET_GROUP_MUTATION, {
    onCompleted(response) {
      if (response.evsTargetGroupCreate) {
        showSuccessMessage(t('Target group successfully created'));
        setTimeout(() => highlightElement(`GridRow${response.evsTargetGroupCreate.targetGroup.id}`));
        handleClose();
      }
    },
    refetchQueries,
  });

  // Update target group
  const [updateTargetGroup, { loading: updatingTargetGroup }] = useMutation(UPDATE_EVS_TARGET_GROUP_MUTATION);

  // Subscribe to EVS and add first targets
  const [subscribe, { loading: subscribing }] = useMutation(EVS_SUBSCRIBE, {
    onCompleted({ evsSubscribe }) {
      if (evsSubscribe) {
        showSuccessMessage(t('Successfully subscribed'));
        handleClose();
      }
    },
    refetchQueries,
  });

  // Update target group with new targets
  const [addTargets, { loading: addingTargets }] = useMutation(EVS_TARGET_GROUP_ADD_TARGETS);

  // Update target group with deleted targets
  const [removeTargets, { loading: removingTargets }] = useMutation(EVS_TARGET_GROUP_REMOVE_TARGETS);

  const onSave = async () => {
    try {
      if (mode === 'subscription') {
        await subscribe({
          variables: {
            company: companyId,
            targetGroupName: formData.name,
            targetGroupTargets: formData.targets,
          },
        });
      } else if (targetGroup) {
        let hasUpdated = false;

        // Update target group name
        if (targetGroup.name !== formData.name) {
          await updateTargetGroup({
            variables: {
              name: formData.name,
              targetGroup: targetGroup.id,
            },
          });
          hasUpdated = true;
        }

        const { deletedTargets, newTargets } = compareTargets(evsTargets, formData.targets);
        // Add targets to the existing target group
        if (newTargets.length > 0) {
          await addTargets({
            variables: {
              targetGroup: targetGroup.id,
              targets: newTargets,
            },
          });
          hasUpdated = true;
        }
        // Remove targets from the existing target group
        if (deletedTargets.length > 0) {
          await removeTargets({
            variables: {
              targetGroup: targetGroup.id,
              targets: deletedTargets,
            },
          });
          hasUpdated = true;
        }
        if (hasUpdated) {
          showSuccessMessage(t('Target group successfully updated'));
          handleClose();
          await apolloClient.refetchQueries({
            include: refetchQueries,
          });
        }
      } else {
        await createTargetGroup({
          variables: {
            companyId,
            name: formData.name,
            targets: formData.targets,
          },
        });
      }
    } catch (e) {
      //
    }
  };

  const saving = creatingTargetGroup || updatingTargetGroup || subscribing || addingTargets || removingTargets;

  return {
    onSave,
    saving,
  };
}
