import React from 'react';

import { useTranslation } from 'react-i18next';

import ImageUploader from 'src/components/common/ImageUploader/ImageUploader';
import LogoPreview from 'src/components/common/LogoPreview';
import { MSP_LOGO_IMAGE_FILE_EXTENSIONS, MSP_LOGO_MAX_SIZE_MB } from 'src/constants/msp';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Card from 'src/design/ui-kit/Card/Card';

function MSPSettingsLogoUploadSection() {
  const { me } = useCurrentUser();
  const { t } = useTranslation();

  const onComplete = (image) => {
    localStorage.setItem('mspLogo', image);
    window.location.reload();
  };

  return (
    <section className="mt-8">
      <h2>{t('MSP logo')}</h2>

      <div className="grid grid-cols-2 gap-5 mt-4 items-stretch">
        <Card>
          <p className="text-sm text-od-white-500">
            {t('Please follow these guidelines when uploading your MSP logo:')}
          </p>
          <ul className="text-xs list-disc list-inside mt-3 text-od-complementary-good/80">
            <li>
              {t('The logo should be in the following formats: {{formats}} .', {
                formats: MSP_LOGO_IMAGE_FILE_EXTENSIONS.join(', '),
              })}
            </li>
            <li>{t('The file size should not exceed {{size}}MB.', { size: MSP_LOGO_MAX_SIZE_MB }) }</li>
            <li>{t('Ensure the logo is clear and not pixelated.')}</li>
            <li>{t('Avoid using logos with a lot of text.')}</li>
          </ul>
        </Card>

        <Card>
          <div className="mt-4 mb-4">
            {me.msp.logo && (
            <LogoPreview logoUrl={me.msp.logo} />
            )}
          </div>
          <ImageUploader onComplete={onComplete} />
        </Card>
      </div>

    </section>
  );
}

export default MSPSettingsLogoUploadSection;
