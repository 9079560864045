import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfToday } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { ReactComponent as AddressCardLockIcon } from 'src/design/custom-icons/fa-light-address-card-lock.svg';
import colors from 'src/design/theme/colors';
import Button from 'src/design/ui-kit/Button/Button';
import InfoCardVisual from 'src/design/ui-kit/InfoCardVisual/InfoCardVisual';
import { formatDate } from 'src/helpers/date';
import useUserParams from 'src/hooks/useUserParams';

function CompanyDashboardBreachCredentialsOverview(props) {
  const { bcStatistics } = props;
  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const navigate = useNavigate();
  const { userConfig } = useCurrentUser();

  if (!bcStatistics) {
    return '';
  }

  return (
    <section>
      <h3>{t('Breach Credentials Overview')}</h3>

      <div className="mt-6 grid gap-4 bg-od-black-500 rounded-od-20 px-5 py-5">
        <div className="grid grid-cols-2 gap-4">
          <InfoCardVisual
            color={colors['od-status-box-new-bg']}
            description={t('New Credentials Found ({{date}})', { date: formatDate(startOfToday()) })}
            Icon={<AddressCardLockIcon className="w-6 h-6 fill-[currentColor]" />}
          >
            {bcStatistics.breachCount}
          </InfoCardVisual>

          <InfoCardVisual
            color={colors['od-complementary-medium']}
            description={t('Credentials Found during current month')}
            Icon={<FontAwesomeIcon icon={light('database')} />}
          >
            {bcStatistics.recordCount}
          </InfoCardVisual>
        </div>

        <div>
          <Button
            onClick={() => navigate(userConfig.buildUrl('/breach-credentials', `/${companyId}`))}
            size="sm"
          >
            {t('View Results')}
          </Button>
        </div>
      </div>
    </section>
  );
}

CompanyDashboardBreachCredentialsOverview.propTypes = {
  bcStatistics: PropTypes.object.isRequired,
};

export default CompanyDashboardBreachCredentialsOverview;
