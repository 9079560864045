import React from 'react';

// import { sortAlphabetically } from 'src/helpers/sort';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import { TableColumnDefinition } from 'src/hooks/useTable';

const useCompanyColumns = (permissions) => {
  const { t } = useTranslation();

  const columns = [
    // TODO: Enable sorting again
    new TableColumnDefinition({
      name: 'name',
      // sortConfig: new TableSortState(
      //   'name',
      //   'asc',
      //   sortAlphabetically((company) => company.name),
      // ),
      sortable: false,
      title: t('Company'),
    }),
    new TableColumnDefinition({
      Icon: (
        <Tooltip text={t('This is the number of users from the company who have access to their portal.')}>
          <FontAwesomeIcon icon={light('circle-info')} />
        </Tooltip>
      ),
      justifyContent: 'center',
      name: 'usersCount',
      title: t('Ext Users'),
    }),
    new TableColumnDefinition({
      justifyContent: 'center',
      name: 'vulnerabilities',
      title: t('Vulnerabilities'),
    }),
    new TableColumnDefinition({
      justifyContent: 'center',
      name: 'breachCredentials',
      title: t('Breach Credentials'),
    }),
    // new TableColumnDefinition({
    //   justifyContent: 'flex-end',
    //   name: 'cost',
    //   title: i18n.t('Cost'),
    // }),
  ];

  if (permissions.canManageCompany()) {
    columns.push(
      // EditButton
      new TableColumnDefinition({
        gridTemplate: '60px',
        name: 'editButton',
      }),
    );
  }

  return columns;
};

export default useCompanyColumns;
