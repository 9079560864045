import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isSubscriptionActive } from 'src/constants/subscription';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import FieldCost from 'src/design/ui-kit/Field/FieldCost/FieldCost';
import FieldPeriodCost from 'src/design/ui-kit/Field/FieldPeriodCost/FieldPeriodCost';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';

function TargetGroupCost(props) {
  const { basePrice, targetPrice, totalPrice, totalTargetsPrice } = props;
  const { t } = useTranslation();
  const { tactics: { evs: { evsSubscriptionStatus } } } = useCurrentCompany();

  return (
    <section className="TargetGroupCost pt-3">
      <div className="grid gap-2">
        {!isSubscriptionActive(evsSubscriptionStatus) && (
        <div className="flex items-center justify-between">
          <span className="od-font-main-regular text-od-white-500">
            {t('Base subscription cost:')}
          </span>
          <FieldCost
            className="od-font-main-regular text-od-white-500"
            value={basePrice}
          />
        </div>
        )}
        <div className="flex items-center justify-between">
          <span className="od-font-main-regular text-od-white-500">
            {t('Cost per IP:')}
          </span>
          <FieldCost
            className="od-font-main-regular text-od-white-500"
            value={targetPrice}
          />
        </div>
        <div className="flex items-center justify-between">
          <span className="od-font-main-regular text-od-white-500">
            {t('Total cost for IPs:')}
          </span>
          <FieldCost
            className="od-font-main-regular text-od-white-500"
            value={totalTargetsPrice}
          />
        </div>
      </div>

      <div className="flex items-center justify-between mt-2">
        <span className="od-font-title-regular">
          {t('Total cost:')}
        </span>
        <span className="flex items-center gap-2.5">
          <Tooltip text={
            t('Information about the cost calculation with recalculation at the time of the 1st day of each month')
          }
          >
            <FontAwesomeIcon icon={light('circle-info')} />
          </Tooltip>
          <FieldPeriodCost timeUnit="month" value={totalPrice} />
        </span>
      </div>
    </section>
  );
}

TargetGroupCost.propTypes = {
  basePrice: PropTypes.number.isRequired,
  targetPrice: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  totalTargetsPrice: PropTypes.number.isRequired,
};

export default TargetGroupCost;
