import { gql } from '@apollo/client';

const FragmentBciSubscription = gql`
  fragment BCISubscriptionNode on BCISubscriptionNode {
    id

    startsAt
    modifiedAt
    expiresAt

    isActive
    
    status
  }
`;

export default FragmentBciSubscription;
