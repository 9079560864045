import React from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { MSP_BILLING_COMPANY_DETAILS } from 'src/api/billing';
import GoBackLink from 'src/components/common/GoBackLink';
import Page from 'src/components/common/Page';
import FieldCost from 'src/design/ui-kit/Field/FieldCost/FieldCost';
import FieldDatetime from 'src/design/ui-kit/Field/FieldDatetime/FieldDatetime';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
import CompanyBillingSubscriptions from 'src/views/CompanyBilling/CompanyBillingSubscriptions';
import PageNotFound from 'src/views/PageNotFound/PageNotFound';

function CompanyBilling() {
  const { t } = useTranslation();
  const { companyId } = useUserParams();

  const { data, error, loading } = useQuery(MSP_BILLING_COMPANY_DETAILS, { variables: {
    id: companyId,
  } });

  const { mspBillingCompanyDetails } = data || { mspBillingCompanyDetails: null };

  if (error || loading) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  if (!mspBillingCompanyDetails) {
    return (
      <PageNotFound title={t('Company not found')} />
    );
  }

  const { company, cost, evsSubscription, nextPayment } = mspBillingCompanyDetails;

  const title = t('{{companyName}} - Billing information', {
    companyName: company.name,
  });

  return (
    <Page title={title}>
      <h3 className="mb-6 mb-4">
        {title}
      </h3>

      <GoBackLink to="/billing">
        {t('Billing')}
      </GoBackLink>

      <section className="flex gap-4 mt-6">
        <InfoCard
          className="flex-1"
          description={t('Total monthly cost')}
        >
          <FieldCost value={cost} />
        </InfoCard>
        {nextPayment && (
        <InfoCard
          className="flex-1"
          description={t('Next payment')}
        >
          <div className="flex items-center gap-2">
            <FieldCost value={nextPayment.cost} />
            <FieldDatetime value={new Date(nextPayment.date)} />
          </div>
        </InfoCard>
        )}
      </section>

      <div className="od-font-title-semi-bold mt-10 mb-6">
        {t('Subscriptions')}
      </div>

      <CompanyBillingSubscriptions
        cost={cost}
        evsSubscription={evsSubscription}
      />

      {/* <div className="od-font-title-semi-bold mt-10 mb-6"> */}
      {/*  {t('Payment history')} */}
      {/* </div> */}

      {/* <CompanyBillingPaymentsTableView payments={payments} /> */}
    </Page>
  );
}

export default CompanyBilling;
