import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const variants = ['error', 'success'];

function Alert(props) {
  const { children, variant } = props;

  return (
    <div
      className={classNames('Alert px-5 py-3 od-font-secondary-medium rounded-od-8', {
        'text-od-complementary-bad border-od-complementary-bad border': variant === 'error',
        'text-od-complementary-good border-od-complementary-good border': variant === 'success',
      })}
      style={{
        ...variant === 'error' && {
          background: 'linear-gradient(0deg, rgba(255, 51, 129, 0.12), rgba(255, 51, 129, 0.12)), #030303',
        },
        ...variant === 'success' && {
          background: 'linear-gradient(0deg, rgba(51, 226, 129, 0.12), rgba(51, 226, 129, 0.12)), #030303',
        },
      }}
    >
      {children}
    </div>
  );
}

Alert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf(variants).isRequired,
};

export default Alert;
