import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';
import CompanyModal from 'src/views/Companies/CompanyModal';

function AddCompanyButton() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button
        Icon={<FontAwesomeIcon icon={light('square-plus')} />}
        onClick={() => setIsOpen(true)}
      >
        {t('Add company')}
      </Button>
      <CompanyModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}

export default AddCompanyButton;
