import { useEffect, useState } from 'react';

import { useQuery, isNetworkRequestSettled } from '@apollo/client';

/**
 * @description
 * The function will be used for no-cache only to handle the pagination in the right way
 * https://github.com/apollographql/apollo-client/issues/5239
 */
export default function usePaginatedLoader(query, { skip, variables }, transformResponse) {
  /**
   * @var {Array} items - a list of paginated data
   *
   */
  const [items, setItems] = useState([]);
  /**
   * @var {Boolean} isLoadingMore - indicates loading state
   */
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  /**
   * @var {Boolean} hasLoaded - indicates if the data was loaded
   */
  const [hasLoaded, setHasLoaded] = useState(false);
  /**
   * @var {Object} originalData - Object of the original response data.
   * Can be used if paginated items are nested, and we need both the items and the parent object
   */
  const [originalData, setOriginalData] = useState(null);
  /**
   * @var {Object} pageInfo - an object storing pagination state
   */
  const [pageInfo, setPageInfo] = useState({
    endCursor: null,
    hasNextPage: false,
  });

  const result = useQuery(query, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(response) {
      const transformedResponse = transformResponse(response);
      setItems(transformedResponse.items);
      setOriginalData(transformedResponse.originalData);
      setPageInfo(transformedResponse.pageInfo);
    },
    skip,
    variables,
  });

  // For some reason loading is not yet set to false when the request is settled and the data is available.
  // This results in a flickering empty state.
  useEffect(() => {
    if (isNetworkRequestSettled(result.networkStatus)) {
      setTimeout(() => {
        setHasLoaded(true);
      });
    }
  }, [result.networkStatus]);

  const fetchMore = async (...args) => {
    setIsLoadingMore(true);
    const response = await result.fetchMore(...args);
    const transformedResponse = transformResponse(response.data);
    setItems([
      ...items,
      ...transformedResponse.items,
    ]);
    setOriginalData(transformedResponse.originalData);
    setPageInfo(transformedResponse.pageInfo);
    setIsLoadingMore(false);
  };

  return {
    ...result,
    data: {
      items,
      originalData,
      pageInfo,
    },
    fetchMore,
    hasLoaded,
    isLoadingMore,
  };
}
