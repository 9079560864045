/* eslint-disable react/destructuring-assignment,react/prop-types */
import React from 'react';

import classNames from 'classnames';
import ReactSelect, { components } from 'react-select';

import DropdownArrow from 'src/components/common/DropdownArrow';
import { ReactComponent as IconCloseMd } from 'src/design/custom-icons/close-md.svg';
import { ReactComponent as IconCloseSm } from 'src/design/custom-icons/close-sm.svg';

export class SelectOptionModel {
  constructor(value, label, id, item) {
    this.value = value;
    this.label = label;
    this.id = id;
    this.item = item;
  }
}

// https://react-select.com/components

function Option({ data: option, innerProps }) {
  return (
    <div
      {...innerProps}
      className="Select__option p-2 hover:shadow-od-select-option rounded-od-8 text-od-white-900 cursor-pointer mb-2"
    >
      { option.label }
    </div>
  );
}

function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props}>
      <button>
        <IconCloseSm />
      </button>
    </components.MultiValueRemove>
  );
}

function IndicatorSeparator() {
  return (
    <div
      className="h-4 w-px Select__indicator-separator"
      style={{
        background: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '1px',
      }}
    />
  );
}

function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props}>
      <button>
        <IconCloseMd />
      </button>
    </components.ClearIndicator>
  );
}

function LoadingIndicator() {
  return ('');
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <button className="p-1">
        <DropdownArrow />
      </button>
    </components.DropdownIndicator>
  );
}

function Select(props) {
  return (
    <ReactSelect
      {...props}
      className={classNames(
        'Select',
        {
          'Select--has-separator': props.hasSeparator,
          'Select--is-multi': props.isMulti,
          'Select--is-single': !props.isMulti,
        },
        props.className,
      )}
      classNamePrefix="Select"
      components={{
        ClearIndicator,
        DropdownIndicator: props.isDisabled ? null : DropdownIndicator,
        IndicatorSeparator,
        LoadingIndicator,
        MultiValueRemove,
        Option,
        ...props.components,
      }}
    />
  );
}

export default Select;
