import React from 'react';

import { useTranslation } from 'react-i18next';

import GoBackLink from 'src/components/common/GoBackLink';
import Page from 'src/components/common/Page';
import BillingInvoicesTableView from 'src/views/BillingInvoices/BillingInvoicesTableView';

function BillingInvoices() {
  const { t } = useTranslation();

  return (
    <Page title={t('Billing invoices')}>
      <h1 className="mb-4">{t('Billing invoices')}</h1>

      <GoBackLink to="/billing">
        {t('Billing')}
      </GoBackLink>

      <section className="mt-10">
        <BillingInvoicesTableView />
      </section>
    </Page>
  );
}

export default BillingInvoices;
