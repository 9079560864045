import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { isValidName, usePasswordValidations } from 'src/helpers/validators';
import useFormBuilder from 'src/hooks/useFormBuilder';

function useSignUpForm(showCompanyField) {
  const { t } = useTranslation();
  const { passwordValidator, validationsMap } = usePasswordValidations();

  const schema = yup.object({
    ...showCompanyField && {
      company: yup
        .string()
        .required(t('Field is required')),
    },
    email: yup
      .string()
      .required(t('Field is required'))
      .email(t('Must be a valid email field')),
    firstName: yup
      .string()
      .required(t('Field is required'))
      .test('name', i18n.t('Must be a valid first name'), isValidName),
    lastName: yup
      .string()
      .required(t('Field is required'))
      .test('name', i18n.t('Must be a valid last name'), isValidName),
    password: passwordValidator,
  }).required();

  const initialFormData = {
    company: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  };

  const formBuilder = useFormBuilder(
    initialFormData,
    schema,
    { focusField: showCompanyField ? 'company' : 'firstName',
      reactHookFormOptions: {
        criteriaMode: 'all',
      } },
  );

  return {
    formBuilder,
    validationsMap,
  };
}

export default useSignUpForm;
