import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';

import NavigationDrawer from 'src/components/common/NavigationDrawer/NavigationDrawer';
import Page from 'src/components/common/Page';
import CompanySelect from 'src/components/features/companies/CompanySelect';
import { CompanyProvider, useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { MSPBillingProvider } from 'src/contexts/useMSPBilling';
import Button from 'src/design/ui-kit/Button/Button';
import Loading from 'src/design/ui-kit/Loading/Loading';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import useUserParams from 'src/hooks/useUserParams';
import useCompanyNavigationItems from 'src/views/Company/useCompanyNavigationItems';
import Notifications from 'src/views/Notifications/Notifications';
import PageNotFound from 'src/views/PageNotFound/PageNotFound';

function CompanyContent() {
  const { isCompanyUser, me, userConfig } = useCurrentUser();
  const { companyId } = useUserParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { company, loading } = useCurrentCompany();
  const companiesNavigationItems = useCompanyNavigationItems(company);

  if (loading) {
    return (
      <Page title={t('Loading...')}>
        <Loading />
      </Page>
    );
  }

  if (!company) {
    return (
      <PageNotFound title={t('Company not found')} />
    );
  }

  const onChangeCompany = (newCompany) => {
    if (newCompany.id === companyId) {
      return;
    }
    navigate(userConfig.buildUrl(`/${newCompany.id}/dashboard`));
  };

  return (
    <NavigationDrawer
      Content={(
        <div className="flex gap-2 items-center -ml-4">
          {userConfig.hasCompanySelect ? (
            <>
              <Tooltip text={t('Exit company control, back to MSP')}>
                <Button
                  className="!px-3"
                  Icon={(
                    <FontAwesomeIcon
                      flip="horizontal"
                      icon={light('person-to-portal')}
                    />
                  )}
                  onClick={() => navigate(userConfig.buildUrl('/'))}
                />
              </Tooltip>
              <CompanySelect
                onChange={onChangeCompany}
                selectedCompany={company}
              />
            </>
          ) : (
            <span className="ml-4">{me.company?.name || me.msp?.name}</span>
          )}
        </div>
      )}
      navigationItems={companiesNavigationItems}
    >
      <Routes>
        {isCompanyUser && (
          <Route element={<Notifications />} path="notifications" />
        )}
        { companiesNavigationItems.map((navigationItem) => (
          <Route
            key={navigationItem.relativePath}
            element={navigationItem.component}
            path={navigationItem.relativePath}
          />
        ))}
      </Routes>
    </NavigationDrawer>
  );
}

function Company() {
  const { isCompanyUser, permissions } = useCurrentUser();
  if (isCompanyUser || !permissions.canViewBillingDetails()) {
    return (
      <CompanyProvider>
        <CompanyContent />
      </CompanyProvider>
    );
  }
  return (
    <MSPBillingProvider>
      <CompanyProvider>
        <CompanyContent />
      </CompanyProvider>
    </MSPBillingProvider>
  );
}

export default Company;
