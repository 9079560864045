import React, { useMemo } from 'react';

import omit from 'lodash-es/omit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useEvsVulnerabilityLoader from 'src/api/loaders/useEvsVulnerabilityLoader';
import Alert from 'src/design/ui-kit/Alert/Alert';
import Loading from 'src/design/ui-kit/Loading/Loading';
import EvsVulnerabilityRowDetailsAssetsList
  from 'src/views/EvsScanVulnerabilities/VulnerabilityRow/EvsVulnerabilityRowDetailsAssetsList';
import VulnerabilityRowDetailsPlugin
  from 'src/views/EvsScanVulnerabilities/VulnerabilityRow/VulnerabilityRowDetailsPlugin';

const assetsFirst = 10;

function VulnerabilityRowDetails(props) {
  const { scanId, vulnerability: vulnBasic, vulnerabilityType } = props;

  const variables = useMemo(() => ({
    assetsFirst,
    scanId,
    vulnId: vulnBasic.id,
  }), [scanId, vulnBasic]);

  const {
    data: { items: assets, originalData: vulnerability, pageInfo },
    error,
    fetchMore,
    isLoadingMore,
    loading,
  } = useEvsVulnerabilityLoader({ variables });

  const onLoadMoreAssets = () => fetchMore({
    variables: {
      ...variables,
      assetsAfter: pageInfo.endCursor,
    },
  });

  const { t } = useTranslation();

  if (error || loading || !vulnerability) {
    return <Loading size="sm" />;
  }

  const attributes = JSON.parse(vulnerability.plugin.attributes);

  const pluginAttributes = omit(
    attributes,
    [
      'description', 'solution', 'see_also', 'synopsis', 'cve',
      'cvss_base_score', 'cvss3_base_score', 'cvss_vector', 'cvss3_vector',
    ],
  );

  const getCVSSLabel = () => {
    if (attributes.cvss3_base_score) {
      return t('CVSS 3');
    }
    if (attributes.cvss_base_score) {
      return t('CVSS');
    }
    return '';
  };

  return (
    <div className="VulnerabilityItemDetails py-6 px-10 grid gap-10">
      <section className="grid gap-4 grid-cols-[max-content_auto]">
        <p className="text-od-white-500 od-font-secondary-medium flex gap-4 items-center">
          {t('Severity')}
        </p>

        <div className="flex">
          <div
            className="px-4 py-1 rounded-md text-od-white-900 text-sm"
            style={{ background: vulnerabilityType.color }}
          >
            {vulnerabilityType.name}
          </div>
        </div>

        {(attributes.cvss_base_score || attributes.cvss3_base_score) && (
          <>
            <p className="text-od-white-500 od-font-secondary-medium flex gap-4 items-center">
              {getCVSSLabel()}
            </p>

            <div className="flex">
              <div
                className="px-4 py-1 rounded-md text-od-white-900 bg-od-white-300 text-sm group cursor-pointer"
              >
                <div className="group-hover:hidden">{attributes.cvss_base_score || attributes.cvss3_base_score}</div>
                <div className="hidden group-hover:block">{attributes.cvss3_vector || attributes.cvss_vector}</div>
              </div>
            </div>
          </>
        )}
      </section>

      <section className="grid gap-4">
        <p className="text-od-white-500 od-font-secondary-medium">
          {t('Synopsis')}
        </p>
        <div
          className="code text-sm bg-od-black-900 p-2 rounded-md overflow-x-auto"
        >
          {vulnerability.plugin.synopsis}
        </div>
      </section>

      <section className="grid gap-4">
        <p className="text-od-white-500 od-font-secondary-medium">
          {t('Description')}
        </p>
        <div
          className="code text-sm bg-od-black-900 p-2 rounded-md overflow-x-auto"
        >
          {vulnerability.plugin.description}
        </div>
      </section>

      <section className="grid gap-4">
        <p className="text-od-white-500 od-font-secondary-medium">
          {t('Solution')}
        </p>
        <Alert variant="success">
          {vulnerability.plugin.solution}
        </Alert>
      </section>

      {(vulnerability.plugin.seeAlso.length > 0 || attributes.cve) && (
      <section className="grid gap-4">
        <p className="text-od-white-500 od-font-secondary-medium">
          {t('See also')}
        </p>
        <ul>
          {attributes.cve && (
            <li className="grid gap-2">
              <a
                className="text-sm link--text-highlighted"
                href={`https://nvd.nist.gov/vuln/detail/${attributes.cve}`}
                rel="noreferrer"
                target="_blank"
              >
                {attributes.cve}
              </a>
            </li>
          )}
          {vulnerability.plugin.seeAlso.map((link, index) => (
            <li key={index} className="grid gap-2">
              <a
                className="text-sm link--text-highlighted"
                href={link}
                rel="noreferrer"
                target="_blank"
              >
                {link}
              </a>
            </li>
          ))}
        </ul>
      </section>
      )}

      <EvsVulnerabilityRowDetailsAssetsList
        assets={assets}
        isLoadingMore={isLoadingMore}
        onLoadMore={onLoadMoreAssets}
        pageInfo={pageInfo}
      />

      <VulnerabilityRowDetailsPlugin attributes={pluginAttributes} />
    </div>
  );
}

VulnerabilityRowDetails.propTypes = {
  scanId: PropTypes.string.isRequired,
  vulnerability: PropTypes.object.isRequired,
  vulnerabilityType: PropTypes.object.isRequired,
};

export default VulnerabilityRowDetails;
