import usePaginatedLoader from 'src/api/loaders/usePaginatedLoader';
import { USERS_QUERY, transformResponse } from 'src/api/users';

export default function useUsersLoader({ variables }) {
  return usePaginatedLoader(
    USERS_QUERY,
    { variables },
    transformResponse,
  );
}
