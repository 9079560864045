export default function useValidationHelpers({ formState }) {
  return {
    getFieldError(field) {
      return formState.errors[field]?.message ?? '';
    },
    isFieldTouched(field) {
      return !!formState.touchedFields[field];
    },
    isFieldValid(field, checkIfDirty = true) {
      if (checkIfDirty) {
        return formState.dirtyFields[field] && !formState.errors[field];
      }
      return !formState.errors[field];
    },
    isFieldValidByRule(field, rule) {
      return !formState?.errors[field]?.types[rule];
    },
  };
}
