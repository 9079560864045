import React, { useState } from 'react';

import PropTypes from 'prop-types';

import TableView from 'src/components/common/Table/TableView';
import { sortByDate } from 'src/helpers/sort';
import { applySort, TableSortState } from 'src/hooks/useTable';

import PerDomainAnalyticsTableViewRow from './PerDomainAnalyticsTableViewRow';
import usePerDomainAnalyticsColumns from './usePerDomainAnalyticsColumns';

// TODO: Refactor: Prefix call components with "Bci" or "Evs" to avoid confusion
function PerDomainAnalyticsTableView(props) {
  const { bcStatistics } = props;
  const columns = usePerDomainAnalyticsColumns();
  const [sortState, setSortState] = useState(new TableSortState(
    'addedAt',
    'desc',
    sortByDate((domainBCStatistics) => domainBCStatistics.addedAt)({
      sortDirection: 'desc',
    }),
  ));

  const filteredDomainBcStatistics = applySort(
    bcStatistics.domains,
    sortState,
  );

  return (
    <TableView
      className="PerDomainAnalyticsTableView gap-y-3"
      columns={columns}
      data={filteredDomainBcStatistics}
      onSortStateChange={setSortState}
      sortState={sortState}
      TableRow={PerDomainAnalyticsTableViewRow}
    />
  );
}

PerDomainAnalyticsTableView.propTypes = {
  bcStatistics: PropTypes.object.isRequired,
};

export default PerDomainAnalyticsTableView;
