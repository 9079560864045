import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import FormControl from 'src/components/common/FormControl';
import { ReactComponent as LogoSvg } from 'src/design/images/logo.svg';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import InputPassword from 'src/design/ui-kit/InputPassword/InputPassword';

import useSignInForm from './useSignInForm';

function SignInForm(props) {
  const { onSubmit, saving } = props;
  const { t } = useTranslation();

  const {
    form: { formState, handleSubmit, register },
    helpers: { getFieldError },
  } = useSignInForm();

  const onSubmitWrapper = (data, event) => {
    event.preventDefault();

    if (!formState.isValid) {
      return;
    }

    onSubmit(data);
  };

  return (
    <form
      className="SignInForm"
      noValidate
      onSubmit={handleSubmit(onSubmitWrapper)}
      style={{
        width: '420px',
      }}
    >
      <section className="grid gap-6">
        <div className="flex justify-center">
          <LogoSvg />
        </div>
        <h1 className="text-center">{t('Please enter your email and password')}</h1>
      </section>

      <div className="mt-6 flex flex-col gap-4">
        <FormControl
          error={getFieldError('email')}
          htmlFor="email"
          label={t('Email')}
        >
          <Input
            error={getFieldError('email')}
            name="email"
            placeholder={t('Enter email')}
            register={register}
          />
        </FormControl>

        <FormControl
          error={getFieldError('password')}
          htmlFor="password"
          label={t('Password')}
        >
          <InputPassword
            error={getFieldError('password')}
            name="password"
            placeholder={t('Enter password')}
            register={register}
          />
        </FormControl>
      </div>

      <div className="mt-6 flex justify-center">
        <NavLink to="/auth/request-reset-password">
          {t('Forgot your password?')}
        </NavLink>
      </div>

      <div className="mt-8">
        <Button
          className="w-full"
          disabled={saving}
          iconPosition="after"
          type="submit"
          visuallyDisabled={!formState.isValid}
        >
          {t('Sign in')}
        </Button>
      </div>
    </form>
  );
}

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default SignInForm;
