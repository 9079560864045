import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';

function LimitSwitcher(props) {
  const { onSwitch, selected, values } = props;
  const { t } = useTranslation();

  return (
    <div className="LimitSwitcher flex items-center gap-1">
      {values.map((value) => (
        <Button
          key={value}
          className={classNames('LimitSwitcher__button px-4 py-2 rounded-od-8 od-font-main-regular', {
            'bg-od-black-700': selected === value,
            'text-od-white-500': selected !== value,
          })}
          onClick={() => onSwitch(value)}
          variant="text"
        >
          <Trans
            i18nKey="{{n}} scans"
            t={t}
            values={{ n: value }}
          />
        </Button>
      ))}
    </div>
  );
}

LimitSwitcher.propTypes = {
  onSwitch: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default LimitSwitcher;
