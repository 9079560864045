import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'src/design/ui-kit/Button/Button';
import { weekdays } from 'src/helpers/date';

function WeekView(props) {
  const { onChange, value } = props;

  const onClick = (n) => {
    if (value.includes(n)) {
      const i = value.indexOf(n);
      onChange([
        ...value.slice(0, i),
        ...value.slice(i + 1),
      ].sort());
    } else {
      onChange([
        ...value,
        n,
      ].sort());
    }
  };

  return (
    <section>
      <div className="grid grid-cols-7 gap-2">
        {weekdays().map(({ id, text }) => (
          <Button
            key={id}
            className={classNames(
              'h-8 py-2 px-4 flex items-center justify-center',
              'od-font-secondary-regular border border-od-black-100 rounded-md',
              {
                'bg-od-primary': value.includes(id),
              },
            )}
            onClick={() => onClick(id)}
            variant="text"
          >
            {text}
          </Button>
        ))}
      </div>
    </section>
  );
}

WeekView.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};

export default WeekView;
