import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';
import AddDomainsSidebarModal from 'src/views/TacticBci/AddDomains/AddDomainsSidebarModal';

function AddDomainsButton() {
  const [isOpenDomainsSidebarModal, setIsOpenDomainsSidebarModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        Icon={<FontAwesomeIcon icon={light('square-plus')} />}
        onClick={() => setIsOpenDomainsSidebarModal(true)}
      >
        {t('Add domains')}
      </Button>
      <AddDomainsSidebarModal
        isOpen={isOpenDomainsSidebarModal}
        mode="domains"
        onClose={() => setIsOpenDomainsSidebarModal(false)}
      />
    </>
  );
}

export default AddDomainsButton;
