import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const EVS_TARGETS = gql`
  query evsTargets($companyId: ID!, $targetGroup: ID, $first: Int, $search: String) {
    evsTargets(
      company: $companyId
      targetGroup: $targetGroup
      first: $first
      search: $search
    ) {
      edges {
        node
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
