import i18n from 'i18next';

import { TableColumnDefinition } from 'src/hooks/useTable';

const useVulnerabilityScansColumns = () => [
  new TableColumnDefinition({
    name: 'startDate',
    title: i18n.t('Start date'),
  }),
  new TableColumnDefinition({
    name: 'targetGroup',
    title: i18n.t('Target Group'),
  }),
  new TableColumnDefinition({
    name: 'vulnerabilities',
    title: i18n.t('Vulnerabilities'),
  }),
  new TableColumnDefinition({
    justifyContent: 'flex-end',
    name: 'status',
    title: i18n.t('Status'),
  }),
];

export default useVulnerabilityScansColumns;
