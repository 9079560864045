import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { COMPANY_NOTIFICATIONS_CONFIG_QUERY, UPDATE_NOTIFICATIONS_CONFIG_MUTATION } from 'src/api/notifications';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';
import Switch from 'src/design/ui-kit/Switch/Switch';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function EvsNotificationSettings() {
  const { t } = useTranslation();
  const { companyId } = useUserParams();

  const { data, loading: loadingCompanyNotificationsConfig, refetch } = useQuery(COMPANY_NOTIFICATIONS_CONFIG_QUERY, {
    variables: {
      company: companyId,
    },
  });
  const evsEmail = data ? data.companyNotificationConfig.evs.email : false;
  const bciEmail = data ? data.companyNotificationConfig.bci.email : false;
  const { showSuccessMessage } = useToast();

  const [
    _updateNotificationConfig,
    { loading: savingNotificationSettings },
  ] = useMutation(UPDATE_NOTIFICATIONS_CONFIG_MUTATION, {
    async onCompleted({ updateNotificationConfig }) {
      if (updateNotificationConfig) {
        const emailEnabled = updateNotificationConfig.config.evs.email;
        if (emailEnabled) {
          showSuccessMessage(t('Email notifications enabled'));
        } else {
          showSuccessMessage(t('Email notifications disabled'));
        }
        await refetch({
          company: companyId,
        });
      }
    },
  });

  const loading = loadingCompanyNotificationsConfig || savingNotificationSettings;

  const onChange = async (newEvsEmail) => {
    await _updateNotificationConfig({
      variables: {
        bciEmail,
        companyId,
        evsEmail: newEvsEmail,
      },
    });
  };

  return (
    <InfoCard className="col-span-2" description={t('Notification settings')}>
      <Switch
        checked={evsEmail}
        disabled={loading}
        id="Companies__archived-toggle"
        label={t('Email notifications')}
        onChange={onChange}
      />
    </InfoCard>
  );
}

export default EvsNotificationSettings;
