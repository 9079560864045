import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useFormBuilder from 'src/hooks/useFormBuilder';

function useRequestResetPasswordForm() {
  const { t } = useTranslation();

  const initialFormData = {
    email: '',
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .required(t('Field is required'))
      .email(t('Must be a valid email field')),
  }).required();

  return useFormBuilder(
    initialFormData,
    validationSchema,
    { focusField: 'email' },
  );
}

export default useRequestResetPasswordForm;
