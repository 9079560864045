import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, formatDistance } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DATETIME } from 'src/constants/date';
import colors from 'src/design/theme/colors';
import InfoCardVisual from 'src/design/ui-kit/InfoCardVisual/InfoCardVisual';

function ScanOverviewDetails(props) {
  const { scan } = props;

  const { t } = useTranslation();

  const getLi = (label, value) => (
    <li className="flex justify-between">
      <span className="od-font-secondary-regular text-od-white-500">
        {label}
      </span>
      <span className="od-font-secondary-semi-bold">
        {value}
      </span>
    </li>
  );

  const startDate = scan.startedAt ? new Date(scan.startedAt) : null;
  const endDate = new Date(scan.ranAt);
  const duration = startDate ? formatDistance(startDate, endDate) : null;

  return (
    <div className="ScanOverviewDetails grid grid-cols-4 xl:grid-cols-6 gap-4">
      <div className="col-span-4 xl:col-span-3 p-5 rounded-od-20 h-41 bg-od-black-700">
        <h4>
          {scan.owner.name}
        </h4>
        <ul className="grid grid-cols-2 gap-x-12 gap-y-3 mt-5">
          {getLi(t('Start Date'), startDate ? format(startDate, DATETIME) : '-')}
          {getLi(t('Targets'), scan.targetedHosts)}
          {getLi(t('End Date'), format(endDate, DATETIME))}
          {getLi(t('Live hosts'), scan.statistics.liveAssetsCount)}
          {getLi(t('Duration'), duration || '-')}
          {getLi(t('Dead hosts'), scan.statistics.deadAssetsCount)}
        </ul>
      </div>

      <InfoCardVisual
        className="col-span-2 xl:col-span-1"
        color={colors['od-status-box-new-bg']}
        description={t('New vulnerabilities')}
        Icon={(
          <FontAwesomeIcon
            icon={light('arrow-up-to-dotted-line')}
            size="xl"
          />
        )}
      >
        {scan.deltaStatistics.newCount.total}
      </InfoCardVisual>
      <InfoCardVisual
        className="col-span-2 xl:col-span-1"
        color={colors['od-complementary-medium']}
        description={t('Exploitable vulnerabilities')}
        Icon={(
          <FontAwesomeIcon icon={light('skull-crossbones')} size="xl" />
        )}
      >
        {scan.statistics.exploitCountPerLevel.total}
      </InfoCardVisual>
      <InfoCardVisual
        className="col-span-2 xl:col-span-1"
        color={colors['od-status-box-resolved-bg']}
        description={t('Resolved vulnerabilities')}
        Icon={(
          <FontAwesomeIcon
            className="rotate-180"
            icon={light('arrow-up-to-dotted-line')}
            size="xl"
          />
        )}
      >
        {scan.deltaStatistics.remediatedCount.total}
      </InfoCardVisual>
    </div>
  );
}

ScanOverviewDetails.propTypes = {
  scan: PropTypes.object.isRequired,
};

export default ScanOverviewDetails;
