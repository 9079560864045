import { gql } from '@apollo/client';

import FragmentEvsSubscription from './fragments/FragmentEvsSubscription';

export const EVS_SUBSCRIPTION_FOR_COMPANY = gql`
  ${FragmentEvsSubscription}

  query companyEvsSubscription ($companyId: ID!) {
    companyEvsSubscription(company: $companyId) {
      ...EVSSubscriptionNode
    }
  }
`;

export const EVS_SUBSCRIBE = gql`
  ${FragmentEvsSubscription}

  mutation (
    $company: ID!,
    $targetGroupName: String,
    $targetGroupTargets: [String!],
  ) {
    evsSubscribe(input: {
      company: $company
      targetGroupName: $targetGroupName
      targetGroupTargets: $targetGroupTargets
      runScan: false
    }) {
      subscription {
        ...EVSSubscriptionNode
      }
    }
  }
`;

export const EVS_UNSUBSCRIBE = gql`
  ${FragmentEvsSubscription}

  mutation ($company: ID!) {
    evsUnsubscribe(input: {
      company: $company
    }) {
      subscription {
        ...EVSSubscriptionNode
      }
    }
  }
`;

export const EVS_RESUME_SUBSCRIPTION = gql`
  ${FragmentEvsSubscription}

  mutation ($company: ID!) {
    evsResumeSubscription(input: {
      company: $company
    }) {
      subscription {
        ...EVSSubscriptionNode
      }
    }
  }
`;

export const EVS_PRICES = gql`
  query evsPrices ($customer: ID!) {
    evsPrices(customer: $customer) {
      basePrice
      targetPrice
    }
  }
`;
