import React, { createContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { MSP_BILLING_DETAILS } from 'src/api/billing';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import useUserParams from 'src/hooks/useUserParams';

// TODO: Cleanup this context
const emptyMSPBillingDetails = {
  companies: [],
  paymentSource: {
    ccLastDigits: null,
    email: '',
    isCardWorking: null,
  },
  refetch: () => {},
  totalCompanies: 0,
  totalMonthlyCost: 0,
};

export const MSPBillingContext = createContext({
  loading: true,
  mspBillingDetails: emptyMSPBillingDetails,
});

function mapMSPBillingDetails(data) {
  if (data && data.mspBillingDetails) {
    return {
      ...data.mspBillingDetails,
      paymentSource: {
        ...emptyMSPBillingDetails.paymentSource,
        ...data.mspBillingDetails.paymentSource,
      },
    };
  }
  return emptyMSPBillingDetails;
}

export function MSPBillingProvider(props) {
  const { children } = props;
  const { mspId } = useUserParams();
  const { isCompanyUser, permissions } = useCurrentUser();

  const variables = {
    id: mspId,
  };

  const { data, loading, refetch } = useQuery(MSP_BILLING_DETAILS, {
    skip: isCompanyUser || !permissions.canViewBillingDetails(),
    variables,
  });

  const mspBillingDetails = mapMSPBillingDetails(data);

  const value = useMemo(() => ({
    loading,
    mspBillingDetails,
    refetch,
  }), [loading, mspBillingDetails, refetch]);

  return (
    <MSPBillingContext.Provider value={value}>
      {children}
    </MSPBillingContext.Provider>
  );
}

MSPBillingProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useMSPBilling = () => React.useContext(MSPBillingContext);
