import { gql } from '@apollo/client';

const FragmentBciStatistics = gql`
  fragment BCStatistics on BCStatistics {
    breachCount
    recordCount
    credentialSetCount
    domainCount
    impactedUserCount
    domains {
      name
      addedAt
      breachCount
      recordCount
      credentialSetCount
      impactedUserCount
      recordsPerMonth {
        date
        records
      }
    }
  }
`;

export default FragmentBciStatistics;
