import React from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { SCAN_OVERVIEW_SCAN_QUERY } from 'src/api/evsScans/evsScans';
import { transformScan } from 'src/api/evsScans/evsScansHelpers';
import Page from 'src/components/common/Page';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
import EvsScanOverviewContent from 'src/views/EvsScanOverview/EvsScanOverviewContent';
import EvsScanHeader from 'src/views/EvsScanOverview/EvsScanOverviewHeader';
import PageNotFound from 'src/views/PageNotFound/PageNotFound';

function EvsScanOverview() {
  const { t } = useTranslation();
  const { scanId } = useUserParams();

  const showInfo = false;

  const excludeLevels = showInfo ? null : ['Info'];

  const { data: scanData, loading } = useQuery(SCAN_OVERVIEW_SCAN_QUERY, {
    variables: {
      excludeLevels,
      scanId,
    },
  });

  if (loading) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  if (!scanData) {
    return (
      <PageNotFound title={t('Scan not found')} />
    );
  }

  const scan = transformScan(scanData.scan);

  return (
    <Page className="EvsScanOverview" title={t('EVS | Scan overview')}>
      <EvsScanHeader activeTab="overview" />
      <EvsScanOverviewContent
        scan={scan}
        showInfo={false}
      />
    </Page>
  );
}

export default EvsScanOverview;
