import React from 'react';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { noop } from 'src/helpers/util';

const buttonVariants = ['primary', 'secondary', 'text', 'icon', 'iconPrimary', 'link', 'dangerous'];

export const buttonSizes = ['xs', 'sm', 'md'];

// TODO: Fix storybook styles & bg
function Button(props) {
  const {
    Icon = null,
    children = null,
    className = 'flex items-center gap-2',
    disabled = false,
    link = '',
    loading = false,
    onClick = noop(),
    size = 'md',
    title = '',
    type = 'button',
    variant = 'primary',
    visuallyDisabled = false,
  } = props;
  const classes = classNames(
    'Button border-0 outline-none focus:outline-none box-border od-font-button',
    `Button--${variant}`,
    `Button--${size}`,
    {
      'appearance-none border-0 outline-none focus:outline-none': variant === 'text'
        && !disabled
        && !visuallyDisabled
        && !loading,
      'appearance-none border-0 outline-none focus:outline-none p-2 hover:opacity-70': variant === 'icon'
        && !disabled && !visuallyDisabled,
      'opacity-40': disabled || visuallyDisabled || loading,
      'px-3 py-2 max-h-9 appearance-none border-0 focus:outline-none hover:opacity-70': variant === 'iconPrimary'
        && !disabled && !visuallyDisabled,
      'px-4 py-1 h-8': !['text', 'icon', 'iconPrimary'].includes(variant) && size === 'xs',
      'px-6 py-2 h-9': !['text', 'icon', 'iconPrimary'].includes(variant) && size === 'sm',
      'px-6 py-3 h-10': !['text', 'icon', 'iconPrimary'].includes(variant) && size === 'md',
      'rounded-od-10': !['text', 'icon'].includes(variant),
    },
    className,
  );

  if (link) {
    return (
      <NavLink
        className={classes}
        to={link}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <button
      className={classes}
      disabled={disabled || loading}
      onClick={onClick}
      title={title}
      type={type}
    >
      {loading ? (
        <FontAwesomeIcon
          icon={solid('spinner')}
          spinPulse
        />
      ) : Icon}
      {children}
    </button>
  );
}

Button.propTypes = {
  Icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  link: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(buttonSizes),
  title: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(buttonVariants),
  visuallyDisabled: PropTypes.bool,
};

export default Button;
