import React, { useState } from 'react';

import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { transformScan } from 'src/api/evsScans/evsScansHelpers';
import ScanPolarChart from 'src/components/charts/ScanPolarChart/ScanPolarChart';
import ScanStackedChart from 'src/components/charts/ScanStackedChart/ScanStackedChart';
import EvsScanStatus from 'src/components/tactics.evs/scans/EvsScanStatus';
import { DATETIME } from 'src/constants/date';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import colors from 'src/design/theme/colors';
import Button from 'src/design/ui-kit/Button/Button';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';
import InfoCardVisual from 'src/design/ui-kit/InfoCardVisual/InfoCardVisual';
import { formatDate } from 'src/helpers/date';
import useBreakpoint from 'src/hooks/useBreakpoint';
import useUserParams from 'src/hooks/useUserParams';

function CompanyDashboardVulnerabilityScanningGroup(props) {
  const { expandedInitialValue = false, targetGroup } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companyId } = useUserParams();
  const breakpoint = useBreakpoint();
  const { userConfig } = useCurrentUser();

  const [expanded, setExpanded] = useState(expandedInitialValue);

  // Last 10 scans
  const scans = [...targetGroup.scans];

  const hasScans = scans.length > 0;

  const lastScan = hasScans ? transformScan(scans[0]) : ({
    deltaStatistics: {
      newCount: {
        total: 0,
      },
      remediatedCount: {
        total: 0,
      },
    },
    statistics: {
      currentCount: 0,
    },
  });

  const hasScanComparison = scans.length >= 2;

  return (
    <div
      className={classNames(
        'CompanyDashboardVulnerabilityScanningGroup',
        'border border-od-black-100 rounded-tl-2xl rounded-tr-2xl',
        {
          'rounded-b-2xl': expanded,
        },
      )}
    >
      <div className="p-5">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <h4>{targetGroup.name}</h4>
            {targetGroup.isScanRunning && (
              <EvsScanStatus />
            )}
          </div>
          {
            hasScans && (
              <Button
                onClick={() => navigate(userConfig.buildUrl(`/evs-scans/${lastScan.id}`, `/${companyId}`))}
                size="sm"
              >
                {t('View Results')}
              </Button>
            )
          }
        </div>
        <ul className={classNames('mt-3 grid gap-3', {
          'lg:grid-cols-4': !hasScans,
          'lg:grid-cols-4 xl:grid-cols-5': hasScans,
        })}
        >
          <InfoCardVisual
            color={colors['od-status-box-previous-bg']}
            description={t('Previous')}
            Icon={(
              <FontAwesomeIcon
                className="rotate-90"
                icon={light('arrow-up-to-dotted-line')}
                size="xl"
              />
            )}
            tag="li"
          >
            {lastScan.statistics.currentCount
              + lastScan.deltaStatistics.remediatedCount.total
              - lastScan.deltaStatistics.newCount.total}
          </InfoCardVisual>
          <InfoCardVisual
            color={colors['od-status-box-new-bg']}
            description={t('New')}
            Icon={(
              <FontAwesomeIcon
                icon={light('arrow-up-to-dotted-line')}
                size="xl"
              />
            )}
            tag="li"
          >
            {lastScan.deltaStatistics.newCount.total}
          </InfoCardVisual>
          <InfoCardVisual
            color={colors['od-status-box-resolved-bg']}
            description={t('Resolved')}
            Icon={(
              <FontAwesomeIcon
                className="rotate-180"
                icon={light('arrow-up-to-dotted-line')}
                size="xl"
              />
            )}
            tag="li"
          >
            {lastScan.deltaStatistics.remediatedCount.total}
          </InfoCardVisual>
          <InfoCardVisual
            color={colors['od-status-box-current-bg']}
            description={t('Current')}
            Icon={(
              <FontAwesomeIcon
                icon={light('chart-scatter')}
                size="xl"
              />
            )}
            tag="li"
          >
            {lastScan.statistics.currentCount}
          </InfoCardVisual>

          {hasScans && !targetGroup.isScanRunning && (
            <InfoCard
              description={t('Last scan')}
              tag="li"
            >
              <time className="od-font-main-medium">
                {formatDate(lastScan.ranAt, DATETIME)}
              </time>
            </InfoCard>
          )}
          {hasScans && targetGroup.isScanRunning && (
          <li
            className="bg-od-black-300 rounded-od-20 grid
            gap-y-1 gap-x-3 items-center p-4 lg:col-span-4 xl:col-span-1 h-18"
          >
            <EvsScanStatus />
          </li>
          )}
        </ul>
      </div>
      {
        hasScans && (
          <div>
            <div className={classNames('border-t border-od-black-100 h-12 flex justify-center items-center', {
              'border-b': expanded,
            })}
            >
              <Button
                className="flex items-center gap-1.5"
                onClick={() => setExpanded(!expanded)}
                size="xs"
                variant="primary"
              >
                <FontAwesomeIcon
                  icon={solid('chart-pie')}
                  size="sm"
                />
                <span className="ml-1.5 mr-1">
                  {expanded ? t('Hide charts') : t('Show charts')}
                </span>
                <FontAwesomeIcon
                  className={classNames({
                    'transform rotate-180': expanded,
                  })}
                  icon={light('chevrons-down')}
                  size="sm"
                />

              </Button>
            </div>
            {
              expanded && (
                <section
                  className="grid"
                  style={{
                    ...breakpoint.isGreaterThan('lg') && {
                      gridTemplateColumns: '309px 1fr',
                    },
                    ...breakpoint.isGreaterThan('lg') && {
                      gridTemplateColumns: '390px 1fr',
                    },
                    ...breakpoint.isGreaterThan('lg') && {
                      gridTemplateColumns: '508px 1fr',
                    },
                    height: '498px',
                  }}
                >
                  {hasScanComparison && (
                    <ScanPolarChart
                      className="border-r border-od-black-100"
                      scans={scans}
                      showInfo={false}
                      title={t('Comparison to last scan')}
                    />
                  )}
                  <ScanStackedChart
                    scans={scans}
                    showInfo={false}
                    title={t('Over time')}
                  />
                </section>
              )
            }
          </div>
        )
      }
    </div>
  );
}

CompanyDashboardVulnerabilityScanningGroup.propTypes = {
  expandedInitialValue: PropTypes.bool,
  targetGroup: PropTypes.object.isRequired,
};

export default CompanyDashboardVulnerabilityScanningGroup;
