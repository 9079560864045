import React, { useState, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { debounce } from 'radash';
import Slider from 'react-slider';

import { UPDATE_COMPANY_MUTATION, COMPANY_QUERY } from 'src/api/companies';
import { EVS_SEVERITY_SCORE_MIN, EVS_SEVERITY_SCORE_MAX, EVS_SEVERITY_SCORE_STEP,
  EvsSeverity } from 'src/constants/evs';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import { hasDuplicates } from 'src/helpers/array';
import { useScanVulnerabilitySeverityDefinitions } from 'src/helpers/scan';
import { noop } from 'src/helpers/util';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function EvsSeverityScoresMapper() {
  const severityDefinitions = useScanVulnerabilitySeverityDefinitions({ showInfo: true }).reverse();
  const { mspId } = useUserParams();
  const { showSuccessMessage } = useToast();

  // Initialize the dots with default positions
  const { company, severityScores } = useCurrentCompany();
  const [positions, setPositions] = useState([
    severityScores.info,
    severityScores.low,
    severityScores.medium,
    severityScores.high,
    severityScores.critical || EVS_SEVERITY_SCORE_MAX,
  ]);

  const [updateCompany, { loading: saving }] = useMutation(
    UPDATE_COMPANY_MUTATION,
    {
      awaitRefetchQueries: true,
      onCompleted(response) {
        if (response.updateCompany) {
          showSuccessMessage('Company severity scores updated successfully');
        } else {
          showSuccessMessage('Failed to update company severity scores');
        }
      },
      refetchQueries: [COMPANY_QUERY],
    },
  );

  // Debounced API call
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallApi = useCallback(
    debounce({ delay: 1000 }, (scores) => updateCompany({
      variables: {
        enabled: company.enabled,
        id: company.id,
        manager: mspId,
        name: company.name,
        severityScores: {
          [EvsSeverity.Info]: scores[0],
          [EvsSeverity.Low]: scores[1],
          [EvsSeverity.Medium]: scores[2],
          [EvsSeverity.High]: scores[3],
        },
      },
    })),
    [],
  );

  const handleChange = (newPositions) => {
    // Ensure dots do not intersect
    const sortedPositions = [...newPositions].sort((a, b) => a - b);
    if (hasDuplicates(sortedPositions)) {
      return;
    }
    // Make sure the last dot cannot be moved
    const updatedPositions = [
      ...sortedPositions.slice(0, severityDefinitions.length - 1),
      EVS_SEVERITY_SCORE_MAX,
    ];
    setPositions(updatedPositions);
    debouncedCallApi(sortedPositions);
  };

  const renderThumb = ({ key, ...props }, state) => {
    const severityDefinition = severityDefinitions[state.index];

    // Disable the last dot for Critical severity
    const _props = {
      ...props,
      ...severityDefinition.key === EvsSeverity.Critical && {
        'aria-disabled': severityDefinition.key === EvsSeverity.Critical,
        onMouseDown: noop,
        onTouchStart: noop,
      },
    };

    return (
      <div
        {..._props}
        key={key}
        className="w-5 h-5 rounded-full flex items-center justify-center cursor-pointer -top-2"
        style={{
          // eslint-disable-next-line react/prop-types
          ..._props.style,
          backgroundColor: severityDefinition.color,
        }}
        title={severityDefinition.name}
      >
        <Tooltip
          popoverProps={{ isOpen: true, padding: 12 }}
          text={severityDefinition.name}
        >
          <div className="w-full h-full" />
        </Tooltip>
      </div>
    );
  };

  const renderTrack = ({ key, ...props }, state) => {
    const severityDefinition = severityDefinitions[state.index];

    return (
      <div
        {...props}
        key={key}
        className="h-1 bg-od-black-100 rounded"
        style={{
          // eslint-disable-next-line react/prop-types
          ...props.style,
          ...severityDefinition && {
            backgroundColor: severityDefinition.color,
          },
        }}
      />
    );
  };

  // TODO: Consider moving this function to a helper and add unit tests
  const getRange = (index) => {
    const from = index === 0 ? EVS_SEVERITY_SCORE_MIN : positions[index - 1] + EVS_SEVERITY_SCORE_STEP;
    const to = index === positions.length - 1 ? EVS_SEVERITY_SCORE_MAX : positions[index];

    // Round the numbers to one decimal place
    const roundedFrom = parseFloat(from.toFixed(1));
    const roundedTo = parseFloat(to.toFixed(1));

    if (roundedTo === 0) {
      return '-';
    }

    if (roundedFrom === roundedTo) {
      return roundedFrom;
    }

    return `${roundedFrom} - ${roundedTo}`;
  };

  return (
    <div className="EvsSeverityScoresSlider w-full py-3 flex items-center gap-5 pr-6">
      <ul className="bg-od-black-700 rounded-xl px-4 py-2 grid gap-2 min-w-[180px]">
        {severityDefinitions.map((severityDefinition, index) => (
          <li
            key={severityDefinition.key}
            className="flex items-center gap-2 text-sm"
          >
            <div
              className="w-3.5 h-3.5 rounded"
              style={{ backgroundColor: severityDefinition.color }}
            />
            <span>
              {severityDefinition.name}
              :
            </span>
            <span>{getRange(index)}</span>
          </li>
        ))}
      </ul>

      <Slider
        className="relative w-full h-1 bg-od-black-100 rounded"
        disabled={saving}
        max={EVS_SEVERITY_SCORE_MAX}
        min={EVS_SEVERITY_SCORE_MIN}
        onChange={handleChange}
        pearling={false}
        renderThumb={renderThumb}
        renderTrack={renderTrack}
        snapDragDisabled
        step={EVS_SEVERITY_SCORE_STEP}
        thumbClassName="absolute top-0"
        trackClassName="hidden"
        value={positions}
      />
    </div>
  );
}

export default EvsSeverityScoresMapper;
