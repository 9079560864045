import React, { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import PolarChart from 'src/components/charts/ScanPolarChart/PolarChart';
import ScanSelect from 'src/components/charts/ScanPolarChart/ScanSelect';
import { useScanVulnerabilitySeverityDefinitions } from 'src/helpers/scan';

function useChartData({ currentScan, lastScan, showInfo }) {
  const categories = [];
  const colors = [];
  const data = [[], []];

  const vulnerabilitySeverityDefinitions = useScanVulnerabilitySeverityDefinitions({ showInfo });

  vulnerabilitySeverityDefinitions.forEach(({ color, key, name }) => {
    colors.push(color);
    categories.push(name);
    data[0].push(lastScan.statistics.vulnCountPerLevel[key]);
    data[1].push(currentScan.statistics.vulnCountPerLevel[key]);
  });

  return {
    categories,
    colors,
    data,
  };
}

function ScanPolarChart(props) {
  const {
    className = '',
    scans,
    showInfo,
    style = {},
    title,
  } = props;
  const [currentScan, setCurrentScan] = useState(scans[1]);
  const lastScan = scans[0];

  const chartData = useChartData({
    currentScan,
    lastScan,
    showInfo,
  });

  return (
    <div
      className={classNames('ScanStackedChart p-6', className)}
      style={style}
    >
      <div className="od-font-title-semi-bold flex justify-between items-center">
        <p>{title}</p>

        <ScanSelect
          onChange={setCurrentScan}
          scans={scans.slice(1)}
          selectedScan={currentScan}
        />
      </div>
      <PolarChart chartData={chartData} showInfo={showInfo} />
    </div>
  );
}

ScanPolarChart.propTypes = {
  className: PropTypes.string,
  scans: PropTypes.array.isRequired,
  showInfo: PropTypes.bool.isRequired,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default ScanPolarChart;
