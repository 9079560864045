import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

export class NavItem {
  constructor({ action, key, name }) {
    this.action = action;
    this.key = key;
    this.name = name;
  }
}

const variants = ['primary', 'secondary'];

function Nav(props) {
  const {
    active,
    className = '',
    navItems,
    variant = variants[0],
  } = props;

  return (
    <nav className={classNames('Nav flex items-center rounded-od-10 overflow-hidden', className)}>
      {navItems.map((item, index) => (
        <button
          key={item.key}
          className={classNames('Nav__Button justify-center', {
            'bg-od-action': active === item.key && variant === 'primary',
            'bg-od-black-100': active === item.key && variant === 'secondary',
            'h-10 text-od-white-900 od-font-secondary-regular ': variant === 'secondary',
            'h-11 od-font-secondary-medium px-16': variant === 'primary',
            'rounded-tl-od-10 rounded-bl-od-10': index === 0,
            'rounded-tr-od-10 rounded-br-od-10': index === navItems.length - 1,
            'text-od-white-500': variant === 'primary' && active !== item.key,
            'text-od-white-500 od-font-secondary-regular border border-od-black-100': active !== item.key,
          })}
          onClick={item.action}
        >
          {item.name}
        </button>
      ))}
    </nav>
  );
}

Nav.propTypes = {
  active: PropTypes.string.isRequired,
  className: PropTypes.string,
  navItems: PropTypes.arrayOf(PropTypes.instanceOf(NavItem)).isRequired,
  variant: PropTypes.oneOf(variants),
};

export default Nav;
