import i18n from 'i18next';

import { sortByDate } from 'src/helpers/sort';
import { TableColumnDefinition, TableSortState } from 'src/hooks/useTable';

const usePerDomainAnalyticsColumns = () => [
  new TableColumnDefinition({
    name: 'domain',
    title: i18n.t('Domain'),
  }),
  new TableColumnDefinition({
    name: 'found',
    title: i18n.t('Found'),
  }),
  new TableColumnDefinition({
    name: 'breaches',
    title: i18n.t('Breaches'),
  }),
  new TableColumnDefinition({
    name: 'users',
    title: i18n.t('Users'),
  }),
  new TableColumnDefinition({
    name: 'sets',
    title: i18n.t('Sets'),
  }),
  new TableColumnDefinition({
    justifyContent: 'end',
    name: 'addedAt',
    sortConfig: new TableSortState(
      'addedAt',
      'desc',
      sortByDate((domainBCStatistics) => domainBCStatistics.addedAt),
    ),
    sortable: true,
    title: i18n.t('Monitoring Started At'),
  }),
];

export default usePerDomainAnalyticsColumns;
