import React from 'react';

import { useTranslation } from 'react-i18next';

import { useMSPBilling } from 'src/contexts/useMSPBilling';
import FieldCost from 'src/design/ui-kit/Field/FieldCost/FieldCost';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';
import ChangeBillingEmailButton from 'src/views/Billing/ChangeBillingEmail/ChangeBillingEmailButton';
import ChangePaymentMethodButton from 'src/views/Billing/ChangePaymentMethod/ChangePaymentMethodButton';

function BillingDetails() {
  const { t } = useTranslation();

  const { mspBillingDetails } = useMSPBilling();

  return (
    <section className="grid lg:grid-cols-2 xl:grid-cols-6 gap-4">
      <InfoCard description={t('Total active companies')}>
        {mspBillingDetails.activeCompanies}
      </InfoCard>
      <InfoCard description={t('Total monthly cost')}>
        <FieldCost value={mspBillingDetails.totalMonthlyCost || 0} />
      </InfoCard>
      <InfoCard
        className="xl:col-span-2"
        description={t('Invoice sent to')}
      >
        <div className="flex items-center justify-between">
          {mspBillingDetails.paymentSource.email}
          <ChangeBillingEmailButton email={mspBillingDetails.paymentSource.email} />
        </div>
      </InfoCard>
      <InfoCard
        className="xl:col-span-2"
        description={t('Payment method')}
      >
        <div className="flex items-center justify-between">
          {mspBillingDetails.paymentSource.ccLastDigits
            && (
              <span>
                {`**** **** **** ${mspBillingDetails.paymentSource.ccLastDigits}`}
              </span>
            )}

          <ChangePaymentMethodButton
            isCardWorking={mspBillingDetails.paymentSource.isCardWorking}
          />
        </div>
      </InfoCard>
    </section>
  );
}

export default BillingDetails;
