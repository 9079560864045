import React from 'react';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { RUN_SCAN_MUTATION } from 'src/api/evsScans/evsScans';
import FormControl from 'src/components/common/FormControl';
import Modal from 'src/components/modals/Modal';
import EvsTargetGroupSelect, { buildOption } from 'src/components/tactics.evs/targetGroups/EvsTargetGroupSelect';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import Button from 'src/design/ui-kit/Button/Button';
import useToast from 'src/hooks/useToast';

// TODO: Move to a dedicated form file inside the same folder
const schema = () => yup.object({
  targetGroup: yup
    .object()
    .required(i18n.t('Field is required')),
}).required();

const initialFormData = (initialTargetGroup) => ({
  targetGroup: initialTargetGroup ? [buildOption(initialTargetGroup)] : [],
});

function EvsRunScanModal(props) {
  const { initialTargetGroup = null, isOpen, onClose } = props;
  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();
  const { tactics: { evs: { refetchQueries } } } = useCurrentCompany();

  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: initialFormData(initialTargetGroup),
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const [runScan, { loading: saving }] = useMutation(
    RUN_SCAN_MUTATION,
    {
      onCompleted(response) {
        if (response.runScan) {
          showSuccessMessage(t('Scan successfully started'));
          reset();
          onClose();
        }
      },
      refetchQueries,
    },
  );

  const onSubmit = async (submitData, event) => {
    event.preventDefault();
    await runScan({
      variables: {
        targetGroup: submitData.targetGroup.id,
      },
    });
  };

  return (
    <Modal
      className="EvsScanModal"
      isOpen={isOpen}
      onClose={onClose}
      shouldFocusFirstFocusable
      subtitle={t('Please select one or more target groups below')}
      title={t('Run Scan')}
    >
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <FormControl
            htmlFor="targetGroup"
            label={t('Target group')}
          >
            <Controller
              control={control}
              id="targetGroup"
              name="targetGroup"
              render={({ field: { onChange, ref, value } }) => (
                <EvsTargetGroupSelect
                  inputRef={ref}
                  isSearchable={false}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </FormControl>
        </div>
        <div className="mt-10 flex justify-end">
          <Button
            disabled={!formState.isValid || saving}
            type="submit"
          >
            {t('Run')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

EvsRunScanModal.propTypes = {
  initialTargetGroup: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EvsRunScanModal;
