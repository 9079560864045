import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { EVS_SCAN_EXPORT_CSV, EVS_SCAN_GENERATE_REPORT } from 'src/api/evsScans/evsScans';
import ReportsModal from 'src/components/modals/ReportsModal';
import Button from 'src/design/ui-kit/Button/Button';
import { openInNewTab } from 'src/helpers/url';
import useToast from 'src/hooks/useToast';

function EvsReportsButton(props) {
  const { scanId } = props;
  const { t } = useTranslation();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const { showSuccessMessage } = useToast();

  const onCompleted = () => {
    showSuccessMessage(t('Scan exported successfully'));
    setIsExportModalOpen(false);
  };
  const [exportCSV, { loading: isExportingCsv }] = useMutation(
    EVS_SCAN_EXPORT_CSV,
    {
      onCompleted(response) {
        if (response.exportScanCsv) {
          openInNewTab(response.xportScanCsv.url);
          onCompleted();
        }
      },
    },
  );

  const [generateReport, { loading: isGenerationReport }] = useMutation(
    EVS_SCAN_GENERATE_REPORT,
    {
      onCompleted(response) {
        if (response.generateReport) {
          if (response.generateReport.report.pdf) {
            openInNewTab(response.generateReport.report.pdf);
          } else if (response.generateReport.report.docx) {
            openInNewTab(response.generateReport.report.docx);
          }
          onCompleted();
        }
      },
    },
  );

  const saving = isExportingCsv || isGenerationReport;

  const onExport = async (format) => {
    if (format === 'csv') {
      await exportCSV({
        variables: {
          scan: scanId,
        },
      });
      return;
    }
    await generateReport({
      variables: {
        scan: scanId,
      },
    });
  };

  return (
    <>
      <Button
        className="EvsExportScanButton gap-2 flex items-center"
        Icon={<FontAwesomeIcon icon={light('cloud-arrow-down')} />}
        onClick={() => setIsExportModalOpen(true)}
      >
        {t('Reports')}
      </Button>
      <ReportsModal
        isOpen={isExportModalOpen}
        loading={saving}
        onClose={() => setIsExportModalOpen(false)}
        onExport={onExport}
        supportedFormats={['csv', 'pdf', 'docx']}
      />
    </>
  );
}

EvsReportsButton.propTypes = {
  scanId: PropTypes.string.isRequired,
};

export default EvsReportsButton;
