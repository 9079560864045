import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Badge from 'src/design/ui-kit/Badge/Badge';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';

function EvsNoScanDataBadge(props) {
  const { companyId } = props;
  const { userConfig } = useCurrentUser();
  const { t } = useTranslation();

  return (
    <Tooltip text={t('Click to view tactic settings')}>
      <NavLink to={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}>
        <Badge>{t('No scan data')}</Badge>
      </NavLink>
    </Tooltip>
  );
}

EvsNoScanDataBadge.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default EvsNoScanDataBadge;
