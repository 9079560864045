import React from 'react';

import { useQuery } from '@apollo/client';
import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { EVS_SCAN_VULNERABILITIES_LIST_BY_STATUS_QUERY,
  EVS_SCAN_VULNERABILITIES_LIST_CURRENT_QUERY } from 'src/api/evsScans/evsScans';
import { VulnerabilityStatus } from 'src/constants/evs';
import IconExpanded from 'src/design/custom-icons/IconExpanded/IconExpanded';
import Loading from 'src/design/ui-kit/Loading/Loading';
import VulnerabilityRow
  from 'src/views/EvsScanVulnerabilities/VulnerabilityRow/VulnerabilityRow';

function useScanVulnerabilitiesList({ scanId, severity, status }) {
  const isCurrent = status === VulnerabilityStatus.CURRENT;
  const query = isCurrent
    ? EVS_SCAN_VULNERABILITIES_LIST_CURRENT_QUERY
    : EVS_SCAN_VULNERABILITIES_LIST_BY_STATUS_QUERY;

  const queryResponse = useQuery(
    query,
    {
      variables: {
        scanId,
        severity,
        status,
      },
    },
  );

  let { data } = queryResponse;

  if (data) {
    data = data?.scan?.deltaStatistics;
    if (isCurrent) {
      data = data?.currentVulnerabilities;
    } else {
      data = data?.vulnerabilitiesByStatus;
    }

    data = data?.edges?.map((e) => e.node);
  }

  return {
    ...queryResponse,
    data,
  };
}

function VulnerabilityRowList(props) {
  const { open, scanId, severity, status, vulnerabilityType } = props;

  const {
    data: vulnerabilities,
    error,
    loading,
  } = useScanVulnerabilitiesList({ scanId, severity, status });

  if (!open) {
    return null;
  }

  if (loading || error) {
    return <Loading size="sm" />;
  }

  return vulnerabilities.map((vulnerability, index) => (
    <VulnerabilityRow
      key={index}
      index={index}
      scanId={scanId}
      vulnerability={vulnerability}
      vulnerabilityType={vulnerabilityType}
    />
  ));
}

VulnerabilityRowList.propTypes = {
  open: PropTypes.bool.isRequired,
  scanId: PropTypes.string.isRequired,
  severity: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  vulnerabilityType: PropTypes.object.isRequired,
};

function EvsVulnerabilitySeverityRow(props) {
  const { counts, scan, status, vulnerabilitySeverityDefinition } = props;

  const { t } = useTranslation();

  const count = counts[vulnerabilitySeverityDefinition.key];

  const hasVulnerabilities = count > 0;

  return (
    <div className="EvsVulnerabilitySeverityRow">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                'px-5 py-3 bg-od-black-300 h-12 flex gap-2 items-center w-full',
                'od-font-secondary-medium rounded-tl-od-8 rounded-tr-od-8',
                {
                  'rounded-bl-od-8 rounded-br-od-8': !open,
                },
              )}
              disabled={!hasVulnerabilities}
            >
              {hasVulnerabilities && <IconExpanded expanded={open} />}
              <div
                className="h-3.5 w-3.5 rounded"
                style={{ background: vulnerabilitySeverityDefinition.color }}
              />
              {vulnerabilitySeverityDefinition.name}
              <div className="h-1 w-1 rounded-full bg-od-white-300" />
              <span className="text-od-white-500">
                {t('{{count}} vulnerabilities', { count })}
              </span>
            </Disclosure.Button>
            <Transition show={open}>
              <Disclosure.Panel static>
                <div className="rounded-bl-od-8 rounded-br-od-8 border-b border-l border-r border-od-black-300">
                  <VulnerabilityRowList
                    open={open}
                    scanId={scan.id}
                    severity={vulnerabilitySeverityDefinition.severity}
                    status={status}
                    vulnerabilityType={vulnerabilitySeverityDefinition}
                  />
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}

EvsVulnerabilitySeverityRow.propTypes = {
  counts: PropTypes.object.isRequired,
  scan: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  vulnerabilitySeverityDefinition: PropTypes.object.isRequired,
};

export default EvsVulnerabilitySeverityRow;
