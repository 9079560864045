export function isMSPDashboardEnabled() {
  return process.env.REACT_APP_DEV_ENABLED_MSP_DASHBOARD === 'true';
}

export function isMSPSettingsEnabled() {
  return process.env.REACT_APP_DEV_ENABLED_MSP_SETTINGS === 'true';
}

export function isBCIEnabled() {
  return process.env.REACT_APP_DEV_ENABLED_BCI === 'true';
}

function useFeatures() {
  return {
    isBCIEnabled,
    isMSPDashboardEnabled,
    isMSPSettingsEnabled,
  };
}

export default useFeatures;
