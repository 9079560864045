import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormControl from 'src/components/common/FormControl';
import PasswordValidation from 'src/components/common/PasswordValidation/PasswordValidation';
import { ReactComponent as LogoSvg } from 'src/design/images/logo.svg';
import Button from 'src/design/ui-kit/Button/Button';
import InputPassword from 'src/design/ui-kit/InputPassword/InputPassword';

import useResetPasswordForm from './useResetPasswordForm';

function ResetPasswordForm(props) {
  const { loading, onSubmit } = props;
  const { t } = useTranslation();

  const {
    formBuilder: {
      form: { formState, handleSubmit, register, watch },
      helpers: { getFieldError, isFieldTouched, isFieldValidByRule },
    },
    validationsMap,
  } = useResetPasswordForm();

  const { password } = watch();

  const onSubmitWrapper = (formData, event) => {
    event.preventDefault();

    if (!formState.isValid) {
      return;
    }

    onSubmit(formData);
  };

  const showPasswordValidation = isFieldTouched('password') || getFieldError('password') || !!password;

  return (
    <form
      className="ResetPasswordForm"
      noValidate
      onSubmit={handleSubmit(onSubmitWrapper)}
      style={{
        width: '420px',
      }}
    >
      <div className="flex justify-center">
        <LogoSvg />
      </div>

      <h1 className="text-center mt-6">{t('Set new password')}</h1>
      <div className="mt-6 flex flex-col gap-4">
        <FormControl
          htmlFor="password"
          label={t('Password')}
        >
          <InputPassword
            error={getFieldError('password')}
            name="password"
            placeholder={t('Enter password')}
            register={register}
          />

          {showPasswordValidation && (
          <PasswordValidation
            isFieldValidByRule={isFieldValidByRule}
            validationsMap={validationsMap}
          />
          )}
        </FormControl>
      </div>
      <div className="mt-8">
        <Button
          className="w-full"
          disabled={loading}
          Icon={<FontAwesomeIcon icon={light('floppy-disk')} />}
          type="submit"
          visuallyDisabled={!formState.isValid}
        >
          {t('Save')}
        </Button>
      </div>
    </form>
  );
}

ResetPasswordForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
