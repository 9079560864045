import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useMSPBilling } from 'src/contexts/useMSPBilling';
import Button from 'src/design/ui-kit/Button/Button';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';
import { formatDate } from 'src/helpers/date';
import { formatPrice, useConvertCentValue } from 'src/helpers/price';

function BillingInvoicesDetails() {
  const navigate = useNavigate();
  const { mspBillingDetails } = useMSPBilling();
  const { t } = useTranslation();
  const { convertFromCentValue } = useConvertCentValue(2);

  const { lastInvoice, thisMonthTotalInvoices, upcomingInvoice } = mspBillingDetails;

  return (
    <section>
      <h2 className="text-base mt-6">{t('Billing invoices')}</h2>

      <div className="flex gap-4 mt-4">
        {lastInvoice && (
          <InfoCard
            className="flex-1"
            description={t('Last invoice')}
          >
            {t('{{total}} on {{date}}', {
              date: formatDate(lastInvoice.date),
              total: formatPrice(convertFromCentValue(lastInvoice.total)),
            })}
          </InfoCard>
        )}

        <InfoCard
          className="flex-1"
          description={t('Total invoices this month')}
        >
          {formatPrice(convertFromCentValue(thisMonthTotalInvoices))}
        </InfoCard>

        {lastInvoice && (
          <InfoCard
            className="flex-1"
            description={t('Upcoming invoice')}
          >
            {t('{{total}} on {{date}}', {
              date: formatDate(upcomingInvoice.date),
              total: formatPrice(convertFromCentValue(upcomingInvoice.total)),
            })}
          </InfoCard>
        )}

        {thisMonthTotalInvoices > 0 && (
          <InfoCard
            className="flex-1"
            description={t('All invoices')}
          >
            <Button
              Icon={<FontAwesomeIcon icon={light('magnifying-glass')} />}
              onClick={() => navigate('/billing/invoices')}
              size="sm"
              variant="secondary"
            >
              {t('View')}
            </Button>
          </InfoCard>
        )}
      </div>
    </section>
  );
}

export default BillingInvoicesDetails;
