import { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { EVS_PRICES, EVS_SUBSCRIPTION_FOR_COMPANY } from 'src/api/evsSubscription';
import { EVS_TARGET_GROUPS, transformResponse } from 'src/api/evsTargetGroups';
import { EVS_TARGETS } from 'src/api/evsTargets';
import { SubscriptionStatus } from 'src/constants/subscription';

// Move target groups away from here
const first = 100;
const scansFirst = 10;

const FETCH_SCANS_INTERVAL = 60 * 1000; // 1 min

export const evsTacticInitialData = {
  /**
     * Evs subscription data and stats for the current company
     */
  companyEvsSubscription: null,
  /**
   * Evs tactic data error
   */
  error: null,
  /**
   * Evs subscription status
   */
  evsSubscriptionStatus: SubscriptionStatus.INACTIVE,
  /**
   * TODO: Move this out and add pagination
   */
  evsTargetGroups: [],
  /**
   * Indicator if a scan is running
   */
  isScanRunning: false,
  /**
   * List of Queries to refetch after evs mutations
   */
  /**
   * Loading state for evs tactic data queries
   */
  loading: true,
  /**
   * List of evs queries to refetch after mutations
   */
  refetchQueries: [],
};

/**
 * @description
 * Grouped data from evs tactic queries
 * @param companyId
 * @param mspId
 */
export default function useEvsTacticData({ companyId }) {
  const defaultVariables = {
    companyId,
  };
  const targetGroupsVariables = {
    ...defaultVariables,
    first,
    scansFirst,
  };

  const companyEvsSubscriptionQuery = useQuery(EVS_SUBSCRIPTION_FOR_COMPANY, { variables: defaultVariables });
  // Move target groups away from here
  const evsTargetGroupsQuery = useQuery(EVS_TARGET_GROUPS, { variables: targetGroupsVariables });

  const { items: evsTargetGroups } = transformResponse(evsTargetGroupsQuery.data);
  const { companyEvsSubscription } = companyEvsSubscriptionQuery.data || { companyEvsSubscription: null };

  const error = companyEvsSubscriptionQuery.error
    || evsTargetGroupsQuery.error;
  const loading = companyEvsSubscriptionQuery.loading
    || evsTargetGroupsQuery.loading;

  const refetch = async () => {
    await Promise.all([
      companyEvsSubscriptionQuery.refetch(defaultVariables),
      evsTargetGroupsQuery.refetch(targetGroupsVariables),
    ].filter(Boolean));
  };

  /**
   * List of queries to refetch after mutations
   */
  const refetchQueries = [
    EVS_SUBSCRIPTION_FOR_COMPANY,
    EVS_TARGET_GROUPS,
    EVS_PRICES,
    EVS_TARGETS,
  ];

  const isScanRunning = companyEvsSubscription?.isScanRunning ?? false;
  const evsSubscriptionStatus = companyEvsSubscription?.status ?? SubscriptionStatus.INACTIVE;

  useEffect(() => {
    let interval = null;
    if (isScanRunning) {
      interval = setInterval(refetch, FETCH_SCANS_INTERVAL);
    } else {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScanRunning]);

  return {
    companyEvsSubscription,
    error,
    evsSubscriptionStatus,
    evsTargetGroups,
    isScanRunning,
    loading,
    refetchQueries,
  };
}
