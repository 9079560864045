import React, { useRef } from 'react';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { UPDATE_COMPANY_MUTATION, CREATE_COMPANY_MUTATION, COMPANIES_QUERY } from 'src/api/companies';
import FormControl from 'src/components/common/FormControl';
import Modal from 'src/components/modals/Modal';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import highlightElement from 'src/helpers/highlightElement';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';
import useValidationHelpers from 'src/hooks/useValidationHelpers';

const schema = () => yup.object({
  company: yup
    .string()
    .required(i18n.t('Field is required')),
}).required();

const initialFormData = (company) => (company ? ({
  company: company.name,
}) : ({
  company: '',
}));

function CompanyModal(props) {
  const { company = null, isOpen, onClose } = props;
  const { t } = useTranslation();
  const { mspId } = useUserParams();
  const formRef = useRef();
  const { showSuccessMessage } = useToast();

  const isEditMode = !!company;

  const { formState, handleSubmit, register, reset, watch } = useForm({
    defaultValues: initialFormData(company),
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const formData = watch();

  const handleClose = () => {
    onClose();
    reset();
  };

  const { getFieldError } = useValidationHelpers({
    formState,
  });

  const [saveCompany, { loading: saving }] = useMutation(
    isEditMode ? UPDATE_COMPANY_MUTATION : CREATE_COMPANY_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [COMPANIES_QUERY],
    },
  );

  const variables = company ? {
    enabled: company.enabled,
    id: company.id,
    manager: mspId,
    name: formData.company,
  } : {
    enabled: true,
    manager: mspId,
    name: formData.company,
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();

    try {
      const response = await saveCompany({ variables });
      const { createCompany, updateCompany } = response.data;
      if (isEditMode && updateCompany) {
        showSuccessMessage('Company successfully updated');
        handleClose();
      } else if (createCompany) {
        showSuccessMessage('Company successfully added');
        setTimeout(() => highlightElement(`TableRow${createCompany.company.id}`));
        handleClose();
      }
    } catch (e) {
      // ignore this error, it's handled globally
    }
  };

  // archive or restore
  const onChangeEnabledStatus = async () => {
    try {
      const newEnabled = !company.enabled;
      const response = await saveCompany({ variables: {
        ...variables,
        enabled: newEnabled,
      } });
      const { updateCompany } = response.data;
      if (updateCompany) {
        handleClose();
        if (newEnabled === true) {
          showSuccessMessage('Company successfully restored');
        } else {
          showSuccessMessage('Company successfully archived');
        }
      }
    } catch (e) {
      // ignore this error, it's handled globally
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      shouldFocusFirstFocusable
      subtitle={t('Enter the name of the company below:')}
      title={company ? t('Edit company') : t('Add company')}
    >
      <form
        ref={formRef}
        className="mt-12"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl
          error={getFieldError('company')}
          htmlFor="CompanyModal__company"
          label={t('Company name')}
        >
          <Input
            error={getFieldError('company')}
            id="CompanyModal__company"
            name="company"
            placeholder={t('Company name')}
            register={register}
          />
        </FormControl>
        <div className="flex justify-end mt-10 gap-4">
          {
            isEditMode && (
              <Button
                loading={saving}
                onClick={onChangeEnabledStatus}
                variant={company.enabled ? 'dangerous' : 'secondary'}
              >
                {company.enabled ? t('Archive') : t('Restore')}
              </Button>
            )
          }
          <Button
            disabled={!formState.isValid || saving}
            type="submit"
          >
            {t('Continue')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

CompanyModal.propTypes = {
  company: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyModal;
