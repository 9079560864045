import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import SubscriptionIndicator from 'src/components/common/SubscriptionIndicator';
import { isSubscriptionActive, isSubscriptionExpiring } from 'src/constants/subscription';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import IconVulnerability from 'src/design/custom-icons/IconVulnerability/IconVulnerability';
import Button from 'src/design/ui-kit/Button/Button';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
import EvsTacticActionButton from 'src/views/TacticsSettings/EvsTactic/EvsTacticActionButton';

function EVSTactic() {
  const { t } = useTranslation();
  const {
    tactics: {
      evs: {
        companyEvsSubscription,
        error,
        evsSubscriptionStatus,
        loading,
      },
    },
  } = useCurrentCompany();
  const { permissions, userConfig } = useCurrentUser();
  const { companyId } = useUserParams();
  const navigate = useNavigate();

  if (error || loading) {
    return <Loading />;
  }

  const getSubscribeButton = () => {
    if (!permissions.canManageEvsSubscription()) {
      return null;
    }
    return (
      <EvsTacticActionButton />
    );
  };

  const editUrl = userConfig.buildUrl('/tactics-settings/evs', `/${companyId}`);

  const canManage = isSubscriptionActive(evsSubscriptionStatus) || isSubscriptionExpiring(evsSubscriptionStatus);
  const manageButtonVariant = isSubscriptionActive(evsSubscriptionStatus) ? 'primary' : 'secondary';

  return (
    <div
      className="
        EvcTactic p-5 rounded-lg
        grid gap-3 grid-cols-[40px_max-content_1fr] grid-rows-[max-content_max-content]
        bg-gradient-to-r from-od-yellow/[0.08] to-od-black-500
      "
    >
      <div className="row-span-2">
        <IconVulnerability />
      </div>

      {canManage ? (
        <NavLink
          className="od-font-main-medium"
          disabled={!canManage}
          to={editUrl}
        >
          {t('External Vulnerability Scanner')}
        </NavLink>
      ) : (
        <span className="od-font-main-medium">{t('External Vulnerability Scanner')}</span>
      )}

      <div className="place-self-end">
        <SubscriptionIndicator
          expiresAt={companyEvsSubscription?.expiresAt}
          status={evsSubscriptionStatus}
        />
      </div>

      <div className="od-font-secondary-regular text-od-white-500 max-w-lg">
        {t('It is a tactic for determining if servers can be exploited.'
          + " It works at network protocol level to determine what's vulnerable & how can be fixed.")}
      </div>

      <div className="place-self-end flex items-center gap-2">
        {canManage && (
          <Button
            className="place-self-end flex gap-2 items-center h-9"
            Icon={<FontAwesomeIcon icon={light('wrench')} />}
            onClick={() => navigate(editUrl)}
            size="sm"
            variant={manageButtonVariant}
          >
            {t('Manage')}
          </Button>
        )}
        {getSubscribeButton()}
      </div>
    </div>
  );
}

export default EVSTactic;
