import { gql } from '@apollo/client';

const FragmentEvsSubscription = gql`
  fragment EVSSubscriptionNode on EVSSubscriptionNode {
    id

    startsAt
    expiresAt

    isActive
    
    status
    
    targetCount
    
    isScanRunning

    targetGroups {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export default FragmentEvsSubscription;
