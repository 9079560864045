import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { isSubscriptionActive, isSubscriptionExpiring, isSubscriptionPending } from 'src/constants/subscription';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useMSPBilling } from 'src/contexts/useMSPBilling';
import Button from 'src/design/ui-kit/Button/Button';
import EvsTacticResumeButton from 'src/views/TacticsSettings/EvsTactic/EvsTacticResumeButton';
import EvsTacticSubscribeButton from 'src/views/TacticsSettings/EvsTactic/EvsTacticSubscribeButton';
import EvsTacticUnsubscribeButton from 'src/views/TacticsSettings/EvsTactic/EvsTacticUnsubscribeButton';

function EvsTacticActionButton() {
  const { tactics: { evs: { evsSubscriptionStatus } } } = useCurrentCompany();
  const { loading } = useMSPBilling();
  const { t } = useTranslation();

  if (loading) {
    return null;
  }

  if (isSubscriptionPending(evsSubscriptionStatus)) {
    return (
      <Button
        disabled
        Icon={<FontAwesomeIcon icon={light('hourglass-end')} />}
        size="sm"
        variant="secondary"
      >
        {t('Pending')}
      </Button>
    );
  }
  if (isSubscriptionActive(evsSubscriptionStatus)) {
    return <EvsTacticUnsubscribeButton />;
  }
  if (isSubscriptionExpiring(evsSubscriptionStatus)) {
    return <EvsTacticResumeButton />;
  }
  return <EvsTacticSubscribeButton />;
}

export default EvsTacticActionButton;
