import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import CompanyModal from 'src/views/Companies/CompanyModal';

function EditCompanyButton(props) {
  const { company } = props;
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <div className="EditCompanyButton">
      <Tooltip text={t('Edit company')}>
        <Button
          Icon={<FontAwesomeIcon icon={light('pen-to-square')} />}
          onClick={() => setIsOpen(true)}
          variant="iconPrimary"
        />
      </Tooltip>

      <CompanyModal
        company={company}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}

EditCompanyButton.propTypes = {
  company: PropTypes.object.isRequired,
};

export default EditCompanyButton;
