import React from 'react';

import PropTypes from 'prop-types';

import EvsRunScanButton from 'src/components/tactics.evs/scans/EvsRunScanButton';
import EvsScheduleScanButton from 'src/components/tactics.evs/scans/EvsScheduleScanButton';

function EvsScanActions(props) {
  const { showSchedule = false } = props;

  return (
    <div className="EvsScanActions flex items-center gap-3">
      {showSchedule && <EvsScheduleScanButton size="sm" />}
      <EvsRunScanButton size="sm" />
    </div>
  );
}

EvsScanActions.propTypes = {
  showSchedule: PropTypes.bool,
};

export default EvsScanActions;
