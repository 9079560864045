import React from 'react';

import PropTypes from 'prop-types';

import { formatDate, formatDateDistanceToNow } from 'src/helpers/date';

function VulnerabilityRowDetailsAssetFirstSeen(props) {
  const { date } = props;

  const formattedDate = formatDate(date);
  const formattedDistance = formatDateDistanceToNow(date);
  const width = Math.max(formattedDate.length, formattedDistance.length) * 8;
  const style = {
    minWidth: `${width}px`,
  };

  return (
    <>
      <span className="block group-hover:hidden" style={style}>
        {formattedDistance}
      </span>
      <span className="hidden group-hover:block" style={style}>
        {formattedDate}
      </span>
    </>
  );
}

VulnerabilityRowDetailsAssetFirstSeen.propTypes = {
  date: PropTypes.string.isRequired,
};

export default VulnerabilityRowDetailsAssetFirstSeen;
