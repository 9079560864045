import { gql } from '@apollo/client';

const FragmentInvoice = gql`
  fragment FragmentInvoice on Invoice {
    id
    date
    total
    paidAt
    status
    pdfUrl
  }`;

export default FragmentInvoice;
