import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line max-len
import { SubscriptionStatus, isSubscriptionActive, isSubscriptionInactive, isSubscriptionExpiring } from 'src/constants/subscription';
import { formatDate } from 'src/helpers/date';

function SubscriptionIndicator(props) {
  const { expiresAt = '', status } = props;

  const { t } = useTranslation();
  // eslint-disable-next-line max-len
  const expirationMessage = () => t('This subscription has been deactivated. All data will be lost after {{date}} unless the subscription is resumed.', {
    date: formatDate(expiresAt),
  });

  return (
    <div className={classNames('flex gap-2 items-center od-font-secondary-semi-bold', {
      'text-od-complementary-bad': isSubscriptionInactive(status),
      'text-od-complementary-good': isSubscriptionActive(status),
      'text-od-complementary-medium': isSubscriptionExpiring(status),
    })}
    >
      <div className={classNames('w-2 h-2 rounded-full shrink-0', {
        'bg-od-complementary-bad': isSubscriptionInactive(status),
        'bg-od-complementary-good': isSubscriptionActive(status),
        'bg-od-complementary-medium': isSubscriptionExpiring(status),
      })}
      />
      <div className="max-w-md">
        {isSubscriptionActive(status) && t('Active')}
        {isSubscriptionInactive(status) && t('Inactive')}
        {isSubscriptionExpiring(status) && expirationMessage()}
      </div>
    </div>
  );
}

SubscriptionIndicator.propTypes = {
  expiresAt: PropTypes.string,
  status: PropTypes.oneOf(Object.values(SubscriptionStatus)).isRequired,
};

export default SubscriptionIndicator;
