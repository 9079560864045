import React from 'react';

import PropTypes from 'prop-types';

function LogoPreview(props) {
  const { logoUrl } = props;

  // TODO: Change logo later
  return (
    <img
      alt="Logo preview"
      className="LogoPreview w-32"
      src={logoUrl}
    />
  );
}

LogoPreview.propTypes = {
  logoUrl: PropTypes.string,
};

export default LogoPreview;
