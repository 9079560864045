import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import useToast from 'src/hooks/useToast';

import useMSPSettingsEvsActions from './useMSPSettingsEvsActions';

function MSPSettingsEvsRevertTemplate() {
  const [isReverting, setIsReverting] = useState(false);
  const { t } = useTranslation();
  const { showErrorMessage, showSuccessMessage } = useToast();
  const { revertReportTemplate } = useMSPSettingsEvsActions();
  const { fetchMe } = useCurrentUser();

  const onClick = async () => {
    setIsReverting(true);

    try {
      const response = await revertReportTemplate();

      if (response.ok) {
        await fetchMe();
        showSuccessMessage(t('Template reverted successfully'));
      } else {
        throw new Error(t('Something went wrong'));
      }

      await response.json();
    } catch (error) {
      showErrorMessage(error.message || t('Something went wrong'));
    } finally {
      setIsReverting(false);
    }
  };

  return (
    <Button
      className="MSPSettingsEvsRevertTemplate flex gap-2 items-center"
      Icon={<FontAwesomeIcon icon={light('backward')} />}
      loading={isReverting}
      onClick={onClick}
      size="xs"
      variant="dangerous"
    >
      {t('Revert to default template')}
    </Button>
  );
}

export default MSPSettingsEvsRevertTemplate;
