import i18n from 'i18next';

// import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { TableColumnDefinition } from 'src/hooks/useTable';

const useEvsTargetsColumns = () => [
  new TableColumnDefinition({
    name: 'ip',
    title: i18n.t('Target'),
  }),
  // new TableColumnDefinition({
  //   name: 'targetGroup',
  //   title: i18n.t('Target Group'),
  // }),
  // new TableColumnDefinition({
  //   gridTemplate: 'max-content',
  //   name: 'deleteButton',
  // }),
].filter(Boolean);
export default useEvsTargetsColumns;
