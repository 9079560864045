import { isMSPDashboardEnabled } from 'src/hooks/useFeatures';

/**
 * https://snowfensive.atlassian.net/wiki/spaces/OD/pages/37781505/User+Types
 */
export const USER_TYPES = {
  AUDITOR: 'AUDITOR',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  GLOBAL_ADMIN: 'GLOBAL_ADMIN',
  MSP_ADMIN: 'MSP_ADMIN',
  MSP_ANALYST: 'MSP_ANALYST',
};

export const USER_TYPE_CATEGORIES = {
  COMPANY: 'TEAM',
  MSP: 'USERS',
};

export const USER_TYPE_CATEGORIES_LIST = [
  USER_TYPE_CATEGORIES.COMPANY,
  USER_TYPE_CATEGORIES.MSP,
];

export const USER_TYPES_CONFIG = {
  AUDITOR: {
    buildUrl: (path) => path,
    getInvitationTypes() {
      return [];
    },
    hasCompanySelect: false,
    userType: USER_TYPES.AUDITOR,
  },
  COMPANY_ADMIN: {
    buildUrl: (path) => path,
    getInvitationTypes(typeCategory) {
      if (typeCategory === USER_TYPE_CATEGORIES.COMPANY) {
        return [
          USER_TYPES.COMPANY_ADMIN,
          USER_TYPES.AUDITOR,
        ];
      }
      return [];
    },
    hasCompanySelect: false,
    userType: USER_TYPES.COMPANY_ADMIN,
  },
  GLOBAL_ADMIN: {
    buildUrl: (path, suffix = '') => `/companies${suffix}${path}`,
    defaultRoute: isMSPDashboardEnabled() ? '/dashboard' : '/companies',
    getInvitationTypes(typeCategory) {
      if (typeCategory === USER_TYPE_CATEGORIES.COMPANY) {
        return [
          USER_TYPES.COMPANY_ADMIN,
          USER_TYPES.AUDITOR,
        ];
      }
      // GLOBAL ADMIN cannot access MSP list,
      // so we disable inviting MSPs for now
      return [];
    },
    hasCompanySelect: true,
    userType: USER_TYPES.MSP_ADMIN,
  },
  MSP_ADMIN: {
    buildUrl: (path, suffix = '') => `/companies${suffix}${path}`,
    defaultRoute: isMSPDashboardEnabled() ? '/dashboard' : '/companies',
    getInvitationTypes(typeCategory) {
      if (typeCategory === USER_TYPE_CATEGORIES.MSP) {
        return [
          USER_TYPES.MSP_ADMIN,
          USER_TYPES.MSP_ANALYST,
        ];
      }
      if (typeCategory === USER_TYPE_CATEGORIES.COMPANY) {
        return [
          USER_TYPES.COMPANY_ADMIN,
          USER_TYPES.AUDITOR,
        ];
      }
      return [];
    },
    hasCompanySelect: true,
    userType: USER_TYPES.MSP_ADMIN,
  },
  MSP_ANALYST: {
    buildUrl: (path, suffix = '') => `/companies${suffix}${path}`,
    defaultRoute: isMSPDashboardEnabled() ? '/dashboard' : '/companies',
    getInvitationTypes(typeCategory) {
      if (typeCategory === USER_TYPE_CATEGORIES.COMPANY) {
        return [
          USER_TYPES.COMPANY_ADMIN,
          USER_TYPES.AUDITOR,
        ];
      }
      return [];
    },
    hasCompanySelect: true,
    userType: USER_TYPES.MSP_ANALYST,
  },
};

export const USER_TYPE_LABELS = {
  AUDITOR: 'Auditor',
  COMPANY_ADMIN: 'Company Admin',
  GLOBAL_ADMIN: 'Global Admin',
  MSP_ADMIN: 'MSP Admin',
  MSP_ANALYST: 'MSP Analyst',
};

export function getUserConfig(userType) {
  if (userType && USER_TYPES_CONFIG[userType]) {
    return USER_TYPES_CONFIG[userType];
  }
  return {
    buildUrl: () => '/',
    getInvitationTypes: () => [],
    hasCompanySelect: false,
    userType: null,
  };
}

export const userTypeStore = {
  clear() {
    localStorage.removeItem('userType');
  },
  get() {
    return localStorage.getItem('userType');
  },
  set(userType) {
    return localStorage.setItem('userType', userType);
  },
};
