import React from 'react';

import PropTypes from 'prop-types';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Username from 'src/design/ui-kit/Username/Username';
import { USER_TYPE_CATEGORIES_LIST, USER_TYPE_LABELS } from 'src/users';
import DeleteUserButton from 'src/views/common/Users/DeleteUserButton';
import EditUserButton from 'src/views/common/Users/EditUserButton';

function UsersTableViewRow(props) {
  const { data: user, userCategoryType } = props;

  const { permissions } = useCurrentUser();

  return (
    <tr id={`TableRow${user.id}`}>
      <td>
        <Username user={user} />
      </td>
      <td>{user.email}</td>
      <td className="justify-end">
        {USER_TYPE_LABELS[user.userType]}
      </td>
      {permissions.canManageUsers(userCategoryType) && (
        <>
          <td className="justify-end">
            <EditUserButton
              user={user}
              userCategoryType={userCategoryType}
            />
          </td>
          <td className="justify-end">
            <DeleteUserButton user={user} />
          </td>
        </>
      )}
    </tr>
  );
}

UsersTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
  userCategoryType: PropTypes.oneOf(USER_TYPE_CATEGORIES_LIST).isRequired,
};

export default UsersTableViewRow;
