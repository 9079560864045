import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { BCI_ADD_DOMAINS_MUTATION, BCI_DOMAINS } from 'src/api/bci';
import { BCI_ESTIMATE, BCI_SUBSCRIBE, BCI_SUBSCRIPTION, BCI_SUBSCRIPTION_PRICES } from 'src/api/bciSubscription';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function useBCISubscriptionActions({
  formData,
  handleClose,
  mode,
}) {
  const { showSuccessMessage } = useToast();
  const { companyId } = useUserParams();
  const { t } = useTranslation();

  const { data: dataPricesEstimate, loading: loadingEstimates } = useQuery(BCI_ESTIMATE, {
    skip: formData.domains.length === 0,
    variables: {
      companyId,
      targetDomains: formData.domains,
    },
  });

  const { data: dataPrices, loading: loadingPrice } = useQuery(BCI_SUBSCRIPTION_PRICES, {
    skip: formData.domains.length === 0,
    variables: {
      companyId,
      targetDomains: formData.domains,
    },
  });

  const domainSetupPrice = dataPrices?.bciPrices?.domainSetupPrice ?? 0;
  const estimateAddThisMonth = dataPricesEstimate?.bciEstimate?.estimateAddThisMonth ?? 0;
  const totalEstimateNextMonth = dataPricesEstimate?.bciEstimate?.totalEstimateNextMonth ?? 0;

  const [bciSubscribe, { loading: savingSubscription }] = useMutation(BCI_SUBSCRIBE, {
    onCompleted(response) {
      if (response.bciSubscribe) {
        showSuccessMessage(t('Successfully subscribed'));
        handleClose();
      }
    },
    refetchQueries: [BCI_DOMAINS, BCI_SUBSCRIPTION],
  });

  const [addDomains, { loading: savingDomains }] = useMutation(BCI_ADD_DOMAINS_MUTATION, {
    onCompleted() {
      showSuccessMessage(t('New domains successfully added'));
      handleClose();
    },
    refetchQueries: [BCI_DOMAINS, BCI_SUBSCRIPTION],
  });

  const loading = loadingEstimates || loadingPrice || savingDomains || savingSubscription;

  const onConfirm = async () => {
    if (mode === 'subscription') {
      await bciSubscribe({
        variables: {
          companyId,
          targetDomains: formData.domains,
        },
      });
      return;
    }
    await addDomains({
      variables: {
        companyId,
        domains: formData.domains,
      },
    });
  };

  return {
    domainSetupPrice,
    estimateAddThisMonth,
    loading,
    onConfirm,
    totalEstimateNextMonth,
  };
}

export default useBCISubscriptionActions;
