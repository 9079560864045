import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

// https://codepen.io/lhermann/pen/EBGZRZ
function Switch(props) {
  const { checked, disabled = false, id, label = '', onChange } = props;

  return (
    <div className="Switch flex items-center">

      <label
        className="flex items-center cursor-pointer"
        htmlFor={id}
      >
        <div className="relative">
          <input
            checked={checked}
            className="Switch__input sr-only"
            disabled={disabled}
            id={id}
            onChange={() => onChange(!checked)}
            type="checkbox"
          />
          <div className={classNames(['Switch__track w-10 h-4 rounded-full shadow-inner'], {
            'bg-od-white-500': !checked,
            'bg-od-white-900': checked,
          })}
          />
          <div className="Switch__dot absolute w-6 h-6 bg-od-white-900 rounded-full shadow -left-1 -top-1 transition" />
        </div>
        <div className="ml-3 od-font-main-medium">
          {label}
        </div>
      </label>
    </div>
  );
}

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
