import React, { useRef, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIntersection } from 'react-use';

import { ALL_NOTIFICATIONS_QUERY, SEE_NOTIFICATIONS_MUTATION, UNSEEN_NOTIFICATIONS_QUERY } from 'src/api/notifications';
import { LONG_LOCALIZED_DATE } from 'src/constants/date';
import FieldDatetime from 'src/design/ui-kit/Field/FieldDatetime/FieldDatetime';

function NotificationsTableViewRow(props) {
  const { data: { id, notification, seen } } = props;

  const ref = useRef();
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const [_seeNotifications, { loading: saving }] = useMutation(
    SEE_NOTIFICATIONS_MUTATION,
    {
      refetchQueries: [ALL_NOTIFICATIONS_QUERY, UNSEEN_NOTIFICATIONS_QUERY],
    },
  );

  useEffect(() => {
    async function handleIntersection() {
      if (!intersection || saving) {
        return;
      }
      const inView = intersection.intersectionRatio === 1;

      if (inView && !seen) {
        await _seeNotifications({
          variables: {
            ignoreErrors: true,
            notifications: [id],
          },
        });
      }
    }
    handleIntersection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection]);

  return (
    <tr id={notification.id}>
      <td ref={ref} className={classNames('flex gap-2', { 'text-od-white-500': seen })}>
        {!seen && (
          <div className="w-2 h-2 flex-shrink-0 bg-od-complementary-bad rounded-full" />
        )}
        {notification.message}
      </td>
      <td>
        <FieldDatetime
          dateFormat={LONG_LOCALIZED_DATE}
          value={new Date(notification.addedAt)}
        />
      </td>
    </tr>
  );
}

NotificationsTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
};

export default NotificationsTableViewRow;
