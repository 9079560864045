import React from 'react';

import { useTranslation } from 'react-i18next';

import { USER_TYPE_CATEGORIES } from 'src/users';
import UsersCommon from 'src/views/common/Users/Users';

function Team() {
  const { t } = useTranslation();
  return (
    <UsersCommon title={t('Team')} userCategoryType={USER_TYPE_CATEGORIES.MSP} />
  );
}

export default Team;
