// eslint-disable-next-line import/prefer-default-export
export const SubscriptionStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  CANCEL_SCHEDULED: 'CANCEL_SCHEDULED',
  INACTIVE: 'INACTIVE',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',
  PENDING: 'PENDING',
  UPDATE_SCHEDULED: 'UPDATE_SCHEDULED',
});

export function isSubscriptionActive(status) {
  return [
    SubscriptionStatus.ACTIVE,
    SubscriptionStatus.UPDATE_SCHEDULED,
  ].includes(status);
}

export function isSubscriptionInactive(status) {
  return [
    SubscriptionStatus.INACTIVE,
  ].includes(status);
}

export function isSubscriptionPending(status) {
  return [
    SubscriptionStatus.PENDING,
  ].includes(status);
}

export function isSubscriptionExpiring(status) {
  return [
    SubscriptionStatus.CANCEL_SCHEDULED,
  ].includes(status);
}
