import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'src/helpers/date';

function MSPSettingsEVSFilePreview(props) {
  const { hasCustomTemplate, lastUpdate = null } = props;
  const { t } = useTranslation();

  return (
    <div className="MSPSettingsEVSFilePreview flex items-center gap-2">
      <FontAwesomeIcon icon={light('file-word')} size="xl" />
      {hasCustomTemplate ? (
        <span className="text-od-white-500 text-sm">
          {t('Last updated: {{date}}', { date: formatDate(lastUpdate) })}
        </span>
      ) : (
        <span className="text-od-white-500 text-sm">
          {t('No file uploaded')}
        </span>
      )}
    </div>
  );
}

MSPSettingsEVSFilePreview.propTypes = {
  hasCustomTemplate: PropTypes.bool.isRequired,
  lastUpdate: PropTypes.string,
};

export default MSPSettingsEVSFilePreview;
