import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';
import Loading from 'src/design/ui-kit/Loading/Loading';
import VulnerabilityRowDetailsAsset
  from 'src/views/EvsScanVulnerabilities/VulnerabilityRow/VulnerabilityRowDetailsAsset';

function EvsVulnerabilityRowDetailsAssetsList(props) {
  const { assets, isLoadingMore, onLoadMore, pageInfo } = props;

  const { t } = useTranslation();

  return (
    <section className="grid gap-4">
      <p className="text-od-white-500 od-font-secondary-medium">
        {t('Affected hosts')}
      </p>

      <ul className="grid gap-4">
        {assets.map((asset, index) => (
          <VulnerabilityRowDetailsAsset key={index} asset={asset} />
        ))}

        {isLoadingMore && (
          <Loading size="sm" />
        )}
        {pageInfo.hasNextPage && !isLoadingMore && (
          <li className="flex justify-center items-center">
            <Button
              onClick={onLoadMore}
              size="sm"
              variant="secondary"
            >
              {t('Load more')}
            </Button>
          </li>
        )}
      </ul>
    </section>
  );
}

EvsVulnerabilityRowDetailsAssetsList.propTypes = {
  assets: PropTypes.array.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  pageInfo: PropTypes.object.isRequired,
};

export default EvsVulnerabilityRowDetailsAssetsList;
