import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { isSubscriptionActive, isSubscriptionExpiring, isSubscriptionPending } from 'src/constants/subscription';
import { useCurrentCompanyBciSubscription } from 'src/contexts/useCurrentCompanyBciSubscription';
import { useMSPBilling } from 'src/contexts/useMSPBilling';
import Button from 'src/design/ui-kit/Button/Button';
import BciTacticResumeButton from 'src/views/TacticsSettings/BciTactic/BciTacticResumeButton';
import BciTacticSubscribeButton from 'src/views/TacticsSettings/BciTactic/BciTacticSubscribeButton';
import BciTacticUnsubscribeButton from 'src/views/TacticsSettings/BciTactic/BciTacticUnsubscribeButton';

function BciTacticActionButton() {
  const { bciSubscriptionStatus } = useCurrentCompanyBciSubscription();
  const { loading } = useMSPBilling();
  const { t } = useTranslation();

  if (loading) {
    return null;
  }

  if (isSubscriptionPending(bciSubscriptionStatus)) {
    return (
      <Button
        disabled
        Icon={<FontAwesomeIcon icon={light('hourglass-end')} />}
        size="sm"
        variant="secondary"
      >
        {t('Pending')}
      </Button>
    );
  }
  if (isSubscriptionActive(bciSubscriptionStatus)) {
    return <BciTacticUnsubscribeButton />;
  }
  if (isSubscriptionExpiring(bciSubscriptionStatus)) {
    return <BciTacticResumeButton />;
  }
  return <BciTacticSubscribeButton />;
}

export default BciTacticActionButton;
