import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import IconVulnerability from 'src/design/custom-icons/IconVulnerability/IconVulnerability';
import Button from 'src/design/ui-kit/Button/Button';
import Card from 'src/design/ui-kit/Card/Card';

import MSPSettingsEVSFilePreview from './MSPSettingsEVSFilePreview';
import MSPSettingsEvsRevertTemplate from './MSPSettingsEvsRevertTemplate';
import MSPSettingsEvsUploadTemplate from './MSPSettingsEvsUploadTemplate';
import useMSPSettingsEvsActions from './useMSPSettingsEvsActions';

function MSPSettingsEVSReportsSection() {
  const { t } = useTranslation();

  const { downloadTemplate } = useMSPSettingsEvsActions();
  const { me: { msp } } = useCurrentUser();

  const { customTemplate: hasCustomTemplate, lastUpdate } = msp.reportTemplate;

  return (
    <div className="MSPSettingsEVSReportsSection grid gap-5 grid-cols-2 mt-5">
      <Card>
        <h3 className="flex gap-2 items-center">
          <IconVulnerability />
          {t('EVS report template')}
        </h3>

        <p className="text-sm text-od-white-500 mt-1.5">
          {t('You can customize the PDF/word report template for this tactic.')}
        </p>

        <div className="mt-5">
          <ul>
            <li>
              <Button
                Icon={<FontAwesomeIcon icon={light('file-chart-pie')} />}
                onClick={downloadTemplate}
                size="xs"
                variant="secondary"
              >
                {t('Download EVS template')}
              </Button>
            </li>
          </ul>
        </div>
      </Card>

      <Card className="flex items-center">
        <ul className="grid gap-4">
          <li>
            <MSPSettingsEVSFilePreview
              hasCustomTemplate={hasCustomTemplate}
              lastUpdate={lastUpdate}
            />
          </li>
          <li>
            <MSPSettingsEvsUploadTemplate />
          </li>
          {hasCustomTemplate && (
            <>
              <li>
                <Button
                  Icon={<FontAwesomeIcon icon={light('file-arrow-down')} />}
                  size="xs"
                  variant="secondary"
                >
                  {t('Download current EVS template')}
                </Button>
              </li>
              <li>
                <MSPSettingsEvsRevertTemplate />
              </li>
            </>
          )}
        </ul>
      </Card>
    </div>
  );
}

export default MSPSettingsEVSReportsSection;
