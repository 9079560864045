import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function CardComponentWrapper(props) {
  const {
    children,
    className = '',
    isDirty,
    state = null,
  } = props;
  const { t } = useTranslation();
  const error = state?.error?.message
    || (isDirty && !state?.complete ? t('Field is required') : '') || '';

  return (
    <div className={classNames('CardComponentWrapper', className)}>
      {children}
      {error && (
        <p className="text-od-complementary-bad text-xs mt-1">{error}</p>
      )}
    </div>
  );
}

CardComponentWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  isDirty: PropTypes.bool.isRequired,
  state: PropTypes.object,
};

export default CardComponentWrapper;
