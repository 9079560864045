import React from 'react';

import useTargetsLoader from 'src/api/loaders/useTargetsLoader';
import TableView from 'src/components/common/Table/TableView';
import EvsTargetsTableViewRow from 'src/components/tactics.evs/targets/EvsTargetsTableViewRow';
import useEvsTargetsColumns from 'src/components/tactics.evs/targets/useEvsTargetsColumns';
import useUserParams from 'src/hooks/useUserParams';

const first = 20;

// TODO: Add empty and loading states & find out why pagination with first=2 not working
function EvsTargetsTableView() {
  const columns = useEvsTargetsColumns();
  const { companyId } = useUserParams();

  const variables = {
    companyId,
    first,
  };

  const {
    data: { items: evsTargets, pageInfo },
    fetchMore, isLoadingMore,
  } = useTargetsLoader(
    { variables },
  );

  const onLoadMore = async () => {
    await fetchMore({
      variables: {
        after: pageInfo.endCursor,
        first,
      },
    });
  };

  return (
    <TableView
      className="EvsTargetsTableView gap-y-2"
      columns={columns}
      data={evsTargets}
      isLoadingMore={isLoadingMore}
      onLoadMore={onLoadMore}
      pageInfo={pageInfo}
      showHead={evsTargets.length > 0}
      TableRow={EvsTargetsTableViewRow}
    />
  );
}

export default EvsTargetsTableView;
