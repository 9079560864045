import { gql } from '@apollo/client';

import FragmentCountPerLevel from 'src/api/fragments/FragmentCountPerLevel';
import { VulnerabilityStatus } from 'src/constants/evs';

const EVS_SCAN_VULNERABILITY_FRAGMENT = gql`
  fragment DeltaVulnerabilityNode on DeltaVulnerabilityNode {
    id
    name
    affectedHosts
    severity
    plugin {
      id
      exploitable
      exploitabilityText
      patchPublicationDate
      description
      solution
      attributes
      family
      seeAlso
      synopsis
    }
    assets(first: $assetsFirst, after: $assetsAfter) {
      edges {
        node {
          ipAddress
          output
          service
          port
          protocol
          firstSeenAt
          status
        }
      }  
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const ALL_SCANS_QUERY = gql`
  ${FragmentCountPerLevel}
  query allScans($first: Int, $last: Int, $after: String, $companyId: ID!, $endAt: DateTime) {
    allScans(
      first: $first
      last: $last
      after: $after
      company: $companyId
      endAt: $endAt
    ) {
      edges {
        node {
          id
          name
          ranAt
          startedAt
          owner {
            name
          }
          statistics {
            id
            vulnCountPerLevel {
              ...FragmentCountPerLevel
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const EVS_SCAN_EXPORT_CSV = gql`
  mutation($scan: ID!) {
    exportScanCsv(input: {
      scan: $scan
    }) {
      url
    }
  }
`;

export const EVS_SCAN_GENERATE_REPORT = gql`
  mutation($scan: ID!) {
    generateReport(input: {
      scan: $scan
    }) {
      report {
        id
        pdf
        docx
      }
    }
  }
`;

export const RUN_SCAN_MUTATION = gql`
  mutation runScan($targetGroup: ID!) {
    runScan(input: {
      targetGroup: $targetGroup
    }) {
      trackId
    }
  }
`;

export const SET_PERIOD_SCAN_SCHEDULE_MUTATION = gql`
  mutation setPeriodScanSchedule(
    $targetGroup: ID!,
    $period: SchedulePeriod!,
    $days: [Int!]!,
    $timeOfDay: Time!,
    $timezone: String!
  ) {
    setPeriodScanSchedule(input: {
      targetGroup: $targetGroup
      period: $period
      days: $days
      timeOfDay: $timeOfDay
      timezone: $timezone
    }) {
      schedule {
        id
      }
    }
  }
`;

const FramgentCountPerLevelNoTotal = gql`
  fragment CountPerLevelNoTotal on CountPerLevel {
    critical
    high
    medium
    low
    info
  }
`;

export const SCAN_OVERVIEW_SCAN_QUERY = gql`
  ${FramgentCountPerLevelNoTotal}
  query ScanOverview($scanId: ID!, $excludeLevels: [SeverityLevel!]) {
    scan(id: $scanId) {
      id
      name
      ranAt
      startedAt
      owner {
        id
        name
        
        company {
          id
        }
      }

      targetedHosts

      deltaStatistics {
        previousScan {
          id
        }
        newCount {
          total(exclude: $excludeLevels)
        }
        remediatedCount {
          total(exclude: $excludeLevels)
        }
      }

      statistics {
        id
        currentCount
        deadAssetsCount
        liveAssetsCount
        
        osCounts

        vulnCountPerLevel {
          ...CountPerLevelNoTotal,
          total(exclude: $excludeLevels)
        }

        exploitCountPerLevel {
          ...CountPerLevelNoTotal,
          total(exclude: $excludeLevels)
        }
      }
    }
  }
`;

export const SCAN_OVERVIEW_SCAN_LIST_QUERY = gql`
  ${FramgentCountPerLevelNoTotal}
  query ScanOverviewList($companyId: ID, $endAt: DateTime, $targetGroup: ID) {
    allScans(first: 10, company: $companyId, endAt: $endAt, targetGroup: $targetGroup) {
      edges {
        node {
          id
          ranAt
          statistics {
            id
            vulnCountPerLevel {
              ...CountPerLevelNoTotal
            }
          }
        }
      }
    }
  }
`;

export const EVS_SCAN_VULNERABILITIES_MAIN_QUERY = gql`
  ${FramgentCountPerLevelNoTotal}
  query ScanVulnerabilities($scanId: ID!, $excludeLevels: [SeverityLevel!]) {
    scan(id: $scanId) {
      id
      statistics {
        id
        currentCount: vulnCountPerLevel {
          ...CountPerLevelNoTotal
          total(exclude: $excludeLevels)
        }
      }

      deltaStatistics {
        id
        newCount: vulnerabilityCountsByStatus(status: ${VulnerabilityStatus.NEW}) {
          ...CountPerLevelNoTotal
          total(exclude: $excludeLevels)
        }

        remediatedCount: vulnerabilityCountsByStatus(status: ${VulnerabilityStatus.REMEDIATED}) {
          ...CountPerLevelNoTotal
          total(exclude: $excludeLevels)
        }
      }
    }
  }
`;

export const EVS_SCAN_VULNERABILITIES_LIST_BY_STATUS_QUERY = gql`
  query ScanVulnerabilitiesList($scanId: ID!, $status: DeltaVulnerabilityStatus!, $severity: Int!) {
    scan(id: $scanId) {
      id
      deltaStatistics {
        id
        vulnerabilitiesByStatus(status: $status, severity: $severity, first: 100) {
          edges {
            node {
              id
              name

              plugin {
                id
                patchPublicationDate
                exploitabilityText
              }

              affectedHosts
            }
          }
        }
      }
    }
  }
`;

export const EVS_SCAN_VULNERABILITIES_LIST_CURRENT_QUERY = gql`
  query ScanVulnerabilitiesList($scanId: ID!, $severity: Int!) {
    scan(id: $scanId) {
      id
      deltaStatistics {
        id
        currentVulnerabilities(severity: $severity, first: 100) {
          edges {
            node {
              id
              name

              plugin {
                id
                patchPublicationDate
                exploitabilityText
              }

              affectedHosts
            }
          }
        }
      }
    }
  }
`;

export const EVS_VULNERABILITY_DETAILS_QUERY = gql`
  ${EVS_SCAN_VULNERABILITY_FRAGMENT}
  query ScanVulnerabilityDetails($scanId: ID!, $vulnId: ID!, $assetsFirst: Int!, $assetsAfter: String) {
    scan(id: $scanId) {
      id
      vulnerability(id: $vulnId) {
        ...DeltaVulnerabilityNode
      }
    }
  }
`;

export function transformEvsVulnerabilityDetailsResponse(data) {
  if (!data) {
    return {
      items: [],
      originalData: null,
      pageInfo: {
        endCursor: null,
        hasNextPage: false,
      },
    };
  }
  const { vulnerability } = data.scan;

  return {
    items: vulnerability.assets.edges.map((edge) => edge.node),
    originalData: vulnerability,
    pageInfo: vulnerability.assets.pageInfo,
  };
}
