import React, { useRef, useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import useToast from 'src/hooks/useToast';

import useMSPSettingsEvsActions from './useMSPSettingsEvsActions';

function MSPSettingsEvsUploadTemplate() {
  const { t } = useTranslation();

  const { showErrorMessage, showSuccessMessage } = useToast();
  const { uploadTemplate } = useMSPSettingsEvsActions();
  const { fetchMe } = useCurrentUser();

  const inputRef = useRef(null);

  const [isUploading, setIsUploading] = useState(false);

  const onClick = () => {
    inputRef.current.click();
  };

  const handleFileUpload = async (file) => {
    if (!file) {
      return;
    }

    try {
      setIsUploading(true);
      const response = await uploadTemplate(file);
      const responseBody = await response.json();
      if (response.ok) {
        await fetchMe();
        showSuccessMessage(t('Template successfully uploaded'));
      } else {
        throw new Error(responseBody.error || response.statusText);
      }
    } catch (error) {
      showErrorMessage(error.message || t('Something went wrong'));
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  return (
    <>
      <input
        ref={inputRef}
        accept=".docx"
        className="hidden"
        onChange={handleFileChange}
        type="file"
      />
      <Button
        Icon={<FontAwesomeIcon icon={light('file-arrow-up')} />}
        loading={isUploading}
        onClick={onClick}
        size="xs"
      >
        {t('Upload new EVS template')}
      </Button>
    </>
  );
}

export default MSPSettingsEvsUploadTemplate;
