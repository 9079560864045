import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import GoBackLink from 'src/components/common/GoBackLink';
import Nav, { NavItem } from 'src/components/common/Nav';
import EvsReportsButton from 'src/components/tactics.evs/scans/EvsReportsButton';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Switch from 'src/design/ui-kit/Switch/Switch';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import { noop } from 'src/helpers/util';
import useUserParams from 'src/hooks/useUserParams';

function EvsScanVulnerabilities(props) {
  const {
    activeTab,
    setShowInfo = noop(),
    showInfo = false,
  } = props;
  const { t } = useTranslation();
  const { companyId, scanId } = useUserParams();
  const { userConfig } = useCurrentUser();
  const navigate = useNavigate();

  const navItems = [
    new NavItem({
      action: () => {
        navigate(userConfig.buildUrl(`/evs-scans/${scanId}`, `/${companyId}`));
      },
      key: 'overview',
      name: t('Overview'),
    }),
    new NavItem({
      action: () => {
        navigate(userConfig.buildUrl(`/evs-scans/${scanId}/vulnerabilities`, `/${companyId}`));
      },
      key: 'vulnerabilities',
      name: t('Vulnerabilities'),
    }),
  ];

  return (
    <header className="EvsScanHeader">
      <div>
        <h1 className="mb-4">
          {activeTab === 'overview' ? t('Scan overview') : t('Scan Vulnerabilities')}
        </h1>
        <GoBackLink to={userConfig.buildUrl('/evs-scans', `/${companyId}`)}>
          {t('All scans')}
        </GoBackLink>
      </div>

      <div className="flex items-center gap-3 mt-5">
        <div className="flex-1">
          <Nav
            active={activeTab}
            navItems={navItems}
            variant="primary"
          />
        </div>

        <div className="flex items-center gap-3">
          {activeTab === 'vulnerabilities' && (
            <div className="flex gap-2 items-center">
              <Switch
                checked={showInfo}
                id="VulnerabilityScan__info-toggle"
                label={t('Show info')}
                onChange={setShowInfo}
              />
              <Tooltip
                popoverProps={{
                  positions: ['bottom'],
                }}
                text={t('Informational findings are not vulnerabilities but contain'
                  + ' configuration information that may be useful to an auditor or an administrator.'
                  + ' By default, these are not shown.')}
              >
                <FontAwesomeIcon icon={light('circle-info')} />
              </Tooltip>
            </div>
          )}

          <EvsReportsButton scanId={scanId} />
        </div>
      </div>
    </header>
  );
}

EvsScanVulnerabilities.propTypes = {
  activeTab: PropTypes.oneOf(['overview', 'vulnerabilities']).isRequired,
  setShowInfo: PropTypes.func,
  showInfo: PropTypes.bool,
};

export default EvsScanVulnerabilities;
