import { isSubscriptionActive } from 'src/constants/subscription';

const DEFAULT_CURRENCY = 'USD';

export const useConvertCentValue = (decimalPoints = 2) => {
  const convertToCentValue = (value) => {
    if (!value && value !== 0) {
      return null;
    }
    const centValue = value * 10 ** decimalPoints;
    return Number(
      `${Math.round(`${centValue}e${decimalPoints}`)}e-${decimalPoints}`,
    );
  };

  const convertFromCentValue = (value) => {
    if (!value && value !== 0) {
      return null;
    }
    return decimalPoints
      ? (value / 100).toFixed(value % 100 ? decimalPoints : 0)
      : Math.floor((value / 100));
  };

  return {
    convertFromCentValue,
    convertToCentValue,
  };
};

export function formatPrice(value) {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: DEFAULT_CURRENCY,
    maximumSignificantDigits: 3,
    style: 'currency',
  });
  return formatter.format(value);
}

export function calculatePrices({ basePrice, status, targetCount, targetGroup, targetPrice }) {
  const existingTargetsPrice = targetGroup ? targetGroup.targetCount * targetPrice : 0;
  const newTargetsPrice = targetCount * targetPrice;
  // We don't do a refund for targets, so we only charge for new targets or don't
  // charge at all if the new target count is less than the existing target count
  const totalTargetsPrice = Math.max(newTargetsPrice - existingTargetsPrice, 0);
  let totalPrice = totalTargetsPrice;

  if (!isSubscriptionActive(status)) {
    totalPrice += basePrice;
  }

  return {
    totalPrice,
    totalTargetsPrice,
  };
}
