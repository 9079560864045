import React from 'react';

import { useTranslation } from 'react-i18next';

import EvsScanActions from 'src/components/tactics.evs/scans/EvsScanActions';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Card from 'src/design/ui-kit/Card/Card';
import useEvsTacticData from 'src/hooks/useEvsTacticData';
import useUserParams from 'src/hooks/useUserParams';
import CompanyDashboardInfoTooltip from 'src/views/CompanyDashboard/CompanyDashboardInfoTooltip';
import CompanyDashboardVulnerabilityScanningGroup from
  'src/views/CompanyDashboard/CompanyDashboardVulnerabilityScanningGroup';
import CompanyDashboardVulnerabilityScanningOverviewTotal
  from 'src/views/CompanyDashboard/CompanyDashboardVulnerabilityScanningOverviewTotal';

function CompanyDashboardVulnerabilityScanningOverview() {
  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const { company } = useCurrentCompany();
  const { evsTargetGroups } = useEvsTacticData({ companyId });
  const { permissions } = useCurrentUser();

  return (
    <section>
      <h3>{t('Vulnerability Scanning Overview')}</h3>

      {
        company.evsAggregateStatistics && (
          <Card>
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <h4>{t('Total')}</h4>
                <CompanyDashboardInfoTooltip />
              </div>
              {permissions.canManageEvsSubscription() && (
                <EvsScanActions />
              )}
            </div>

            <CompanyDashboardVulnerabilityScanningOverviewTotal />
          </Card>
        )
      }

      <div className="grid grid-cols-1 gap-8 mt-8">
        {evsTargetGroups.map((targetGroup) => (
          <CompanyDashboardVulnerabilityScanningGroup
            key={targetGroup.id}
            expandedInitialValue={false}
            targetGroup={targetGroup}
          />
        ))}
      </div>
    </section>
  );
}

export default CompanyDashboardVulnerabilityScanningOverview;
