import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Page from 'src/components/common/Page';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import PirateGif from 'src/design/images/pirate.gif';
import Button from 'src/design/ui-kit/Button/Button';

function PageNotFound(props) {
  const { title = '' } = props;

  const { t } = useTranslation();
  const { navigateToDefaultPage } = useCurrentUser();

  return (
    <Page className="grid gap-10" title={title}>
      <section className="flex justify-center">
        <div className="w-auto">
          <h1 className="od-typewriter">
            {title || t('Page not found')}
            .
          </h1>
        </div>
      </section>
      <section className="flex justify-center">
        <img
          alt={t('Loading')}
          className="block mx-auto my-4 w-[480px]"
          src={PirateGif}
        />
      </section>
      <section className="flex justify-center">
        <Button
          onClick={navigateToDefaultPage}
          variant="primary"
        >
          {t('Return home')}
        </Button>
      </section>
    </Page>
  );
}

PageNotFound.propTypes = {
  title: PropTypes.string,
};

export default PageNotFound;
