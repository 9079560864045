import React, { useState } from 'react';

import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useVulnerabilityStatuses, useScanVulnerabilitySeverityDefinitions } from 'src/helpers/scan';
import EvsVulnerabilitySeverityRow
  from 'src/views/EvsScanVulnerabilities/EvsVulnerabilitySeverityRow';

function EvsScanVulnerabilitiesContent(props) {
  const { scan, showInfo } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const vulnerabilitySeverityDefinitions = useScanVulnerabilitySeverityDefinitions({ showInfo });
  const vulnerabilityStatuses = useVulnerabilityStatuses({
    scan,
    showInfo,
  });

  return (
    <div className="ScanVulnerabilities grid mt-8">
      <Tab.Group
        onChange={setSelectedIndex}
        selectedIndex={selectedIndex}
      >
        <Tab.List className="flex items-center gap-10">
          {vulnerabilityStatuses.map(({ id, text }, index) => (
            <Tab
              key={id}
              className={classNames('h-11 flex flex-col od-font-main-regular', {
                'text-od-white-500': index !== selectedIndex,
                'text-od-white-900': index === selectedIndex,
              })}
            >
              {text}
              {index === selectedIndex && (
                <div className="h-0.5 w-full bg-od-white-900 rounded-full mt-4" />
              )}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="mt-8">
          {vulnerabilityStatuses.map((vulnerabilityStatus, index) => (
            <Tab.Panel key={index}>
              <div className="grid grid-cols-1 gap-3">
                {vulnerabilitySeverityDefinitions.map((vulnerabilitySeverityDefinition) => (
                  <EvsVulnerabilitySeverityRow
                    key={vulnerabilitySeverityDefinition.key}
                    counts={vulnerabilityStatus.counts}
                    scan={scan}
                    status={vulnerabilityStatus.id}
                    vulnerabilitySeverityDefinition={vulnerabilitySeverityDefinition}
                  />
                ))}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

EvsScanVulnerabilitiesContent.propTypes = {
  scan: PropTypes.object.isRequired,
  showInfo: PropTypes.bool.isRequired,
};

export default EvsScanVulnerabilitiesContent;
