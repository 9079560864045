import { MSP_BILLING_INVOICES, transformBillingDetailsToInvoices } from 'src/api/billing';
import usePaginatedLoader from 'src/api/loaders/usePaginatedLoader';

export default function useBillingInvoicesLoader({ variables }) {
  return usePaginatedLoader(
    MSP_BILLING_INVOICES,
    { variables },
    transformBillingDetailsToInvoices,
  );
}
