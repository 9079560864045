// Redefine them breakpoints
// https://v2.tailwindcss.com/docs/responsive-design

const lg = 1024;

const xl = 1440;

const xxl = 1920;

const screensMap = {
  lg,
  xl,
  xxl,
};

const screens = {
  lg: `${lg}px`,
  xl: `${xl}px`,
  xxl: `${xxl}px`,
};

module.exports = {
  screens,
  screensMap,
};
