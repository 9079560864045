import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import ColumnChart from 'src/components/charts/DomainColumnChart/ColumnChart';
import { domainColors } from 'src/constants/domains';
import { buildChartData, tooltipFormatter } from 'src/helpers/domainChart';

function DomainColumnChart(props) {
  const {
    bcStatistics,
    className = '',
    style = {},
    title,
  } = props;

  const domains = bcStatistics.domains.map(({ name }) => name).sort();

  const chartData = buildChartData(bcStatistics);

  return (
    <div
      className={classNames('DomainColumnChart px-6', className)}
      style={style}
    >
      <div className="flex justify-between mb-4">
        <div className="od-font-title-semi-bold">
          {title}
        </div>
      </div>

      <ColumnChart
        chartData={chartData}
        tooltipFormatter={tooltipFormatter}
      />

      <div className="DomainColumnChart__legend flex gap-5 justify-center mt-6">
        {domains.map((domain, index) => (
          <div
            key={index}
            className="od-font-secondary-medium od-font-family text-od-white-900 flex items-center gap-2"
          >
            <div
              className="h-3.5 w-3.5 rounded"
              style={{
                background: domainColors[index],
              }}
            />
            {domain}
          </div>
        ))}
      </div>
    </div>
  );
}

DomainColumnChart.propTypes = {
  bcStatistics: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default DomainColumnChart;
